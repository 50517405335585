import {

    FIND_RESULT_RATING_REQUEST,
    FIND_RESULT_RATING_SUCCESS,
    FIND_RESULT_RATING_FAILURE,

    FIND_RESULT_RATINGS_REQUEST,
    FIND_RESULT_RATINGS_SUCCESS,
    FIND_RESULT_RATINGS_FAILURE,

    LOGOUT_USER,
    AUTHENTICATE_FAILURE,

} from 'Redux/Action';
import ResultRatingState from 'Redux/Store/ResultRating/ResultRatingState';
import { removeOpenRequest, removeFailedRequest, addSingleObjectToReducer } from 'ClientService/ReducerUtil/ReducerUtil';

const initialState = ResultRatingState.getInitialState();

export const resultRatingReducer = (state = initialState, action) => {

    switch (action.type) {

    case AUTHENTICATE_FAILURE :
    case LOGOUT_USER :
        return {
            ...initialState,
        };

    case FIND_RESULT_RATING_REQUEST:
    case FIND_RESULT_RATINGS_REQUEST:
        return {
            ...state,
            lastInteractiveId: null,
            openRequests: [...state.openRequests, action.type],
            failedRequests: removeFailedRequest(action, state),
            updatedAt: Date.now(),
        };

    case FIND_RESULT_RATING_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
            resultRatings: {
                ...state.resultRatings,
                entries: addSingleObjectToReducer(state.resultRatings.entries, action.payload.resultRating)
            },
        };

    case FIND_RESULT_RATINGS_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            resultRatings: {
                entries: action.payload.resultRatings,
                maxCount: (action.meta) ? action.meta.response.count : state.resultRatings.maxCount,
            },
            updatedAt: Date.now(),
        };

    case FIND_RESULT_RATING_FAILURE:
    case FIND_RESULT_RATINGS_FAILURE:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            failedRequests: [...state.failedRequests, action.type],
            updatedAt: Date.now(),
        };



    default:
        return state;
    }
};
