import {

    FIND_TARGET_ACTUALS_REQUEST,
    FIND_TARGET_ACTUALS_SUCCESS,
    FIND_TARGET_ACTUALS_FAILURE,

    UPDATE_COMMENT_BY_TARGET_ACTUAL_REQUEST,
    UPDATE_COMMENT_BY_TARGET_ACTUAL_SUCCESS,
    UPDATE_COMMENT_BY_TARGET_ACTUAL_FAILURE,

    LOGOUT_USER,
    DISABLE_USER_SUCCESS,
    AUTHENTICATE_FAILURE,

} from 'Redux/Action';
import TargetActualState from 'Redux/Store/TargetActual/TargetActualState';
import { removeOpenRequest, removeFailedRequest, addSingleObjectToReducer, getSingleObject } from 'ClientService/ReducerUtil/ReducerUtil';

const initialState = TargetActualState.getInitialState();

export const targetActualReducer = (state = initialState, action) => {

    switch (action.type) {

    case AUTHENTICATE_FAILURE :
    case LOGOUT_USER :
    case DISABLE_USER_SUCCESS :
        return {
            ...initialState,
        };

    case FIND_TARGET_ACTUALS_REQUEST:
    case UPDATE_COMMENT_BY_TARGET_ACTUAL_REQUEST:
        return {
            ...state,
            openRequests: [...state.openRequests, action.type],
            failedRequests: removeFailedRequest(action, state),
            updatedAt: Date.now(),
        };

    case UPDATE_COMMENT_BY_TARGET_ACTUAL_SUCCESS:
        const targetActual = getSingleObject(action.payload.targetActual);

        return {
            ...state,
            lastInteractiveGuid: targetActual.id,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
            targetActual: {
                ...state.targetActual,
                entries: addSingleObjectToReducer(state.targetActual.entries, action.payload.targetActual)
            },
        };

    case FIND_TARGET_ACTUALS_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            targetActual: {
                entries: action.payload.targetActuals,
                maxCount: (action.meta) ? action.meta.response.count : state.targetActual.maxCount,
            },
            updatedAt: Date.now(),
        };

    case FIND_TARGET_ACTUALS_FAILURE:
    case UPDATE_COMMENT_BY_TARGET_ACTUAL_FAILURE:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            failedRequests: [...state.failedRequests, action.type],
            updatedAt: Date.now(),
        };



    default:
        return state;
    }
};
