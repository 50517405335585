import {
    FIND_TARGET_ACTUALS_REQUEST,
    UPDATE_COMMENT_BY_TARGET_ACTUAL_REQUEST,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isTargetActualLoading = (state) =>
    ArrayContains(state.targetActual.openRequests, [
        FIND_TARGET_ACTUALS_REQUEST,
        UPDATE_COMMENT_BY_TARGET_ACTUAL_REQUEST,
    ]);