import { SHOW_WARNING_NOTIFICATION } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { message } from 'antd';

export function showWarningNotificationEpic(action$) {
    return action$.pipe(
        ofType(SHOW_WARNING_NOTIFICATION),
        mergeMap(action => {
            message.warning(action.payload.message);
            return EMPTY
        }),
    );
}
