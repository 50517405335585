import React from "react";
import {
    LoadingOutlined,
    UploadOutlined,
    FileOutlined,
    TableOutlined,
    FormOutlined,
    MedicineBoxOutlined,
    EyeOutlined,
    EyeInvisibleOutlined,
    TeamOutlined,
    UserOutlined,
    SettingOutlined,
    LogoutOutlined,
    FolderOutlined,
    SaveOutlined,
    SearchOutlined,
    CloseOutlined,
    ClockCircleOutlined,
    LockOutlined,
    DragOutlined,
    EditOutlined,
    DeleteOutlined,
    InboxOutlined,
    DownloadOutlined,
    CheckCircleOutlined,
    PlusOutlined,
    ReloadOutlined,
    WarningOutlined,
    InfoCircleOutlined,
    FilePdfOutlined,
    FileExcelOutlined,
    FileImageOutlined,
    FileTextOutlined,
    FileZipOutlined,
    RightOutlined,
    LeftOutlined,
    SendOutlined,
    CommentOutlined,
    SnippetsOutlined,
    IssuesCloseOutlined,
    KeyOutlined,
    DashOutlined,
    RollbackOutlined,
    MailOutlined,
    AuditOutlined,
    MessageOutlined,
    LinkOutlined,
    FilterFilled,
    VerticalAlignTopOutlined,
    SecurityScanOutlined,
    QuestionCircleOutlined, CaretUpOutlined, CaretDownOutlined,
} from '@ant-design/icons';


export default class extends React.Component {

    render() {
        const { type, ...props } = this.props;

        switch( type ) {
            case 'carrot-up'     : return <CaretUpOutlined { ...props } />;
            case 'carrot-down'   : return <CaretDownOutlined { ...props } />;
            case 'loading'       : return <LoadingOutlined { ...props } />;
            case 'upload'        : return <UploadOutlined { ...props } />;
            case 'file'          : return <FileOutlined { ...props } />;
            case 'table'         : return <TableOutlined { ...props } />;
            case 'form'          : return <FormOutlined { ...props } />;
            case 'medicine-box'  : return <MedicineBoxOutlined { ...props } />;
            case 'eye'           : return <EyeOutlined { ...props } />;
            case 'eye-invisible' : return <EyeInvisibleOutlined { ...props } />;
            case 'team'          : return <TeamOutlined { ...props } />;
            case 'user'          : return <UserOutlined { ...props } />;
            case 'setting'       : return <SettingOutlined { ...props } />;
            case 'logout'        : return <LogoutOutlined { ...props } />;
            case 'save'          : return <SaveOutlined { ...props } />;
            case 'search'        : return <SearchOutlined { ...props } />;
            case 'close'         : return <CloseOutlined { ...props } />;
            case 'lock'          : return <LockOutlined { ...props } />;
            case 'clock-circle'  : return <ClockCircleOutlined { ...props } />;
            case 'delete'        : return <DeleteOutlined { ...props } />;
            case 'drag'          : return <DragOutlined { ...props } />;
            case 'edit'          : return <EditOutlined { ...props } />;
            case 'inbox'         : return <InboxOutlined { ...props } />;
            case 'download'      : return <DownloadOutlined { ...props } />;
            case 'check'         : return <CheckCircleOutlined { ...props } />;
            case 'plus'          : return <PlusOutlined { ...props } />;
            case 'warning'       : return <WarningOutlined { ...props } />;
            case 'reload'        : return <ReloadOutlined { ...props } />;
            case 'info-circle'   : return <InfoCircleOutlined { ...props } />;
            case 'filter'        : return <FilterFilled { ...props } />;
            case 'file-pdf'      : return <FilePdfOutlined { ...props } />;
            case 'file-excel'    : return <FileExcelOutlined { ...props } />;
            case 'file-image'    : return <FileImageOutlined { ...props } />;
            case 'file-text'     : return <FileTextOutlined { ...props } />;
            case 'file-zip'      : return <FileZipOutlined { ...props } />;
            case 'check-circle'  : return <CheckCircleOutlined { ...props } />;
            case 'right'         : return <RightOutlined { ...props } />;
            case 'left'          : return <LeftOutlined { ...props } />;
            case 'send'          : return <SendOutlined { ...props } />;
            case 'comment'       : return <CommentOutlined { ...props } />;
            case 'report'        : return <SnippetsOutlined { ...props } />;
            case 'issue-close'   : return <IssuesCloseOutlined { ...props } />;
            case 'key'           : return <KeyOutlined { ...props } />;
            case 'dash'          : return <DashOutlined { ...props } />;
            case 'revert'        : return <RollbackOutlined { ...props } />;
            case 'mail'          : return <MailOutlined { ...props } />;
            case 'AuditOutlined' : return <AuditOutlined { ...props } />;
            case 'link'          : return <LinkOutlined { ...props } />;
            case 'message'       : return <MessageOutlined { ...props } />;
            case 'topArrow'      : return <VerticalAlignTopOutlined { ...props } />;
            case 'rule'          : return <SecurityScanOutlined { ...props } />;
            case 'question'      : return <QuestionCircleOutlined { ...props } />;
            case 'folder'        : return <FolderOutlined { ...props } />;

            default : return `No Icon found: (${type})`;
            // no default
        }
    }

}
