import { FIND_PROCESS_STATEMENTS_REQUEST, findProcessStatementsFailure, findProcessStatementsSuccess} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function findProcessStatementsEpic(action$, store$, { ProcessStatementRepository }) {
    return action$.pipe(
        ofType( FIND_PROCESS_STATEMENTS_REQUEST ),
        mergeMap(action => from(
            ProcessStatementRepository.findAll(action.payload)
                .then(({ statements, meta }) => findProcessStatementsSuccess({ processStatements: statements, response: meta, request: action}))
                .catch(error => findProcessStatementsFailure({ error, request: action })),
        )),
    );
}
