import { UPDATE_USER_REQUEST, updateUserFailure, updateUserSuccess } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function updateUserRequestEpic(action$, store$, { UserRepository }) {
    return action$.pipe(
        ofType(UPDATE_USER_REQUEST),
        mergeMap(action => from(
            UserRepository.update( action.payload.updateData )
                .then(({ user }) => updateUserSuccess({ user }))
                .catch(error => updateUserFailure(error)),
        )),
    );
}
