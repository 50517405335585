export const FIND_TARGET_ACTUALS_SUCCESS = 'FIND_TARGET_ACTUALS_SUCCESS';

export const findTargetActualsSuccess = ({ targetActuals, request, response }) => ({
    type: FIND_TARGET_ACTUALS_SUCCESS,
    payload: { targetActuals },
    meta: {
        request,
        response,
    }
});
