const regExpressions = {
    notIn: 'NICHTIN \\(([^)]+)\\)',
    in: 'IN \\(([^)]+)\\)',
    equalNotEqual: '(=\\s?[^ ]+)|(<>\\s?[^ ]+)',
    equal: '=\\s?([^ |\\)]+)',
    notEqual: '<>\\s?([^ |\\)]+)',
    braces: '\\(|\\)',
    and: ' UND ',
    or: ' ODER ',
    greaterThan: '(> \'[^\']+\')',
    lowerThen: '(< \'[^\']+\')',
    dates: "('\\d{2}\\.\\d{4}')",
}

const getAvailableKeysFromCondition = (condition) => {
    // replace all condition matches to extract the keys
    return [ ...new Set(condition
        .replace(new RegExp(regExpressions.notIn, 'gi'), '')
        .replace(new RegExp(regExpressions.in, 'gi'), '')
        .replace(new RegExp(regExpressions.equalNotEqual, 'gi'), '')
        .replace(new RegExp(regExpressions.braces, 'gi'), '')
        .replace(new RegExp(regExpressions.and, 'gi'), '')
        .replace(new RegExp(regExpressions.or, 'gi'), '')
        .replace(new RegExp(regExpressions.greaterThan, 'gi'), '')
        .replace(new RegExp(regExpressions.lowerThen, 'gi'), '')
        .split(' ')
        .map(key => `${key}`.trim())
        .filter(key => !!`${key}`.trim())
    )];
}

const getReplacementTextNotIn = (condition) => {
    return condition.match(new RegExp(`([A-Z0-9]*) ${regExpressions.notIn}`, 'gi'))?.map(result => {
        const match = result.match(new RegExp(`([A-Z0-9]*) ${regExpressions.notIn}`, 'i'));
        return {
            text: `!['${match[2].split(';').join("','")}'].includes(${match[1]})`,
            match: match[0]
        };
    });
}

const getReplacementTextIn = (condition) => {
    return condition.match(new RegExp(`([A-Z0-9]*) ${regExpressions.in}`, 'gi'))?.map(result => {
        const match = result.match(new RegExp(`([A-Z0-9]*) ${regExpressions.in}`, 'i'));
        return {
            text: `['${match[2].split(';').join("','")}'].includes(${match[1]})`,
            match: match[0]
        };
    });
}

const replaceEmpty = (str) => {
    return `${str === 'LEER' ? str.replace('LEER', 'null') : `'${str}'`}`
}

const getReplacementEqual = (condition) => {
    return condition.match(new RegExp(`${regExpressions.equal}`, 'gi'))?.map(result => {
        const match = result.match(new RegExp(`${regExpressions.equal}`, 'i'));
        return {
            text: `=== ${replaceEmpty(match[1])}`,
            match: match[0]
        };
    });
}

const getReplacementNotEqual = (condition) => {
    return condition.match(new RegExp(`${regExpressions.notEqual}`, 'gi'))?.map(result => {
        const match = result.match(new RegExp(`${regExpressions.notEqual}`, 'i'));
        return {
            text: `!== ${replaceEmpty(match[1])}`,
            match: match[0]
        };
    });
}

const getReplacementDates = (condition) => {
    return condition.match(new RegExp(`${regExpressions.dates}`, 'gi'))?.map(result => {
        const match    = result.match(new RegExp(`${regExpressions.dates}`, 'i'));
        const split    = match[1].replace(/'/g, '').split('.');
        const datetime = new Date(split[1], split[0], 0);
        datetime.setTime(datetime.getTime() + 86399000); // end of day

        return {
            text: datetime.getTime(),
            match: match[0]
        };
    });
}


const replaceByReplacements = (condition, replacements) => {
    Object.keys(replacements).forEach(key => {
        replacements[key].forEach(replacement => {
            condition = condition.replace(replacement.match, replacement.text);
        });
    });
    return condition.replace(/ ODER /gi, ' || ').replace(/ UND /gi, ' && ');
}

export const transformConditionText = (condition) => {
    const notInReplacements    = getReplacementTextNotIn(condition);
    const inReplacements       = getReplacementTextIn(condition);
    const equalReplacements    = getReplacementEqual(condition);
    const notEqualReplacements = getReplacementNotEqual(condition);
    const dateReplacements     = getReplacementDates(condition);

    const replacements = Object.assign({},
        notInReplacements ? { notIn: notInReplacements } : null,
        inReplacements ? { in: inReplacements } : null,
        equalReplacements ? { equal: equalReplacements } : null,
        notEqualReplacements ? { notEqual: notEqualReplacements } : null,
        dateReplacements ? { dates: dateReplacements } : null,
    );

    return { condition: replaceByReplacements(condition, replacements), replacements };
}

export const ruleParser = (rules) => {
    const parsedRules = [];
    rules.forEach(rule => {
        parsedRules.push({
            ...rule,
            conditionText: rule.condition,
            availableKeys: getAvailableKeysFromCondition(rule.condition),
            ...transformConditionText(rule.condition)
        });
    })

    return parsedRules;
}
