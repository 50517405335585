import Button                   from 'Common/Button/Button';
import Icon                     from 'Common/Icon/Icon';
import React                    from 'react';
import { connect }              from 'react-redux';
import { getUserRole }          from 'Redux/Query';
import Style                    from './Footer.style';
import { version, handbookUrl } from 'AppConfig/Config';


class Footer extends React.Component {


    handleOnScrollToTop = () => {
        window.scrollTo(0, 0);
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    render() {


        const year = new Date().getFullYear();

        return (
            <Style>
                <Button onClick={ this.handleOnScrollToTop } className="scrollToTop"><Icon type="topArrow" /></Button>
                &copy; {year} unitrend GmbH |&nbsp;
                <a href={"https://www.unitrend.de/qspversion"} target={"_blank"}>Version {version}</a> |&nbsp;
                <a href={ handbookUrl[this.props.role] || handbookUrl.default } target={"_blank"}>Handbuch</a>
            </Style>
        );
    }

}

const mapStateToProps = (state) => ({
    role: getUserRole(state),
});

export default connect(mapStateToProps)(Footer);
