import { FIND_EMPLOYEE_REQUEST, findEmployeeFailure, findEmployeeSuccess} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from, EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function findEmployeeEpic(action$, store$, { EmployeeRepository }) {
    return action$.pipe(
        ofType(FIND_EMPLOYEE_REQUEST),
        mergeMap( action =>{
            const { id } = action.payload;

            if(!id.trim()) {
                return EMPTY;
            }

            return from(
                EmployeeRepository.findById( id )
                    .then(employee => findEmployeeSuccess(employee))
                    .catch(error => findEmployeeFailure(error)),
            );
        }),
    );
}
