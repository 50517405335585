import { APPLICATION_BOOTSTRAPPED, getSettingsRequest } from "Redux/Action";
import { ofType } from "redux-observable";
import { mergeMap } from "rxjs/operators";
import { from } from "rxjs";

export function initializeBulkLoad( action$ ) {

    return action$.pipe(
          ofType( APPLICATION_BOOTSTRAPPED ),
          mergeMap(( action ) => {
                return from([
                    getSettingsRequest(),
                ]);

          })
    );

}