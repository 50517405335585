import { ArrayContains } from 'ClientService';
import {
    DISABLE_USER_REQUEST,
    UPDATE_USER_REQUEST,
    FORGOT_PASSWORD_REQUEST,
    RESET_PASSWORD_REQUEST,
    REGISTER_REQUEST,
} from 'Redux/Action';

export const isUserLoading = (state) =>
    ArrayContains(state.user.openRequests, [
        DISABLE_USER_REQUEST,
        UPDATE_USER_REQUEST,
        FORGOT_PASSWORD_REQUEST,
        RESET_PASSWORD_REQUEST,
        REGISTER_REQUEST,
    ]);



