import { FIND_OPEN_PROCESS_STATEMENTS_REQUEST, findOpenProcessStatementsSuccess, findOpenProcessStatementsFailure } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function findOpenProcessStatementsEpic(action$, store$, { ProcessStatementRepository }) {
    return action$.pipe(
        ofType( FIND_OPEN_PROCESS_STATEMENTS_REQUEST ),
        mergeMap(action => from(
            ProcessStatementRepository.findAllFromInbox(action.payload)
                .then(({ statements, meta }) => findOpenProcessStatementsSuccess({ processStatements: statements, response: meta, request: action}))
                .catch(error => findOpenProcessStatementsFailure({ error, request: action })),
        )),
    );
}
