export const parseQueryArguments = (queryString = '') => {
    const str  = queryString.replace(/\?/, '');
    const splitetString = str.split('&');

    const args = {};

    splitetString.forEach(arg => {
        const tmp = arg.split('=');
        args[tmp[0]] = tmp[1];
    });

    return args;
};