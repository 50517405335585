import { FORGOT_PASSWORD_REQUEST, forgotPasswordFailure, forgotPasswordSuccess } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function forgotPasswordRequestEpic(action$, store$, { UserRepository }) {
    return action$.pipe(
        ofType(FORGOT_PASSWORD_REQUEST),
        mergeMap(action => from(
            UserRepository.forgotPassword( action.payload.mail )
                .then(() => forgotPasswordSuccess())
                .catch(error => forgotPasswordFailure(error)),
        )),
    );
}
