import React from 'react';
import Content from './Content.style';

export default class extends React.Component {

    render() {
        return (
            <Content>
                { this.props.children }
            </Content>
        );
    }

};