import { WINDOW_TYPES } from 'AppConfig/Config/WindowTypes';

const getInitialState = () => ({
    openRequests: [],
    failedRequests: [],
    updatedAt: 0,
    isInitialized: false,
    isApplicationRunning: false,
    activeSideMenuTab: 'documentUpload',
    currentWindowMode: WINDOW_TYPES.DESKTOP,
    settings: {},
    networkError: false,
    temporaryData: {},
});

export default {
    getInitialState
};
