import {
    FIND_FILES_REQUEST,
    FIND_FILES_FAILURE,
    FIND_FILES_SUCCESS,

    FIND_FILE_REQUEST,
    FIND_FILE_FAILURE,
    FIND_FILE_SUCCESS,

    ADD_ZIP_FILE,
    REMOVE_ZIP_FILE,
    TOGGLE_ZIP_MENU,

    DOWNLOAD_FILES_AS_ZIP_REQUEST,
    DOWNLOAD_FILES_AS_ZIP_FAILURE,
    DOWNLOAD_FILES_AS_ZIP_SUCCESS,

    LOGOUT_USER,
    DISABLE_USER_SUCCESS,
    AUTHENTICATE_FAILURE,

} from 'Redux/Action';
import FileState from 'Redux/Store/File/FileState';
import { removeOpenRequest, removeFailedRequest, addSingleObjectToReducer, getSingleObject } from 'ClientService/ReducerUtil/ReducerUtil';

const initialState = FileState.getInitialState();

export const fileReducer = (state = initialState, action) => {

    switch (action.type) {

    case AUTHENTICATE_FAILURE :
    case LOGOUT_USER :
    case DISABLE_USER_SUCCESS :
        return {
            ...initialState,
        };

    case FIND_FILE_REQUEST:
    case DOWNLOAD_FILES_AS_ZIP_REQUEST:
    case FIND_FILES_REQUEST:
        return {
            ...state,
            openRequests: [...state.openRequests, action.type],
            failedRequests: removeFailedRequest(action, state),
            updatedAt: Date.now(),
        };

    case FIND_FILE_SUCCESS:
        const file = getSingleObject(action.payload.file);

        return {
            ...state,
            lastInteractiveGuid: file.guid,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
            files: {
                ...state.files,
                entries: addSingleObjectToReducer(state.files.entries, action.payload.file)
            },
        };

    case ADD_ZIP_FILE: {
        const { id, modelType } = action.payload;
        if(state.zipFiles.find((file) => file.id === id && file.modelType === modelType)) {
            return { ...state };
        }
        return {
            ...state,
            updatedAt: Date.now(),
            zipFiles: [
                ...state.zipFiles,
                action.payload,
            ],
        };
    }

    case REMOVE_ZIP_FILE: {
        const { id, modelType } = action.payload;
        const zipFiles = state.zipFiles.filter(file => !(file.id === id && file.modelType === modelType));
        return {
            ...state,
            updatedAt: Date.now(),
            zipFiles,
        };
    }

    case DOWNLOAD_FILES_AS_ZIP_SUCCESS: {
        window.location = window.location.href;
        return {
            ...state,
            updatedAt: Date.now(),
            zipFiles: [],
            showZipMenu: false,
        };
    }

    case TOGGLE_ZIP_MENU: {
        return {
            ...state,
            updatedAt: Date.now(),
            showZipMenu: !state.showZipMenu,
        }
    }


    case FIND_FILES_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            files: {
                entries: action.payload.files,
                maxCount: (action.meta) ? action.meta.response.count : state.files.maxCount,
            },
            updatedAt: Date.now(),
        };

    case FIND_FILE_FAILURE:
    case DOWNLOAD_FILES_AS_ZIP_FAILURE:
    case FIND_FILES_FAILURE:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            failedRequests: [...state.failedRequests, action.type],
            updatedAt: Date.now(),
        };



    default:
        return state;
    }
};
