export const FIND_TARGET_ACTUALS_REQUEST = 'FIND_TARGET_ACTUALS_REQUEST';

export const findTargetActualsRequest = ({
     filter = [],
     sorting = { field: 'name', order: 'ASC' },
     pagination = { page: 1, take: 50 },
 }) => ({
    type: FIND_TARGET_ACTUALS_REQUEST,
    payload: { filter, sorting, pagination },
    meta: {
        sorting,
        pagination,
    }
});
