import { FIND_TARGET_ACTUALS_REQUEST, findTargetActualsSuccess, findTargetActualsFailure } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function findTargetActualsEpic(action$, store$, { TargetActualRepository }) {
    return action$.pipe(
        ofType( FIND_TARGET_ACTUALS_REQUEST ),
        mergeMap(action => from(
            TargetActualRepository.findAll(action.payload)
                .then(({ targetActuals, meta }) => findTargetActualsSuccess({ targetActuals, response: meta, request: action}))
                .catch(error => findTargetActualsFailure({ error, request: action })),
        )),
    );
}
