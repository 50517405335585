import cloneDeep from 'lodash/cloneDeep';
import isObject from 'lodash/isObject';

export const getIdFromSingleObject = (singleObject) => {
    if(isObject(singleObject)) {
        const keys = Object.keys(singleObject);
        if(keys.length >= 1) {
            if(keys.includes('id')) {
                return singleObject.id;
            }
            return singleObject[keys[0]].id;
        }
    }
    else if(Array.isArray(singleObject) && singleObject.length >= 1) {
        return singleObject[0].id;
    }

    return null;
};

export const getSingleObject = (singleObject) => {
    if(isObject(singleObject)) {
        const keys = Object.keys(singleObject);
        if(keys.length >= 1) {
            if(keys.includes('id')) {
                return singleObject;
            }
            return singleObject[keys[0]];
        }
    }
    else if(Array.isArray(singleObject) && singleObject.length >= 1) {
        return singleObject[0];
    }

    return null;
};

export const addSingleObjectToReducer = (Store, singleObject) => {
    const baseStore = cloneDeep(Store);
    let objectKey   = null;
    let baseObject  = null;

    const addToReducer = () => {
        if(!objectKey || !baseObject) {
            return false;
        }

        if(baseStore.hasOwnProperty(objectKey)) {
            for(const key in baseObject) {
                if(key === 'createdAt') {
                    continue;
                }
                if(baseObject.hasOwnProperty(key)) {
                    baseStore[objectKey][key] = baseObject[key];
                }
            }
        }
        else {
            baseStore[objectKey] = baseObject;
        }


        return true;
    };

    if(isObject(singleObject)) {
        const keys = Object.keys(singleObject);
        if(keys.length === 1) {
            objectKey  = keys[0];
            baseObject = singleObject[objectKey];
        }
        else if(keys.length > 1 && keys.includes('id')) {
            objectKey  = singleObject.id;
            baseObject = singleObject;
        }

        addToReducer();
    }
    else if(Array.isArray(singleObject) && singleObject.length === 1) {
        return addSingleObjectToReducer(Store, { ...singleObject });
    }

    return baseStore;
};

export const mergeReducerByArray = (store, secondStore, meta = {}) => {
    const mainStore = cloneDeep(store);

    const addById = addEntry => {
        let willBeAdd = true;

        for(let i = 0, l = mainStore.length; i < l; i++) {
            if(mainStore[i].id === addEntry.id) {
                mainStore[i] = cloneDeep(addEntry);
                willBeAdd = false;
                break;
            }
        }

        if(willBeAdd) {
            mainStore.push(cloneDeep(addEntry));
        }
    };

    secondStore.forEach(entry => {
        if(entry instanceof Object && entry.hasOwnProperty('id')) {
            addById(entry);
        }

        if(typeof entry === 'number'){
            if(!mainStore.includes(entry)) {
                mainStore.push(entry);
            }
        }
    });

    if(meta.hasOwnProperty('extend') && meta.extend === false) {
        return mainStore || [];
    }


    return mainStore;

};

export const mergeReducerByObject = (store, secondStore, meta = {}) => {
    const mainStore = cloneDeep(store);

    if(meta.hasOwnProperty('extend') && meta.extend === false) {
        return mainStore || {};
    }

    for(const addEntryId in secondStore ){
        const addEntry = secondStore[addEntryId];
        if(mainStore.hasOwnProperty(addEntryId)) {
            mainStore[addEntryId] = cloneDeep(addEntry);
        }
        else {
            mainStore[addEntryId] = addEntry;
        }
    }

    return mainStore;
};

export const mergeReducer = (store, secondStore, meta = {}) => {
    if(Array.isArray(store)) {
        return mergeReducerByArray(store, secondStore, meta);
    }

    if(store instanceof Object) {
        return mergeReducerByObject(store, secondStore, meta);
    }

    return store;

};

export const removeOpenRequest = (action, state) => {
    const openRequests = [...state.openRequests];
    let actionType = '';
    if(action.type.match('SUCCESS')) {
        actionType = action.type.replace('SUCCESS', 'REQUEST');

    }
    else if(action.type.match('FAILURE')) {
        actionType = action.type.replace('FAILURE', 'REQUEST');
    }
    else {
        return openRequests;
    }

    let index = openRequests.indexOf(actionType);
    while(index > -1){
        openRequests.splice(index, 1);
        index = openRequests.indexOf(actionType);
    }
    return [...new Set(openRequests)];
};

export const removeFailedRequest = (action, state) => {
    const failedRequests = [...state.failedRequests];
    let actionType = '';
    if(action.type.match('REQUEST')) {
        actionType = action.type.replace('REQUEST', 'FAILURE');
    }
    else {
        return failedRequests;
    }

    let index = failedRequests.indexOf(actionType);
    while(index > -1){
        failedRequests.splice(index, 1);
        index = failedRequests.indexOf(actionType);
    }
    return [...new Set(failedRequests)];
};
