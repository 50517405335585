import styled from 'styled-components';
import { Layout } from "antd";
import { white } from "Public/css/colors";

export default styled(Layout.Header) `
    background-color: ${ white };
    
    height: 64px;
    line-height: 64px;
    margin-bottom: 24px;
    
    text-align: right;
    
    & > div {
      text-align: left;
      display: inline-block;
      margin-right: 32px;
    }
`;
