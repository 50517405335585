import { ADM_KH, KH }                                           from 'AppConfig/Config';
import { filterObjectStore, queryFilter, userContainsRole } from 'Redux/Query';

export const getEmployeeRoles = (state, filter = null) => {
    if(filter === null) {
        filter = queryFilter({sorting: { by: 'id', order: 'ASC' }})
    }

    const roles = filterObjectStore(state.employeeRole.employeeRoles.entries, filter);
    if(userContainsRole(state)([ADM_KH])) {
        return roles.filter(role => [KH, ADM_KH].includes(role.id))
    }

    return roles;
};
