import {
    UPDATE_STATEMENT_TEMPLATE_REQUEST,
    UPDATE_RESULTS_QSEB_REQUEST,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isResultUpdating = (state) =>
    ArrayContains(state.result.openRequests, [
        UPDATE_STATEMENT_TEMPLATE_REQUEST,
        UPDATE_RESULTS_QSEB_REQUEST,
    ]);