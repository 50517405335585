import BaseRepository from '../Base/BaseRepository';
import File from 'Domain/Model/File/File';
import isEmpty from 'lodash/isEmpty';
import each from "lodash/each";

class FileRepository extends BaseRepository {

    filterSettings = {};

    setFilterSettings(settings) {
        super.setFilterSettings(settings);

        if( this.filterSettings.hasOwnProperty('filter_username')) {
            this.filterSettings['filter_`Employee`.`title`']      = this.filterSettings['filter_username'];
            this.filterSettings['filter_`Employee`.`first_name`'] = this.filterSettings['filter_username'];
            this.filterSettings['filter_`Employee`.`last_name`']  = this.filterSettings['filter_username'];

            delete this.filterSettings['filter_username'];
        }

        return this;
    };

    findByGuid(guid) {
        return this.makeRequest({
            path: `/file/${guid}`,
            type: 'GET',
            callback: this.initializeSingleModel
        });
    }

    downloadFilesAsZip(files) {
        return this.makeRequest({
            path: `/file/zip-downloader`,
            type: 'POST',
            data: { files },
            callback: this.fetchDownloadZip
        });
    }

    deleteFileReferenceByGuid(guid) {
        return this.makeRequest({
            path: `/file-reference/${guid}`,
            type: 'DELETE',
            callback: this.initializeSingleModel
        });

    }

    downloadByGuid(guid) {
        return this.makeRequest({
            path: `/${this.baseRoute}/download/${guid}`,
            type: 'GET',
            callback: this.fetchDownload
        });

    }

    initializeModels = (responseObject) => {
        if(responseObject.hasOwnProperty('files')){
            return { files: this.iterate(responseObject.files), meta: responseObject.meta };
        }

        return null;
    };


    iterate = (dataEntries, initializeFunction = this.initializeModel) => {
        const objectStorage = {};
        each(dataEntries, entry => {
            const newModel = initializeFunction(entry);
            objectStorage[newModel.guid] = newModel;
        });
        return objectStorage;

    };

    fetchDownload = (responseData, response) => {
        response.blob()
            .then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = 'employees.json';
                a.click();
            })
            .catch( console.error );
    };


    fetchDownloadZip = (responseData) => {
        const byteCharacters = atob(responseData.zipContent);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: 'application/zip'});

        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = responseData.filename;
        a.click();
    };

    initializeSingleModel = (response) => {
        if(isEmpty(response)) {
            return {};
        }

        const entry     = (response.hasOwnProperty('file')) ? response.file : response;
        const newModel  = this.initializeModel(entry);

        return { [newModel.guid]: newModel };
    };
}

export default new FileRepository({
    model: File,
    baseRoute: 'file',
    singular: 'file',
    plural: 'files',
});
