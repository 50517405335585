import {
    CREATE_EMPLOYEE_REQUEST,
    CREATE_EMPLOYEE_FAILURE,
    CREATE_EMPLOYEE_SUCCESS,

    UPDATE_EMPLOYEE_REQUEST,
    UPDATE_EMPLOYEE_FAILURE,
    UPDATE_EMPLOYEE_SUCCESS,

    FIND_EMPLOYEE_REQUEST,
    FIND_EMPLOYEE_FAILURE,
    FIND_EMPLOYEE_SUCCESS,

    FIND_EMPLOYEES_REQUEST,
    FIND_EMPLOYEES_FAILURE,
    FIND_EMPLOYEES_SUCCESS,

    DELETE_EMPLOYEE_REQUEST,
    DELETE_EMPLOYEE_SUCCESS,
    DELETE_EMPLOYEE_FAILURE,

    LOGOUT_USER,
    DISABLE_USER_SUCCESS,
    AUTHENTICATE_FAILURE,

} from 'Redux/Action';
import EmployeeState from 'Redux/Store/Employee/EmployeeState';
import { removeOpenRequest, removeFailedRequest, addSingleObjectToReducer, getSingleObject } from 'ClientService/ReducerUtil/ReducerUtil';

const initialState = EmployeeState.getInitialState();

export const employeeReducer = (state = initialState, action) => {

    switch (action.type) {

    case AUTHENTICATE_FAILURE :
    case LOGOUT_USER :
    case DISABLE_USER_SUCCESS :
        return {
            ...initialState,
        };

    case CREATE_EMPLOYEE_REQUEST:
    case DELETE_EMPLOYEE_REQUEST:
    case UPDATE_EMPLOYEE_REQUEST:
    case FIND_EMPLOYEE_REQUEST:
    case FIND_EMPLOYEES_REQUEST:
        return {
            ...state,
            lastInteractiveId: null,
            openRequests: [...state.openRequests, action.type],
            failedRequests: removeFailedRequest(action, state),
            updatedAt: Date.now(),
        };

    case CREATE_EMPLOYEE_SUCCESS:
    case UPDATE_EMPLOYEE_SUCCESS:
    case FIND_EMPLOYEE_SUCCESS:
        const employee = getSingleObject(action.payload.employee);

        return {
            ...state,
            lastInteractiveId: employee.id,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
            employees: {
                ...state.employees,
                entries: addSingleObjectToReducer(state.employees.entries, action.payload.employee)
            },
        };

    case DELETE_EMPLOYEE_SUCCESS:
        const employeeEntries = { ...state.employees.entries };
        if(employeeEntries[action.payload.id]) {
            delete employeeEntries[action.payload.id];
        }

        return {
            ...state,
            lastInteractiveId: action.payload.id,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
            employees: {
                maxCount: state.employees.maxCount - 1,
                entries: employeeEntries
            },
        };

    case FIND_EMPLOYEES_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            employees: {
                entries: action.payload.employees,
                maxCount: (action.meta) ? action.meta.response.count : state.employees.maxCount,
            },
            updatedAt: Date.now(),
        };

    case CREATE_EMPLOYEE_FAILURE:
    case UPDATE_EMPLOYEE_FAILURE:
    case FIND_EMPLOYEE_FAILURE:
    case DELETE_EMPLOYEE_FAILURE:
    case FIND_EMPLOYEES_FAILURE:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            failedRequests: [...state.failedRequests, action.type],
            updatedAt: Date.now(),
        };



    default:
        return state;
    }
};
