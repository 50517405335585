import { LOAD_PROCESS_STATEMENT_RTF_REQUEST, loadProcessStatementRtfSuccess, loadProcessStatementRtfFailure } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from, EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function loadProcessStatementRtfEpic(action$, store$, { SettingsRepository }) {
    return action$.pipe(
        ofType(LOAD_PROCESS_STATEMENT_RTF_REQUEST),
        mergeMap( action => from(
            SettingsRepository.loadProcessStatementRtf()
                .then(rtfTemplate => loadProcessStatementRtfSuccess({ rtfTemplate }))
                .catch(error => loadProcessStatementRtfFailure(error)),
        )),
    );
}
