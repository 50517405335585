import {
    CREATE_CARE_PROVIDER_FAILURE,
    UPDATE_CARE_PROVIDER_FAILURE,
    FIND_CARE_PROVIDER_FAILURE,
    FIND_CARE_PROVIDERS_FAILURE,
    MASS_DE_PSEUDO_FAILURE,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isCareProviderFailed = (state) => ArrayContains(state.careProvider.failedRequests, [
    CREATE_CARE_PROVIDER_FAILURE,
    UPDATE_CARE_PROVIDER_FAILURE,
    FIND_CARE_PROVIDER_FAILURE,
    FIND_CARE_PROVIDERS_FAILURE,
    MASS_DE_PSEUDO_FAILURE,
]);