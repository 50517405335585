import {
    ADD_NEW_CHAT_MESSAGE_SUCCESS,
    FIND_CHAT_CONVERSATIONS_SUCCESS,
    CREATE_NEW_CHAT_CONVERSATION_SUCCESS,
    COUNT_OPEN_CHAT_CONVERSATIONS_REQUEST,
    AUTHENTICATE_SUCCESS,

    countOpenChatConversationsSuccess,
    countOpenChatConversationsFailure,
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from, of, EMPTY } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from "rxjs/operators";
import { KH, VA, LAG_GS } from "AppConfig/Config";
import { userContainsRole } from "Redux/Query";

export function countOpenChatConversationsEpic(action$, store$, { ChatRepository }) {
    return action$.pipe(
        ofType(
            ADD_NEW_CHAT_MESSAGE_SUCCESS,
            FIND_CHAT_CONVERSATIONS_SUCCESS,
            CREATE_NEW_CHAT_CONVERSATION_SUCCESS,
            COUNT_OPEN_CHAT_CONVERSATIONS_REQUEST,
            AUTHENTICATE_SUCCESS,
        ),
        withLatestFrom(store$),
        mergeMap(([ action, store ]) => {
            if(!userContainsRole(store)([ KH, VA, LAG_GS ])) {
                return EMPTY;
            }

            return from(ChatRepository.countOpenChatConversations()).pipe(
                mergeMap(( count ) => {
                    return from([
                        countOpenChatConversationsSuccess({ count, request: action})
                    ]);
                }),
                catchError(error => of(countOpenChatConversationsFailure({ error, request: action })))
            )
        })

    );
}
