import { SHOW_NOTIFICATION } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { message } from 'antd';

export function showNotificationEpic(action$) {
    return action$.pipe(
        ofType(SHOW_NOTIFICATION),
        mergeMap(action => {
            message.success(action.payload.message);
            return EMPTY
        }),
    );
}
