import FormData from 'form-data';
import { isNotEmpty } from 'ClientService/Validation/ValidationService';

export default class {

    constructor() {
        this.images              = [];
        this.files               = [];
        this.additionalImageData = {};
        this.formData            = {};

    }

    add(key, value, override = false) {
        if(override || !this.formData.hasOwnProperty(key)) {
            return this.set(key, value);
        }

        const type = typeof this.formData[key];
        switch(type.toLowerCase()) {
            case 'string'  :
            case 'number'  :
            case 'boolean' :
                return this.set(key, value);

            case 'object' :
                if(Array.isArray(this.formData[key])) {
                    this.formData[key].push(value);
                    return this;
                }
                return this.set(key, value);

            default :
                return this.set(key, value);

        }
    }

    addImage(image, key = 'images') {
        if(typeof image !== 'object'){
            console.error('the given image is not an valid image Object');
            return this;
        }

        if(!image.hasOwnProperty('uid') || !image.hasOwnProperty('blobData')) {
            if(image.hasOwnProperty('id') && image.hasOwnProperty('sorting')) {
                this.additionalImageData[image.uid] = { sorting: image.sorting, id: image.id, update: true };
            }
            return this;
        }
        if(image.hasOwnProperty('additionalImageData')) {
            this.additionalImageData[image.uid] = { ...image.additionalImageData };
            if(image.sorting) {
                this.additionalImageData[image.uid].sorting = image.sorting;
            }
        }

        this.images.push({key, image});

        return this;
    }

    addFile(file, key = 'files') {
        if(typeof file !== 'object'){
            console.error('the given file is not an valid file Object');
            return this;
        }
        this.files.push({key, file});

        return this;
    }

    addImages(images, key = 'images') {
        if(!Array.isArray(images)){
            console.warn('images are not an array');
            return this.addImage(images, key);
        }

        images.forEach(image => this.addImage(image, key));

        return this;
    }

    addFiles(files, key = 'files') {
        if(!Array.isArray(files)){
            console.warn('files are not an array');
            return this.addImage(files, key);
        }

        files.forEach(file => this.addFile(file, key));

        return this;
    }

    set(key, value) {
        this.formData[key] = value;
        return this;
    }

    get(key) {
        if(this.has(key)){
            return this.formData[key];
        }
        return null;
    }

    has(key) {
        return this.formData.hasOwnProperty(key);
    }


    parseData(formDataName = 'data') {
        const form = new FormData();


        if(this.images.length > 0) {
            this.set('_additionalImageData', this.additionalImageData);
        }
        if(isNotEmpty(this.additionalImageData)) {
            this.set('_additionalImageData', this.additionalImageData);
        }

        form.append(formDataName, JSON.stringify(this.formData));

        if(this.images.length > 0) {
            this.images.forEach(({key, image}) => form.append(key, (image.origin) ? image.origin.blobData : image.blobData, image.uid))
        }
        if(this.files.length > 0) {
            this.files.forEach(({key, file}) => form.append(`${key}`, (file.originFileObj) ? file.originFileObj : file, file.name))
        }


        return form;
    }

    reset() {
        this.images              = [];
        this.additionalImageData = {};
        this.formData            = {};
    }


}
