import { DISABLE_USER_REQUEST, disableUserFailure, disableUserSuccess } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function disableUserRequestEpic(action$, store$, { UserRepository }) {
    return action$.pipe(
        ofType(DISABLE_USER_REQUEST),
        mergeMap(action => from(
            UserRepository.disable(action.payload.password)
                .then(() => disableUserSuccess())
                .catch(error => disableUserFailure(error)),
        )),
    );
}
