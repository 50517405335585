import {
    DELETE_PROCESS_STATEMENT_REQUEST,
    deleteProcessStatementSuccess,
    deleteProcessStatementFailure,
    showNotification
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';

export const deleteProcessStatementEpic = (action$, store$, { ProcessStatementRepository }) => {
    return action$.pipe(
        ofType(DELETE_PROCESS_STATEMENT_REQUEST),
        mergeMap(action => from(ProcessStatementRepository.remove( action.payload.id ))
            .pipe(
                mergeMap(() => {
                    return from([
                        showNotification(`Die Stellungnahme wurde erfolgreich gelöscht.`),
                        deleteProcessStatementSuccess(action.payload.id),
                    ]);
                }),
                catchError( error => {
                    return from([
                        deleteProcessStatementFailure(error),
                    ])
                })
            )
        ),
    );
}
