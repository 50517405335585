/* eslint-disable no-undef,no-console */
import requestApi from 'ClientService/RequestApi';
import each    from 'lodash/each';
import isEmpty from 'lodash/isEmpty';

export default class {

    model          = null;
    filterSettings = {};
    requestApi     = requestApi;
    baseRoute      = '/';
    singular       = 'entry';
    plural         = 'entries';

    constructor( { model = null, baseRoute = null, singular = null, plural = null } ){
        this.baseRoute = baseRoute;

        if(singular) {
            this.singular = singular;
        }

        if(plural) {
            this.plural = plural;
        }

        if(model) {
            this.setModel(model);
        }

        this.initialize();
    }

    initialize = () => {
        return this;
    };

    setModel(model) {
        this.model = model;
        return this;
    }

    getFilterSettings = () => {
        const filterSettings = Object.assign({}, this.filterSettings);
        this.filterSettings  = {};

        return filterSettings
    };

    setFilterSettings(settings) {

        let filterSettings = {
            sortField: 'id',
            sortDirection: 'ASC',
            page: 1,
            take: 50,
        };

        if(settings.hasOwnProperty('filter')) {
            settings.filter.forEach(filter => {
                filterSettings[`filter_${filter.field}`] = filter.value;
            })
        }
        if(settings.hasOwnProperty('pagination')) {
            filterSettings = Object.assign({}, filterSettings, settings.pagination);
        }
        if(settings.hasOwnProperty('sorting')) {
            filterSettings.sortField = settings.sorting.field;
            filterSettings.sortDirection = settings.sorting.order;
        }

        this.filterSettings = filterSettings;

        return this;
    };



    findAll(filterSettings = {}) {
        return this.setFilterSettings(filterSettings).makeRequest({
            path: `/${this.baseRoute}`,
            data: this.getFilterSettings(),
            type: 'GET'
        });
    }

    findById(id) {
        return this.makeRequest({
            path: `/${this.baseRoute}/${id}`,
            type: 'GET',
            callback: this.initializeSingleModel
        });

    }

    findBy( data ) {
        return this.makeRequest({
            path: `/${this.baseRoute}/list`,
            data: data,
            type: 'POST'
        });
    }

    remove(id) {
        return this.makeRequest({
            path: `/${this.baseRoute}/${id}`,
            type: 'DELETE'
        });
    }

    update( id = null, data ) {
        return this.makeRequest({
            path: `/${this.baseRoute}/${id}`,
            data: data,
            type: 'PATCH',
            callback: this.initializeSingleModel
        });
    }

    bulkUpdate( entries ) {
        return this.makeRequest({
            path: `/${this.baseRoute}`,
            data: { entries },
            type: 'PATCH',
        });
    }

    create(data) {
        return this.makeRequest({
            path: `/${this.baseRoute}`,
            data: data,
            type: 'POST',
            callback: this.initializeSingleModel
        });
    };


    iterate = (dataEntries, initializeFunction = this.initializeModel) => {
        const objectStorage = {};
        each(dataEntries, entry => {
            const newModel = initializeFunction(entry);
            objectStorage[newModel.id] = newModel;
        });
        return objectStorage;

    };

    initializeModel = (entry)  => {
        return new this.model( entry );
    };

    initializeSingleModel = ( response ) => {
        if(isEmpty(response)) {
            return {};
        }

        const newModel  = this.initializeModel((response.hasOwnProperty(this.singular)) ? response[this.singular] : response);
        return { [newModel.id]: newModel };
    };

    initializeModels = ( response ) => {
        if(isEmpty( response )) {
            return {};
        }
        return Object.assign(
            {},
            {
                [this.plural]: this.iterate( (response.hasOwnProperty(this.plural)) ? response[this.plural] : response )
            },
            (response.hasOwnProperty('meta')) ? { meta: response.meta } : null,

        );
    };


    makeRequest({ type = 'get', path = `/${this.baseRoute}`, data = null, callback = this.initializeModels }, logs = true) {

        return new Promise((resolve, reject) => {
            if(process.env.NODE_ENV === 'development') {
                logs && console.log(`REQUEST ${type.toUpperCase()}: `, path, data);
            }

            requestApi[type.toLowerCase()](path, data)
                .then(response => {
                    if(response.ok && ( response.data.status === 'success' || response.status === 200 )) {
                        if(process.env.NODE_ENV === 'development') {
                            logs && console.log(`RESPONSE ${type.toUpperCase()}: `, path, response.data);
                        }
                        resolve( callback(response.data, response ) );
                    }
                    else {
                        if(process.env.NODE_ENV === 'development') {
                            logs && console.log(`RESPONSE ERROR ${type.toUpperCase()}: `, path, response);
                        }
                        reject(response);
                    }
                }).catch((error)=> {
                    if(process.env.NODE_ENV === 'development') {
                        logs && console.log(`RESPONSE ERROR ${type.toUpperCase()}: `, path, error);
                    }
                    return reject(new Error('SYSTEMERROR - BaseRepository'));
                });
        });
    }
}

export const request = ({type, path, data = null}) => {
    return new Promise((resolve, reject) => {
        requestApi[type.toLowerCase()](path, data)
            .then(response => {
                if(response.ok) {
                    console.log(`${type.toUpperCase()}: `, path, response.data);
                    resolve(response.data);
                }
                else {
                    console.log(`RESPONSE ERROR ${type.toUpperCase()}: `, path, response);
                    reject(response);
                }
            }).catch((e)=> {
                console.log(`RESPONSE ERROR ${type.toUpperCase()}: `, path, response);
                return reject(new Error('SYSTEMERROR - BaseRepository'));
            });
    });
};
