export const FIND_PROCESS_STATEMENTS_REQUEST = 'FIND_PROCESS_STATEMENTS_REQUEST';

export const findProcessStatementsRequest = ({
     filter = [],
     sorting = { field: '`Result`.`eval_year`', order: 'ASC' },
     pagination = { page: 1, take: 50 },
 }) => ({
    type: FIND_PROCESS_STATEMENTS_REQUEST,
    payload: { filter, sorting, pagination },
    meta: {
        sorting,
        pagination,
    }
});
