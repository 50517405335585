import {
    FIND_MAIL_TEMPLATE_REQUEST,
    FIND_MAIL_TEMPLATE_SUCCESS,
    FIND_MAIL_TEMPLATE_FAILURE,

    FIND_MAIL_TEMPLATES_REQUEST,
    FIND_MAIL_TEMPLATES_SUCCESS,
    FIND_MAIL_TEMPLATES_FAILURE,

    UPDATE_MAIL_TEMPLATE_REQUEST,
    UPDATE_MAIL_TEMPLATE_SUCCESS,
    UPDATE_MAIL_TEMPLATE_FAILURE,

    LOGOUT_USER,
    DISABLE_USER_SUCCESS,
    AUTHENTICATE_FAILURE,

} from 'Redux/Action';
import MailTemplateState from 'Redux/Store/MailTemplate/MailTemplateState';
import { removeOpenRequest, removeFailedRequest, addSingleObjectToReducer, getSingleObject } from 'ClientService/ReducerUtil/ReducerUtil';

const initialState = MailTemplateState.getInitialState();

export const mailTemplateReducer = (state = initialState, action) => {

    switch (action.type) {

    case AUTHENTICATE_FAILURE :
    case LOGOUT_USER :
    case DISABLE_USER_SUCCESS :
        return {
            ...initialState,
        };

    case FIND_MAIL_TEMPLATE_REQUEST:
    case FIND_MAIL_TEMPLATES_REQUEST:
    case UPDATE_MAIL_TEMPLATE_REQUEST:
        return {
            ...state,
            openRequests: [...state.openRequests, action.type],
            failedRequests: removeFailedRequest(action, state),
            updatedAt: Date.now(),
        };

    case FIND_MAIL_TEMPLATES_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            mailTemplates: {
                entries: action.payload.mailTemplates,
                maxCount: (action.meta) ? action.meta.response.count : state.mailTemplates.maxCount,
            },
            updatedAt: Date.now(),
        };

    case UPDATE_MAIL_TEMPLATE_SUCCESS:
    case FIND_MAIL_TEMPLATE_SUCCESS:
        const mailTemplate = getSingleObject(action.payload.mailTemplate);

        return {
            ...state,
            lastInteractiveId: mailTemplate.id,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
            mailTemplates: {
                ...state.mailTemplates,
                entries: addSingleObjectToReducer(state.mailTemplates.entries, action.payload.mailTemplate)
            },
        };

    case FIND_MAIL_TEMPLATE_FAILURE:
    case FIND_MAIL_TEMPLATES_FAILURE:
    case UPDATE_MAIL_TEMPLATE_FAILURE:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            failedRequests: [...state.failedRequests, action.type],
            updatedAt: Date.now(),
        };



    default:
        return state;
    }
};
