import {
    CREATE_POOL_REQUEST_REQUEST,
    CREATE_POOL_REQUEST_SUCCESS,
    CREATE_POOL_REQUEST_FAILURE,

    UPDATE_POOL_REQUEST_REQUEST,
    UPDATE_POOL_REQUEST_SUCCESS,
    UPDATE_POOL_REQUEST_FAILURE,

    FIND_POOL_REQUEST_REQUEST,
    FIND_POOL_REQUEST_SUCCESS,
    FIND_POOL_REQUEST_FAILURE,

    FIND_POOL_REQUESTS_REQUEST,
    FIND_POOL_REQUESTS_SUCCESS,
    FIND_POOL_REQUESTS_FAILURE,

    SEND_POOL_REQUEST_REQUEST,
    SEND_POOL_REQUEST_SUCCESS,
    SEND_POOL_REQUEST_FAILURE,

    SEND_OPEN_POOL_REQUESTS_REQUEST,
    SEND_OPEN_POOL_REQUESTS_SUCCESS,
    SEND_OPEN_POOL_REQUESTS_FAILURE,

    DELETE_POOL_REQUESTS_REQUEST,
    DELETE_POOL_REQUESTS_SUCCESS,
    DELETE_POOL_REQUESTS_FAILURE,

    CLEAR_POOL_REQUESTS,

    LOGOUT_USER,
    DISABLE_USER_SUCCESS,
    AUTHENTICATE_FAILURE,

} from 'Redux/Action';
import PoolRequestState from 'Redux/Store/PoolRequest/PoolRequestState';
import { removeOpenRequest, removeFailedRequest, addSingleObjectToReducer, getSingleObject } from 'ClientService/ReducerUtil/ReducerUtil';

const initialState = PoolRequestState.getInitialState();

export const poolRequestReducer = (state = initialState, action) => {

    switch (action.type) {

    case AUTHENTICATE_FAILURE :
    case LOGOUT_USER :
    case DISABLE_USER_SUCCESS :
        return {
            ...initialState,
        };

    case CREATE_POOL_REQUEST_REQUEST:
    case UPDATE_POOL_REQUEST_REQUEST:
    case FIND_POOL_REQUEST_REQUEST:
    case SEND_POOL_REQUEST_REQUEST:
    case SEND_OPEN_POOL_REQUESTS_REQUEST:
    case FIND_POOL_REQUESTS_REQUEST:
    case DELETE_POOL_REQUESTS_REQUEST:
        return {
            ...state,
            lastInteractiveId: null,
            openRequests: [...state.openRequests, action.type],
            failedRequests: removeFailedRequest(action, state),
            updatedAt: Date.now(),
        };

    case CREATE_POOL_REQUEST_SUCCESS:
    case UPDATE_POOL_REQUEST_SUCCESS:
    case FIND_POOL_REQUEST_SUCCESS:
        const poolRequest = getSingleObject(action.payload.poolRequest);

        return {
            ...state,
            lastInteractiveId: poolRequest.id,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
            poolRequests: {
                ...state.poolRequests,
                entries: addSingleObjectToReducer(state.poolRequests.entries, action.payload.poolRequest)
            },
        };

    case SEND_POOL_REQUEST_SUCCESS:
    case SEND_OPEN_POOL_REQUESTS_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
        };

    case FIND_POOL_REQUESTS_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            poolRequests: {
                entries: action.payload.poolRequests,
                maxCount: (action.meta) ? action.meta.response.count : state.poolRequests.maxCount,
            },
            updatedAt: Date.now(),
        };

    case CLEAR_POOL_REQUESTS:
        return {
            ...state,
            poolRequests: {
                entries: {},
                maxCount: 0,
            },
            updatedAt: Date.now(),
        };

    case DELETE_POOL_REQUESTS_SUCCESS: {
        const entries = {...state.poolRequests.entries};
        action.payload.ids.filter(id => !!entries[id]).forEach(id => delete entries[id]);

        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            poolRequests: {
                entries,
                maxCount: state.poolRequests.maxCount - action.payload.ids.length,
            },
            updatedAt   : Date.now(),
        };
    }

    case FIND_POOL_REQUESTS_FAILURE:
    case DELETE_POOL_REQUESTS_FAILURE:
    case FIND_POOL_REQUEST_FAILURE:
    case SEND_POOL_REQUEST_FAILURE:
    case SEND_OPEN_POOL_REQUESTS_FAILURE:
    case UPDATE_POOL_REQUEST_FAILURE:
    case CREATE_POOL_REQUEST_FAILURE:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            failedRequests: [...state.failedRequests, action.type],
            updatedAt: Date.now(),
        };



    default:
        return state;
    }
};
