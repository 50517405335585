import { FIND_EMPLOYEE_ROLE_REQUEST, findEmployeeRoleFailure, findEmployeeRoleSuccess} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from, EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function findEmployeeRoleEpic(action$, store$, { EmployeeRoleRepository }) {
    return action$.pipe(
        ofType(FIND_EMPLOYEE_ROLE_REQUEST),
        mergeMap( action =>{
            const { id } = action.payload;

            if(!id.trim()) {
                return EMPTY;
            }

            return from(
                EmployeeRoleRepository.findById( id )
                    .then(employeeRole => findEmployeeRoleSuccess(employeeRole))
                    .catch(error => findEmployeeRoleFailure(error)),
            );
        }),
    );
}
