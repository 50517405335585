export const convertFileSize = (bSize) => {
    //1024 x 1024 x 1024 x 1024
    // KB     MB     GB     TB
    switch(true) {
        case (bSize > 1024 * 1024 * 1024 * 1024) : return (bSize / (1024 * 1024 * 1024 * 1024)).toFixed(1) + ' TB';
        case (bSize > 1024 * 1024 * 1024 )       : return (bSize / (1024 * 1024 * 1024) ).toFixed(1) + ' GB';
        case (bSize > 1024 * 1024  )             : return (bSize / (1024 * 1024)  ).toFixed(1) + ' MB';
        case (bSize > 1024 )                     : return (bSize / (1024)  ).toFixed(1) + ' KB';
        default : return bSize + ' B';
    }

};