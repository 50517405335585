import {
    DELETE_PROCESS_STATEMENT_REQUEST,
    FIND_PROCESS_STATEMENT_REQUEST,
    FIND_PROCESS_STATEMENTS_REQUEST,
    FIND_OPEN_PROCESS_STATEMENTS_REQUEST,
    UPDATE_STATEMENT_PROCESS_REQUEST,
    DISMISS_ANSWER_FROM_PROCESS_STATEMENT_REQUEST,
    FIND_PROCESS_STATEMENT_RESULTS_REQUEST,
    DOWNLOAD_PROCESS_STATEMENT_RTF_REQUEST,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isProcessStatementLoading = (state) =>
    ArrayContains(state.processStatement.openRequests, [
        UPDATE_STATEMENT_PROCESS_REQUEST,
        DELETE_PROCESS_STATEMENT_REQUEST,
        FIND_PROCESS_STATEMENT_REQUEST,
        FIND_PROCESS_STATEMENTS_REQUEST,
        FIND_OPEN_PROCESS_STATEMENTS_REQUEST,
        DISMISS_ANSWER_FROM_PROCESS_STATEMENT_REQUEST,
        FIND_PROCESS_STATEMENT_RESULTS_REQUEST,
        DOWNLOAD_PROCESS_STATEMENT_RTF_REQUEST,
    ]);
