export const FIND_EDOK_FORMS_REQUEST = 'FIND_EDOK_FORMS_REQUEST';

export const findEdokFormsRequest = ({
     filter = [],
     sorting = { field: 'year', order: 'ASC' },
     pagination = { page: 1, take: 50 },
 }) => ({
    type: FIND_EDOK_FORMS_REQUEST,
    payload: { filter, sorting, pagination },
    meta: {
        sorting,
        pagination,
    }
});
