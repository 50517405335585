import React from 'react';
import Style from "./PublicApp.style";
import { isGlobalStateEqual } from "Redux/Query";
import { connect } from "react-redux";

class PublicApp extends React.Component {

    render () {
        const { children, dispatch, ...props } = this.props;
        return (
            <Style { ...props }>
                { children }
            </Style>

        );
    }
}

const options = {
    areStatesEqual: (nextStore, prevStore) => {
        return (
            isGlobalStateEqual(nextStore, prevStore)
        );
    }
};
export default connect(undefined, undefined,  undefined, options)(PublicApp);
