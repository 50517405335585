export default class EmployeeRole {

    constructor( data  ) {

        this.id   = data.id;
        this.name = data.name;
        this.key  = data.key;


    }

}
