import {
    CREATE_NEW_CHAT_CONVERSATION_REQUEST,
    CREATE_NEW_CHAT_CONVERSATION_SUCCESS,
    CREATE_NEW_CHAT_CONVERSATION_FAILURE,

    FIND_CHAT_CONVERSATIONS_REQUEST,
    FIND_CHAT_CONVERSATIONS_SUCCESS,
    FIND_CHAT_CONVERSATIONS_FAILURE,

    FIND_CHAT_CONVERSATION_REQUEST,
    FIND_CHAT_CONVERSATION_SUCCESS,
    FIND_CHAT_CONVERSATION_FAILURE,

    ADD_NEW_CHAT_MESSAGE_REQUEST,
    ADD_NEW_CHAT_MESSAGE_SUCCESS,
    ADD_NEW_CHAT_MESSAGE_FAILURE,

    COUNT_OPEN_CHAT_CONVERSATIONS_REQUEST,
    COUNT_OPEN_CHAT_CONVERSATIONS_SUCCESS,
    COUNT_OPEN_CHAT_CONVERSATIONS_FAILURE,

    LOGOUT_USER,
    DISABLE_USER_SUCCESS,
    AUTHENTICATE_FAILURE,

} from 'Redux/Action';
import ChatState from 'Redux/Store/Chat/ChatState';
import {
    removeOpenRequest,
    removeFailedRequest,
    addSingleObjectToReducer,
    getSingleObject
} from 'ClientService/ReducerUtil/ReducerUtil';

const initialState = ChatState.getInitialState();

export const chatReducer = (state = initialState, action) => {

    switch (action.type) {

    case AUTHENTICATE_FAILURE :
    case LOGOUT_USER :
    case DISABLE_USER_SUCCESS :
        return {
            ...initialState,
        };

    case COUNT_OPEN_CHAT_CONVERSATIONS_REQUEST:
    case CREATE_NEW_CHAT_CONVERSATION_REQUEST:
    case ADD_NEW_CHAT_MESSAGE_REQUEST:
        return {
            ...state,
            lastInteractiveGuid: null,
            openRequests: [...state.openRequests, action.type],
            failedRequests: removeFailedRequest(action, state),
            updatedAt: Date.now(),
        };

    case FIND_CHAT_CONVERSATIONS_REQUEST:
    case FIND_CHAT_CONVERSATION_REQUEST:
        return {
            ...state,
            visibleMessages: [],
            lastInteractiveGuid: null,
            openRequests: [...state.openRequests, action.type],
            failedRequests: removeFailedRequest(action, state),
            updatedAt: Date.now(),
        };

    case CREATE_NEW_CHAT_CONVERSATION_SUCCESS:
    case ADD_NEW_CHAT_MESSAGE_SUCCESS: {
        const { messages, ...conversation } = getSingleObject(action.payload.conversation);

        return {
            ...state,
            lastInteractiveGuid: conversation.id,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
            conversations: {
                ...state.conversations,
                entries: addSingleObjectToReducer(state.conversations.entries, action.payload.conversation)
            },
            visibleMessages: messages,
        };

    }

    case FIND_CHAT_CONVERSATION_SUCCESS: {
        const { messages, ...conversation } = getSingleObject(action.payload.conversation);

        return {
            ...state,
            lastInteractiveGuid: conversation.id,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
            openChatConversationsCount: action.payload.count,
            conversations: {
                ...state.conversations,
                entries: addSingleObjectToReducer(state.conversations.entries, action.payload.conversation)
            },
            visibleMessages: messages,
        };

    }

    case FIND_CHAT_CONVERSATIONS_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            conversations: {
                entries: action.payload.conversations,
                maxCount: (action.meta) ? action.meta.response.count : state.conversations.maxCount,
            },
            updatedAt: Date.now(),
        };

    case COUNT_OPEN_CHAT_CONVERSATIONS_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            openChatConversationsCount: action.payload.count,
            updatedAt: Date.now(),
        };

    case CREATE_NEW_CHAT_CONVERSATION_FAILURE:
    case COUNT_OPEN_CHAT_CONVERSATIONS_FAILURE:
    case FIND_CHAT_CONVERSATIONS_FAILURE:
    case FIND_CHAT_CONVERSATION_FAILURE:
    case ADD_NEW_CHAT_MESSAGE_FAILURE:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            failedRequests: [...state.failedRequests, action.type],
            updatedAt: Date.now(),
        };



    default:
        return state;
    }
};
