export const FIND_POOL_REQUESTS_SUCCESS = 'FIND_POOL_REQUESTS_SUCCESS';

export const findPoolRequestsSuccess = ({ poolRequests, request, response }) => ({
    type: FIND_POOL_REQUESTS_SUCCESS,
    payload: { poolRequests },
    meta: {
        request,
        response,
    }
});
