export default class PoolRequest {

    constructor( data  ) {

        this.id               = data.id;
        this.createdAt        = data.createdAt;
        this.request          = data.request;
        this.title            = data.title;
        this.type             = data.type;
        this.shortKey         = data.shortKey;
        this.isLocked         = data.isLocked;
        this.archived         = data.archived;
        this.entries          = data.poolRequestEntries ?
            data.poolRequestEntries.sort((a, b) => (a.sorting > b.sorting) ? 1 : (a.sorting < b.sorting) ? -1 : 0) :
            [];

    }

}
