import {
    SUBMIT_PROCESS_STATEMENT_REQUEST,
    submitProcessStatementSuccess,
    submitProcessStatementFailure,
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { message } from "antd";

export function submitProcessStatementEpic(action$, store$, { ProcessStatementRepository }) {
    return action$.pipe(
        ofType(SUBMIT_PROCESS_STATEMENT_REQUEST),
        mergeMap( action => from(ProcessStatementRepository.submit(action.payload.id, { submitInformation: action.payload.submitInformation } ))
            .pipe(
                mergeMap( statement => {
                    message.success('Stellungnahme wurde erfolgreich bestätigt')
                    return from ([
                        submitProcessStatementSuccess(statement),
                    ]);
                }),
                catchError( error => {
                    return from([
                        submitProcessStatementFailure(error),
                    ])
                })
            )
        ),
    );
}
