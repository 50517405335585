import { AUTHENTICATE_SUCCESS, getSettingsRequest, showNotification } from 'Redux/Action';
import { ofType }                                                     from 'redux-observable';
import {mergeMap, withLatestFrom} from 'rxjs/operators';
import { from } from 'rxjs';
import { getSiteName } from "Redux/Query";

export function authenticateSuccessEpic(action$, store$ ) {
    return action$.pipe(
        ofType(AUTHENTICATE_SUCCESS),
        withLatestFrom( store$ ),
        mergeMap(([action, store]) => {
            const siteName = getSiteName(store);
            return from( [
                getSettingsRequest(),
                // showNotification(`Willkommen im ${ siteName }!` )
            ]);
        })
    );
}
