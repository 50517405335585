import { combineEpics } from 'redux-observable';
import { showErrorNotificationEpic } from './ShowErrorNotification/ShowErrorNotificationEpic';
import { showWarningNotificationEpic } from './ShowWarningNotification/ShowWarningNotificationEpic';
import { showNotificationEpic } from './ShowNotification/ShowNotificationEpic';


export default combineEpics(
    showErrorNotificationEpic,
    showWarningNotificationEpic,
    showNotificationEpic,
);
