import {
    UPLOAD_RESULTS_REQUEST,
    uploadResultsSuccess,
    uploadResultsFailure,
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { message } from "antd";

export function uploadResultsEpic(action$, store$, { ResultRepository }) {
    return action$.pipe(
        ofType(UPLOAD_RESULTS_REQUEST),
        mergeMap( action => from(ResultRepository.uploadResults( action.payload.formData ))
            .pipe(
                mergeMap(() => {
                    message.success('Die Ergebnisse wurden erfolgreich hochgeladen');
                    return from([
                        uploadResultsSuccess(),
                    ]);
                }),
                catchError( error => from([ uploadResultsFailure(error) ]))
            )
        ),
    );
}
