import { combineEpics }       from 'redux-observable';
import GlobalEpics            from './Global';
import UserEpics              from './User';
import FileEpics              from './File';
import EmployeeEpics          from './Employee';
import EmployeeRoleEpics      from './EmployeeRole';
import CareProviderEpics      from './CareProvider';
import NotificationEpics      from './Notification';
import DocumentEpics          from './Document';
import ModuleEpics            from './Module';
import ResultEpics            from './Result';
import PoolRequestEpics       from './PoolRequest';
import ProcessStatementEpics  from './ProcessStatement';
import PseudonymEpics         from './Pseudonym';
import TargetActualEpics      from './TargetActual';
import StatementTemplateEpics from './StatementTemplate';
import ResultRatingEpics      from './ResultRating';
import ReportEpics            from './Report';
import EdokEpics              from './Edok';
import ChatEpics              from './Chat';
import MailTemplateEpics      from './MailTemplate';


export default combineEpics(
    GlobalEpics,
    UserEpics,
    FileEpics,
    EmployeeEpics,
    EmployeeRoleEpics,
    CareProviderEpics,
    NotificationEpics,
    DocumentEpics,
    ModuleEpics,
    ResultEpics,
    PoolRequestEpics,
    ProcessStatementEpics,
    PseudonymEpics,
    TargetActualEpics,
    StatementTemplateEpics,
    ResultRatingEpics,
    ReportEpics,
    EdokEpics,
    ChatEpics,
    MailTemplateEpics,
);
