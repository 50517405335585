export const FIND_RESULT_RATINGS_SUCCESS = 'FIND_RESULT_RATINGS_SUCCESS';

export const findResultRatingsSuccess = ({ resultRatings, request, response }) => ({
    type: FIND_RESULT_RATINGS_SUCCESS,
    payload: { resultRatings },
    meta: {
        request,
        response,
    }
});
