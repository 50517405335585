import {
    FIND_MODULE_REQUEST,
    FIND_MODULES_REQUEST,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isModuleLoading = (state) =>
    ArrayContains(state.module.openRequests, [
        FIND_MODULE_REQUEST,
        FIND_MODULES_REQUEST,
    ]);