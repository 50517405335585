import {
    FIND_DOCUMENT_REQUEST,
    FIND_DOCUMENTS_REQUEST,
    DOWNLOAD_DOCUMENT_REQUEST,
    UPLOAD_DOCUMENT_REQUEST,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isDocumentLoading = (state) =>
    ArrayContains(state.document.openRequests, [
        FIND_DOCUMENT_REQUEST,
        FIND_DOCUMENTS_REQUEST,
        DOWNLOAD_DOCUMENT_REQUEST,
        UPLOAD_DOCUMENT_REQUEST,
    ]);