import { UPDATE_EMPLOYEE_REQUEST, updateEmployeeFailure, updateEmployeeSuccess } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function updateEmployeeRequestEpic(action$, store$, { EmployeeRepository }) {
    return action$.pipe(
        ofType(UPDATE_EMPLOYEE_REQUEST),
        mergeMap(action => from(
            EmployeeRepository.update( action.payload.id, action.payload.updateData )
                .then(( employee ) => updateEmployeeSuccess({ employee }))
                .catch(error => updateEmployeeFailure(error)),
        )),
    );
}
