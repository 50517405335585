import { FIND_FILES_REQUEST, findFilesFailure, findFilesSuccess} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function findFilesEpic(action$, store$, { FileRepository }) {
    return action$.pipe(
        ofType( FIND_FILES_REQUEST ),
        mergeMap(action => from(
            FileRepository.findAll(action.payload)
                .then(({ files, meta }) => findFilesSuccess({ files, response: meta, request: action}))
                .catch(error => findFilesFailure({ error, request: action })),
        )),
    );
}
