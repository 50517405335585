import {
    DOWNLOAD_CHAT_CONVERSATION_REQUEST,
    downloadChatConversationSuccess,
    downloadChatConversationFailure
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from, EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function downloadChatConversationEpic(action$, store$, { ChatRepository }) {
    return action$.pipe(
        ofType(DOWNLOAD_CHAT_CONVERSATION_REQUEST),
        mergeMap( action =>{
            const { id } = action.payload;

            if(!`${id}`.trim()) {
                return EMPTY;
            }

            return from(
                ChatRepository.downloadChatConversation( id )
                    .then(downloadChatConversationSuccess)
                    .catch(downloadChatConversationFailure),
            );
        }),
    );
}
