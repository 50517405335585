export const FIND_EMPLOYEE_ROLES_SUCCESS = 'FIND_EMPLOYEE_ROLES_SUCCESS';

export const findEmployeeRolesSuccess = ({ employeeRoles, request, response }) => ({
    type: FIND_EMPLOYEE_ROLES_SUCCESS,
    payload: { employeeRoles },
    meta: {
        request,
        response,
    }
});
