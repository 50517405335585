import { DOWNLOAD_FILES_AS_ZIP_REQUEST, downloadFilesAsZipFailure, downloadFilesAsZipSuccess} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from, EMPTY }              from 'rxjs';
import { mergeMap, withLatestFrom } from 'rxjs/operators';

export function downloadFilesAsZipEpic(action$, store$, { FileRepository }) {
    return action$.pipe(
        ofType(DOWNLOAD_FILES_AS_ZIP_REQUEST),
        withLatestFrom(store$),
        mergeMap( ([action, store]) => {
            const { zipFiles } = store.file;

            if(!zipFiles.length) {
                return EMPTY;
            }

            return from(
                FileRepository.downloadFilesAsZip( zipFiles )
                    .then(file => downloadFilesAsZipSuccess(file))
                    .catch(error => downloadFilesAsZipFailure(error)),
            );
        }),
    );
}
