import {
    UPDATE_STATEMENT_TEMPLATE_REQUEST,
    updateStatementTemplateSuccess,
    updateStatementTemplateFailure,
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { message } from "antd";

export function updateStatementTemplateEpic(action$, store$, { StatementTemplateRepository }) {
    return action$.pipe(
        ofType(UPDATE_STATEMENT_TEMPLATE_REQUEST),
        mergeMap( action => from(StatementTemplateRepository.update(action.payload.id, action.payload.updateData ))
            .pipe(
                mergeMap( statementTemplate => {
                    message.success('Das Template wurde erfolgreich gespeichert.');
                    return from ([
                        updateStatementTemplateSuccess({ statementTemplate }),
                    ]);
                }),
                catchError( error => {
                    return from([
                        updateStatementTemplateFailure(error),
                    ])
                })
            )
        ),
    );
}
