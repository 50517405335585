import { FIND_POOL_REQUEST_REQUEST, findPoolRequestSuccess, findPoolRequestFailure} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from, EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function findPoolRequestEpic(action$, store$, { PoolRequestRepository }) {
    return action$.pipe(
        ofType(FIND_POOL_REQUEST_REQUEST),
        mergeMap( action =>{
            const { id } = action.payload;

            if(!id.trim()) {
                return EMPTY;
            }

            return from(
                PoolRequestRepository.findById( id )
                    .then(employee => findPoolRequestSuccess(employee))
                    .catch(error => findPoolRequestFailure(error)),
            );
        }),
    );
}
