import { FIND_DOCUMENT_REQUEST, findDocumentSuccess, findDocumentFailure} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from, EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function findDocumentEpic(action$, store$, { DocumentRepository }) {
    return action$.pipe(
        ofType(FIND_DOCUMENT_REQUEST),
        mergeMap( action =>{
            const { id } = action.payload;

            if(!id.trim()) {
                return EMPTY;
            }

            return from(
                DocumentRepository.findById( id )
                    .then( findDocumentSuccess )
                    .catch( findDocumentFailure ),
            );
        }),
    );
}
