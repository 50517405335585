export const FIND_MODULES_BY_CARE_PROVIDER_FAILURE = 'FIND_MODULES_BY_CARE_PROVIDER_FAILURE';

export const findModulesByCareProviderFailure = ({ error, request }) => ({
    type: FIND_MODULES_BY_CARE_PROVIDER_FAILURE,
    payload: error,
    error: true,
    meta: {
        request
    }
});
