import {
    CREATE_STATEMENT_TEMPLATE_PLACEHOLDER_REQUEST,
    createStatementTemplatePlaceholderSuccess,
    createStatementTemplatePlaceholderFailure
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { message } from "antd";

export function createStatementTemplatePlaceholderEpic(action$, store$, { StatementTemplateRepository }) {
    return action$.pipe(
        ofType(CREATE_STATEMENT_TEMPLATE_PLACEHOLDER_REQUEST),
        mergeMap( action => from(StatementTemplateRepository.create(action.payload.updateData ))
            .pipe(
                mergeMap( statementTemplate => {
                    message.success('Der Allgemeine Textbaustein wurde erfolgreich gespeichert.');
                    return from ([
                        createStatementTemplatePlaceholderSuccess(statementTemplate),
                    ]);
                }),
                catchError( error => {
                    return from([
                        createStatementTemplatePlaceholderFailure(error),
                    ])
                })
            )
        ),
    );
}
