import { HAS_ANSWER_ACCESS_GRANTS } from 'AppConfig/Config';
import { getAccessGrant }           from 'Redux/Query/Employee';
import { userContainsRole }         from 'Redux/Query/User/UserContainsRole/UserContainsRole';

export const getUserAccessGrants = (state) => {
    if(userContainsRole(state)(HAS_ANSWER_ACCESS_GRANTS)) {
        return getAccessGrant(state, 'ANSWER_PROCESS_STATEMENT');
    }
    if(userContainsRole(state)(HAS_ANSWER_ACCESS_GRANTS)) {
        return getAccessGrant(state, 'CREATE_PROCESS_STATEMENT');
    }
    return 'SEND';
}
