import { FIND_RESULT_RATING_REQUEST, findResultRatingSuccess, findResultRatingFailure} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from, EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function findResultRatingEpic(action$, store$, { ResultRatingRepository }) {
    return action$.pipe(
        ofType( FIND_RESULT_RATING_REQUEST ),
        mergeMap( action =>{
            const { id } = action.payload;

            if(!id.trim()) {
                return EMPTY;
            }

            return from(
                ResultRatingRepository.findById( id )
                    .then(findResultRatingSuccess)
                    .catch(findResultRatingFailure),
            );
        }),
    );
}
