export const GET_LAST_DEADLINE_DATES_SUCCESS = 'GET_LAST_DEADLINE_DATES_SUCCESS';

export const getLastDeadlineDatesSuccess = ({ toReminderDate, toAnswerDate, toSubsequentDeliveryDate }) => ({
    type: GET_LAST_DEADLINE_DATES_SUCCESS,
    payload: {
        toReminderDate,
        toAnswerDate,
        toSubsequentDeliveryDate,
    },
});
