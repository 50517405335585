import { FIND_CARE_PROVIDER_REQUEST, findCareProviderFailure, findCareProviderSuccess} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from, EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function findCareProviderEpic(action$, store$, { CareProviderRepository }) {
    return action$.pipe(
        ofType(FIND_CARE_PROVIDER_REQUEST),
        mergeMap( action =>{
            const { id } = action.payload;

            if(!id.trim()) {
                return EMPTY;
            }

            return from(
                CareProviderRepository.findById( id )
                    .then(employee => findCareProviderSuccess(employee))
                    .catch(error => findCareProviderFailure(error)),
            );
        }),
    );
}
