import styled from 'styled-components';
import { black, white, lightGrey } from 'Public/css/colors';

export default styled.div `
    
    color: ${ black };
    
    .anticon {
        background-color: ${ lightGrey };
        color: ${ white };
        border-radius: 100%;
        padding: 4px;
        font-size: 20px;
        display: inline-block;
        margin-right: 10px;
        vertical-align: middle;
    }
    
    .userCareProviders {
        position: absolute;
        top: 0;
        right: 85px;
        padding-top: 24px;
        display: inline-block;
        white-space: nowrap;
        font-size: 10px;
        font-style: italic;
        
        span {
          padding: 0 3px 0 0;
          
          &:after {
            content: ' | ';
          }
          
          &:first-child {
              padding-left: 3px;
          }

          &:last-child:after {
            content: '';
          }
          
        }
    }
  
`;