export const RELOAD_SERVER_SETTINGS_SUCCESS = 'RELOAD_SERVER_SETTINGS_SUCCESS';

export const reloadServerSettingsSuccess = ({ settings, request, response }) => ({
    type: RELOAD_SERVER_SETTINGS_SUCCESS,
    payload: { settings },
    meta: {
        request,
        response,
    }
});
