import React from "react";
import {
    useLocation,
    useNavigate,
    useParams,

} from "react-router-dom";

const getRoutePath = (location, params) => {
    const { pathname } = location;

    if (!Object.keys(params).length) {
        return pathname; // we don't need to replace anything
    }

    let path = pathname;
    Object.entries(params).forEach(([paramName, paramValue]) => {
        if (paramValue) {
            path = path.replace(paramValue, `:${paramName}`);
        }
    });
    return path;
};

export const withRouter = (Component) => {
    const ComponentWithRouterProp = (props) => {
        let location = useLocation();
        let navigate = useNavigate();
        let params   = useParams();

        return (
            <Component
                {...props}
                router={{ location, navigate, params }}
                history={ {
                    replace: (to) => { navigate(to, { replace: true }); },
                    goBack: () => { navigate(-1); },
                    push: (to) => { navigate(to); }
                } }
                location={ location }
                match={{
                    url: location.pathname,
                    params,
                    path: getRoutePath(location, params),
                }}
            />
        );
    }

    return ComponentWithRouterProp;
}