export const getFileIcon = (mimetype) => {
    let icon = 'file';
    if( !mimetype ) {
        return icon;
    }
    switch(true) {
        case mimetype === 'application/x-7z-compressed' :
        case mimetype === 'text/zip' :        icon = 'file-zip'; break;
        case mimetype === 'application/pdf' : icon = 'file-pdf'; break;
        case (!!mimetype.match(/image/i)) :   icon = 'file-image'; break;

        case (['application/pdf'].includes(mimetype)) : icon = 'file-pdf'; break;

        case ([
            'text/csv',
            'application/vnd.oasis.opendocument.database',
            'application/vnd.ms-excel',
            'application/vnd.ms-excel.addin.macroenabled.12',
            'application/vnd.ms-excel.sheet.binary.macroenabled.12',
            'application/vnd.ms-excel.template.macroenabled.12',
            'application/vnd.ms-excel.sheet.macroenabled.12',
        ].includes(mimetype)) :
            icon = 'file-excel'; break;

        case ([
            'application/vnd.oasis.opendocument.text',
        ].includes(mimetype) || !!mimetype.match(/text\//i)) :
            icon = 'file-text'; break;

        default : break;
    }

    return icon;

};