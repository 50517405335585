export const validateRule = (condition, values) => {
    let fn = condition;
    Object.keys(values).forEach(argumentKey => {
        let value      = values[argumentKey];
        const match    = `${value}`.match(/\d{2}\.\d{4}/i);
        if(!!match) {
            const split    = match[0].replace(/'/g, '').split('.');
            value = new Date(`${split[1]}-${split[0]}-01 00:00:00`).getTime();

        }
        else if(value !== null) {
            value = `'${value}'`
        }
        fn = fn.replace(new RegExp(`\\b${argumentKey}\\b`, 'gi'), value);
    });
    return Function(`"use strict";return (${fn})`)();
}
