const getInitialState = () => ({
    openRequests: [],
    failedRequests: [],
    updatedAt: 0,
    lastInteractiveGuid: null,
    conversations: {
        entries: {},
        maxCount: 0,
    },
    visibleMessages: [],
    openChatConversationsCount: 0,
});

export default {
    getInitialState
};
