import BaseRepository from '../Base/BaseRepository';
import ResultRating from 'Domain/Model/ResultRating/ResultRating';

class ResultRatingRepository extends BaseRepository {


}

export default new ResultRatingRepository({
    model: ResultRating,
    baseRoute: 'result-rating',
    singular: 'resultRating',
    plural: 'resultRatings',
});
