import BaseRepository from '../Base/BaseRepository';
import ChatConversation from 'Domain/Model/Chat/ChatConversation';

class ChatRepository extends BaseRepository {

    findChatConversationPartners() {
        return this.makeRequest({
            path: `/chat-conversation/message-partner`,
            type: 'GET',
            callback: (response) => response.conversationPartners,
        });
    }

    createNewChatMessage(conversationId, message) {
        return this.makeRequest({
            path: `/chat-conversation/${conversationId}/message`,
            type: 'PUT',
            data: { message },
            callback: this.initializeSingleModel,
        });
    }

    countOpenChatConversations() {
        return this.makeRequest({
            path: `/chat-conversation/count`,
            type: 'GET',
            callback: (response) => response.count,
        });
    }

    downloadChatConversation(id) {
        return this.makeRequest({
            path: `/chat-conversation/download/${id}`,
            type: 'GET',
            callback: (response) => {
                const { rtf, filename } = response;
                const file = new File([rtf], filename, {type: 'text/plain'});
                let url = window.URL.createObjectURL(file);
                let a = document.createElement('a');
                a.href = url;
                a.download = filename;
                a.click();
            }
        });
    }


    findById(id) {
        return this.makeRequest({
            path: `/${this.baseRoute}/${id}`,
            type: 'GET',
            callback: (response) => ({
                conversation: this.initializeSingleModel(response),
                count: response.count,
            })
        });

    }


}

export default new ChatRepository({
    model: ChatConversation,
    baseRoute: 'chat-conversation',
    singular: 'conversation',
    plural: 'conversations',
});
