import {
    FIND_SPEC_YEARS_REQUEST,
    FIND_EVAL_YEARS_REQUEST,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isFilterRequestLoading = (state) =>
    ArrayContains(state.result.openRequests, [
        FIND_EVAL_YEARS_REQUEST,
        FIND_SPEC_YEARS_REQUEST,
    ]);
