import { message }              from 'antd';
import {
    UPDATE_EDOK_FORM_REQUEST,
    updateEdokFormFailure,
    updateEdokFormSuccess
}                               from 'Redux/Action';
import { ofType }               from 'redux-observable';
import { from }                 from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

export function updateEdokFormEpic(action$, store$, { EdokRepository }) {
    return action$.pipe(
        ofType(UPDATE_EDOK_FORM_REQUEST),
        mergeMap(action => from(EdokRepository.update(action.payload.id, action.payload.updateData))
            .pipe(
                mergeMap(( form ) => {
                    message.success('Der Bogen wurde erfolgreich aktualisiert. Bitte beachten Sie, dass Sie ihn noch versenden müssen.');
                    return from([
                        updateEdokFormSuccess({ form }),
                    ]);
                }),
                catchError(error => {
                    return from([
                        updateEdokFormFailure(error),
                    ]);
                })
            )
        ),
    );
}
