export const FIND_PSEUDONYMS_SUCCESS = 'FIND_PSEUDONYMS_SUCCESS';

export const findPseudonymsSuccess = ({ pseudonyms, request, response }) => ({
    type: FIND_PSEUDONYMS_SUCCESS,
    payload: { pseudonyms },
    meta: {
        request,
        response,
    }
});
