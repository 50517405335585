const getInitialState = () => ({
    openRequests: [],
    failedRequests: [],
    updatedAt: 0,
    files: {
        entries: {},
        maxCount: 0,
    },
    lastInteractiveGuid: null,
    zipFiles: [],
    showZipMenu: false
});

export default {
    getInitialState
};
