export const FIND_REPORTS_SUCCESS = 'FIND_REPORTS_SUCCESS';

export const findReportsSuccess = ({ reports, request, response }) => ({
    type: FIND_REPORTS_SUCCESS,
    payload: { reports },
    meta: {
        request,
        response,
    }
});
