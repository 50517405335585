import {
    FIND_REF_RESULTS_REQUEST,
    findRefResultsSuccess,
    findRefResultsFailure
}                      from 'Redux/Action';
import { ofType }      from 'redux-observable';
import { from, EMPTY } from 'rxjs';
import { mergeMap }    from 'rxjs/operators';

export function findRefResultsEpic(action$, store$, { ResultRepository }) {
    return action$.pipe(
        ofType(FIND_REF_RESULTS_REQUEST),
        mergeMap(action => {
            const { id } = action.payload;

            if (!`${id}`.trim()) {
                return EMPTY;
            }

            return from(
                ResultRepository.findRefResultsById(id)
                    .then(({ results }) => findRefResultsSuccess({ results }))
                    .catch(error => findRefResultsFailure(error)),
            );
        }),
    );
}
