export const getDisabledSites = state => {
    const settings = state.persistStore.settings;
    const disabledSites = {};
    if(!settings.ALLOW_FILE_UPLOAD) {
        disabledSites.dashboard = true;
        disabledSites.detailDownloadFile = true;
    }
    if(!settings.ALLOW_PROCESS_STATEMENT) {
        disabledSites.processStatementOverview = true;
        disabledSites.resultProcessStatementDetail = true;
        disabledSites.poolRequestOverview = true;
        disabledSites.poolRequestDetail = true;

    }
    if(!settings.ALLOW_RESULTS) {
        disabledSites.resultsDetail = true;
        disabledSites.resultsOverview = true;
        disabledSites.sendPoolRequest = true;
    }

    if(!!settings.MENU_DISALLOW_DOCUMENTS) {
        disabledSites.documentUpload = true;
    }
    if(!!settings.MENU_DISALLOW_XML_UPLOAD) {
        disabledSites.dashboard = true;
        disabledSites.detailDownloadFile = true;
    }
    if(!!settings.MENU_DISALLOW_CARE_PROVIDER) {
        disabledSites.careProviderList = true;
    }

    if(!!settings.MENU_DISALLOW_PSEUDONYM) {
        disabledSites.pseudonymList = true;
    }
    if(!!settings.MENU_DISALLOW_PROCESS_STATEMENT) {
        disabledSites.processStatementOverview = true;
    }
    if(!!settings.MENU_DISALLOW_TARGET_ACTUAL) {
        disabledSites.targetActualList = true;
    }
    if(!!settings.MENU_DISALLOW_EMPLOYEE) {
        disabledSites.employeeList = true;
    }
    if(!!settings.MENU_DISALLOW_EDOK) {
        disabledSites.edokList = true;
        disabledSites.edokDetail = true;
    }
    if(!!settings.MENU_DISALLOW_CHAT) {
        disabledSites.chatOverview = true;
        disabledSites.chatDetail = true;
    }

    return disabledSites;
}