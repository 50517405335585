import BaseRepository from '../Base/BaseRepository';
import Pseudonym from 'Domain/Model/Pseudonym/Pseudonym';

class PseudonymRepository extends BaseRepository {

    findByModuleAndCareProvider({ moduleId, careProviderId }) {
            return this.makeRequest({
                path: `/${this.baseRoute}/care-provider/module`,
                data: { moduleId, careProviderId },
                type: 'GET',
                callback: this.initializeSingleModel
            });
        }

}

export default new PseudonymRepository({
    model: Pseudonym,
    baseRoute: 'pseudonym',
    singular: 'pseudonym',
    plural: 'pseudonyms',
});
