import { FIND_EVAL_YEARS_REQUEST, findEvalYearsSuccess, findEvalYearsFailure } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function findEvalYearsEpic(action$, store$, { ResultRepository }) {
    return action$.pipe(
        ofType( FIND_EVAL_YEARS_REQUEST ),
        mergeMap(action => from(
            ResultRepository.findAllEvalYears(action.payload)
                .then(( evalYears ) => findEvalYearsSuccess({ evalYears }))
                .catch(error => findEvalYearsFailure({ error, request: action })),
        )),
    );
}
