import React, { Component } from 'react';
import LayoutHeader from './Header.style';
import { AdditionalHeaderInformation } from 'Component/AdditionalHeaderInformation/AdditionalHeaderInformation';
import Clock from 'Component/Clock/Clock';
import LoggedInAs from 'Component/LoggedInAs/LoggedInAs';
import {getUserRole} from "Redux/Query";
import {connect} from "react-redux";
import {handbookUrl} from "AppConfig/Config";

class Header extends React.Component {
    render() {

        return (
            <LayoutHeader>
                <AdditionalHeaderInformation />
                <Clock />
                <LoggedInAs skeleton={ this.props.skeleton } />
                <a href={ handbookUrl[this.props.role] || handbookUrl.default } target={"_blank"}>Handbuch</a>
            </LayoutHeader>
        );
    }
}


const mapStateToProps = (state) => ({
    role: getUserRole(state),
});

export default connect(mapStateToProps)(Header);
