import {
    DELETE_EMPLOYEE_REQUEST,
    deleteEmployeeSuccess,
    deleteEmployeeFailure,
    showNotification
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';

export const deleteEmployeeEpic = (action$, store$, { EmployeeRepository }) => {
    return action$.pipe(
        ofType(DELETE_EMPLOYEE_REQUEST),
        mergeMap(action => from(EmployeeRepository.remove( action.payload.id ))
            .pipe(
                mergeMap(() => {
                    return from([
                        showNotification(`Der Mitarbeiter wurde erfolgreich gelöscht.`),
                        deleteEmployeeSuccess(action.payload.id),
                    ]);
                }),
                catchError( error => {
                    return from([
                        deleteEmployeeFailure(error),
                    ])
                })
            )
        ),
    );
}
