import { GET_CURRENT_EDOK_YEAR_REQUEST, getCurrentEdokYearFailure, getCurrentEdokYearSuccess} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function getCurrentEdokYearEpic(action$, store$, { EdokRepository }) {
    return action$.pipe(
        ofType(GET_CURRENT_EDOK_YEAR_REQUEST),
        mergeMap( () =>{
            return from(
                EdokRepository.getCurrentEdokYear()
                    .then(year => getCurrentEdokYearSuccess({ year }))
                    .catch(error => getCurrentEdokYearFailure(error)),
            );
        }),
    );
}
