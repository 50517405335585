import { FIND_PSEUDONYM_REQUEST, findPseudonymFailure, findPseudonymSuccess} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from, EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function findPseudonymEpic(action$, store$, { PseudonymRepository }) {
    return action$.pipe(
        ofType(FIND_PSEUDONYM_REQUEST),
        mergeMap( action =>{
            const { id } = action.payload;

            if(!id.trim()) {
                return EMPTY;
            }

            return from(
                PseudonymRepository.findById( id )
                    .then(pseudonym => findPseudonymSuccess(pseudonym))
                    .catch(error => findPseudonymFailure(error)),
            );
        }),
    );
}
