import {
    FIND_POOL_REQUEST_REQUEST,
    FIND_POOL_REQUESTS_REQUEST,
    SEND_POOL_REQUEST_REQUEST,
    SEND_OPEN_POOL_REQUESTS_REQUEST,
    CREATE_POOL_REQUEST_REQUEST,
    UPDATE_POOL_REQUEST_REQUEST,
    DELETE_POOL_REQUESTS_REQUEST,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isPoolRequestLoading = (state) =>
    ArrayContains(state.poolRequest.openRequests, [
        FIND_POOL_REQUEST_REQUEST,
        FIND_POOL_REQUESTS_REQUEST,
        SEND_POOL_REQUEST_REQUEST,
        SEND_OPEN_POOL_REQUESTS_REQUEST,
        CREATE_POOL_REQUEST_REQUEST,
        UPDATE_POOL_REQUEST_REQUEST,
        DELETE_POOL_REQUESTS_REQUEST,
    ]);