import {
    FIND_REPORT_REQUEST,
    FIND_REPORTS_REQUEST,
    DOWNLOAD_REPORT_REQUEST,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isReportLoading = (state) =>
    ArrayContains(state.report.openRequests, [
        FIND_REPORT_REQUEST,
        FIND_REPORTS_REQUEST,
        DOWNLOAD_REPORT_REQUEST,
    ]);