import { DELETE_POOL_REQUESTS_REQUEST, deletePoolRequestsFailure, deletePoolRequestsSuccess } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function deletePoolRequestsEpic(action$, store$, { PoolRequestRepository }) {
    return action$.pipe(
        ofType( DELETE_POOL_REQUESTS_REQUEST ),
        mergeMap(action => from(
            PoolRequestRepository.delete( action.payload.ids )
                .then(({ poolRequests, meta }) => deletePoolRequestsSuccess({ ids: action.payload.ids, response: meta, request: action}))
                .catch(error => deletePoolRequestsFailure({ error, request: action })),
        )),
    );
}
