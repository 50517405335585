import {
    FIND_DOCUMENTS_FAILURE,
    FIND_DOCUMENT_FAILURE,
    DOWNLOAD_DOCUMENT_FAILURE,
    UPLOAD_DOCUMENT_FAILURE,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isDocumentFailed = (state) => ArrayContains(state.document.failedRequests, [
    FIND_DOCUMENTS_FAILURE,
    FIND_DOCUMENT_FAILURE,
    DOWNLOAD_DOCUMENT_FAILURE,
    UPLOAD_DOCUMENT_FAILURE,
]);