import {

    FIND_MODULE_REQUEST,
    FIND_MODULE_SUCCESS,
    FIND_MODULE_FAILURE,

    FIND_MODULES_REQUEST,
    FIND_MODULES_SUCCESS,
    FIND_MODULES_FAILURE,

    FIND_MODULES_BY_CARE_PROVIDER_REQUEST,
    FIND_MODULES_BY_CARE_PROVIDER_SUCCESS,
    FIND_MODULES_BY_CARE_PROVIDER_FAILURE,

    LOGOUT_USER,
    AUTHENTICATE_FAILURE,

} from 'Redux/Action';
import ModuleState from 'Redux/Store/Module/ModuleState';
import { removeOpenRequest, removeFailedRequest, addSingleObjectToReducer, getSingleObject } from 'ClientService/ReducerUtil/ReducerUtil';

const initialState = ModuleState.getInitialState();

export const moduleReducer = (state = initialState, action) => {

    switch (action.type) {

    case AUTHENTICATE_FAILURE :
    case LOGOUT_USER :
        return {
            ...initialState,
        };

    case FIND_MODULES_BY_CARE_PROVIDER_REQUEST:
    case FIND_MODULE_REQUEST:
    case FIND_MODULES_REQUEST:
        return {
            ...state,
            lastInteractiveId: null,
            openRequests: [...state.openRequests, action.type],
            failedRequests: removeFailedRequest(action, state),
            updatedAt: Date.now(),
        };

    case FIND_MODULE_SUCCESS:
        const module = getSingleObject(action.payload.module);

        return {
            ...state,
            lastInteractiveId: module.id,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
            modules: {
                ...state.modules,
                entries: addSingleObjectToReducer(state.modules.entries, action.payload.module)
            },
        };

    case FIND_MODULES_SUCCESS:
    case FIND_MODULES_BY_CARE_PROVIDER_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            modules: {
                entries: action.payload.modules,
                maxCount: (action.meta) ? action.meta.response.count : state.modules.maxCount,
            },
            updatedAt: Date.now(),
        };

    case FIND_MODULE_FAILURE:
    case FIND_MODULES_BY_CARE_PROVIDER_FAILURE:
    case FIND_MODULES_FAILURE:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            failedRequests: [...state.failedRequests, action.type],
            updatedAt: Date.now(),
        };



    default:
        return state;
    }
};
