import ErrorMessages from "AppConfig/Messages/ErrorMessages";

export default function defaultErrorMessages(errorObject) {
    let message;

    switch(errorObject.problem) {
        case 'NETWORK_ERROR' : message = 'Netzwerkfehler: Bitte wenden Sie sich an Ihre EDV-Betreuung.'; break;
        case 'SERVER_ERROR' :
        case 'CLIENT_ERROR' :
            if(errorObject.data === 'Unauthorized') {
                return message || errorObject.data || false;
            }

            if(errorObject.data && errorObject.data.hasOwnProperty('errors') && errorObject.data.errors.length > 0){
                const error = errorObject.data.errors[0];
                if(error.hasOwnProperty('errorMessage')) {
                    message = error.errorMessage;
                }
            }
            break;
        default : break;
    }

    if(errorObject?.data && !message) {
        const { errorMessage, message: error_message, message_code } = errorObject.data;
        message = errorMessage || error_message || message_code;


    }

    let responseError = ErrorMessages[message] || message || null;
    if(responseError === null) {
        console.error(errorObject);
    }

    if(!!responseError && typeof responseError === 'function') {
        responseError = responseError(errorObject);
    }

    if(!responseError) {
        responseError = errorObject.data?.message_code;
    }

    return responseError;

}
