import { FIND_EDOK_DOCUMENTATIONS_REQUEST, findEdokDocumentationsSuccess, findEdokDocumentationsFailure } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function findEdokDocumentationsEpic(action$, store$, { EdokRepository }) {
    return action$.pipe(
        ofType( FIND_EDOK_DOCUMENTATIONS_REQUEST ),
        mergeMap(action => from(
            EdokRepository.findAllDocumentations(action.payload)
                .then((documentations) => findEdokDocumentationsSuccess({ documentations }))
                .catch(error => findEdokDocumentationsFailure({ error, request: action })),
        )),
    );
}
