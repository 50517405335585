import {
    FIND_RESULT_REQUEST,
    FIND_RESULTS_REQUEST,
    UPDATE_RATING_REQUEST,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isResultLoading = (state) =>
    ArrayContains(state.result.openRequests, [
        FIND_RESULT_REQUEST,
        FIND_RESULTS_REQUEST,
        UPDATE_RATING_REQUEST,
    ]);