import {
    FIND_PROCESS_STATEMENT_REQUEST,
    FIND_PROCESS_STATEMENT_SUCCESS,
    FIND_PROCESS_STATEMENT_FAILURE,

    DELETE_PROCESS_STATEMENT_REQUEST,
    DELETE_PROCESS_STATEMENT_SUCCESS,
    DELETE_PROCESS_STATEMENT_FAILURE,

    FIND_PROCESS_STATEMENTS_REQUEST,
    FIND_PROCESS_STATEMENTS_SUCCESS,
    FIND_PROCESS_STATEMENTS_FAILURE,

    FIND_OPEN_PROCESS_STATEMENT_REQUEST,
    FIND_OPEN_PROCESS_STATEMENT_SUCCESS,
    FIND_OPEN_PROCESS_STATEMENT_FAILURE,

    FIND_OPEN_PROCESS_STATEMENTS_REQUEST,
    FIND_OPEN_PROCESS_STATEMENTS_SUCCESS,
    FIND_OPEN_PROCESS_STATEMENTS_FAILURE,

    FIND_OPEN_ANSWER_PROCESS_STATEMENTS_REQUEST,
    FIND_OPEN_ANSWER_PROCESS_STATEMENTS_SUCCESS,
    FIND_OPEN_ANSWER_PROCESS_STATEMENTS_FAILURE,

    GET_ANSWER_INBOX_PROCESS_STATEMENT_COUNT_REQUEST,
    GET_ANSWER_INBOX_PROCESS_STATEMENT_COUNT_SUCCESS,
    GET_ANSWER_INBOX_PROCESS_STATEMENT_COUNT_FAILURE,

    UPDATE_STATEMENT_PROCESS_REQUEST,
    UPDATE_STATEMENT_PROCESS_SUCCESS,
    UPDATE_STATEMENT_PROCESS_FAILURE,

    UPDATE_PROCESS_STATEMENTS_REQUEST,
    UPDATE_PROCESS_STATEMENTS_SUCCESS,
    UPDATE_PROCESS_STATEMENTS_FAILURE,

    SUBMIT_PROCESS_STATEMENT_REQUEST,
    SUBMIT_PROCESS_STATEMENT_SUCCESS,
    SUBMIT_PROCESS_STATEMENT_FAILURE,

    UPDATE_OPEN_PROCESS_STATEMENT_REQUEST,
    UPDATE_OPEN_PROCESS_STATEMENT_SUCCESS,
    UPDATE_OPEN_PROCESS_STATEMENT_FAILURE,

    GET_POOL_REQUEST_INBOX_REQUEST,
    GET_POOL_REQUEST_INBOX_SUCCESS,
    GET_POOL_REQUEST_INBOX_FAILURE,

    DISMISS_ANSWER_FROM_PROCESS_STATEMENT_REQUEST,
    DISMISS_ANSWER_FROM_PROCESS_STATEMENT_SUCCESS,
    DISMISS_ANSWER_FROM_PROCESS_STATEMENT_FAILURE,

    FIND_PROCESS_STATEMENT_RESULTS_REQUEST,
    FIND_PROCESS_STATEMENT_RESULTS_SUCCESS,
    FIND_PROCESS_STATEMENT_RESULTS_FAILURE,

    GET_LAST_DEADLINE_DATES_REQUEST,
    GET_LAST_DEADLINE_DATES_SUCCESS,
    GET_LAST_DEADLINE_DATES_FAILURE,

    DOWNLOAD_PROCESS_STATEMENT_RTF_REQUEST,
    DOWNLOAD_PROCESS_STATEMENT_RTF_SUCCESS,
    DOWNLOAD_PROCESS_STATEMENT_RTF_FAILURE,

    FIND_REF_RESULTS_REQUEST,
    FIND_REF_RESULTS_SUCCESS,

    LOGOUT_USER,
    DISABLE_USER_SUCCESS,
    AUTHENTICATE_FAILURE,
    CLEAR_PROCESS_STATEMENTS,
    LAST_ACTIVE_PROCESS_STATEMENT,

} from 'Redux/Action';
import ProcessStatementState from 'Redux/Store/ProcessStatement/ProcessStatementState';
import {
    removeOpenRequest,
    removeFailedRequest,
    getSingleObject,
    addSingleObjectToReducer
} from 'ClientService/ReducerUtil/ReducerUtil';

const initialState = ProcessStatementState.getInitialState();

export const processStatementReducer = (state = initialState, action) => {

    switch (action.type) {

    case AUTHENTICATE_FAILURE :
    case LOGOUT_USER :
    case DISABLE_USER_SUCCESS :
        return {
            ...initialState,
        };

    case DOWNLOAD_PROCESS_STATEMENT_RTF_REQUEST:
    case DISMISS_ANSWER_FROM_PROCESS_STATEMENT_REQUEST:
    case DELETE_PROCESS_STATEMENT_REQUEST:
    case GET_ANSWER_INBOX_PROCESS_STATEMENT_COUNT_REQUEST:
    case GET_POOL_REQUEST_INBOX_REQUEST:
    case UPDATE_STATEMENT_PROCESS_REQUEST:
    case FIND_PROCESS_STATEMENT_REQUEST:
    case GET_LAST_DEADLINE_DATES_REQUEST:
    case UPDATE_OPEN_PROCESS_STATEMENT_REQUEST:
    case FIND_OPEN_PROCESS_STATEMENT_REQUEST:
    case SUBMIT_PROCESS_STATEMENT_REQUEST:
    case FIND_OPEN_PROCESS_STATEMENTS_REQUEST:
    case UPDATE_PROCESS_STATEMENTS_REQUEST:
    case FIND_PROCESS_STATEMENTS_REQUEST:
    case FIND_OPEN_ANSWER_PROCESS_STATEMENTS_REQUEST:
        return {
            ...state,
            openRequests: [...state.openRequests, action.type],
            failedRequests: removeFailedRequest(action, state),
            updatedAt: Date.now(),
        };

    case FIND_REF_RESULTS_REQUEST:
    case FIND_PROCESS_STATEMENT_RESULTS_REQUEST:
        return {
            ...state,
            processStatementDetailResults: null,
            openRequests: [...state.openRequests, action.type],
            failedRequests: removeFailedRequest(action, state),
            updatedAt: Date.now(),
        };

    case LAST_ACTIVE_PROCESS_STATEMENT:
        return {
            ...state,
            lastActiveProcessStatement: action.payload.id,
            updatedAt: Date.now(),
        };

    case GET_LAST_DEADLINE_DATES_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            lastDeadlineDates: {
                toReminderDate: action.payload.toReminderDate,
                toAnswerDate: action.payload.toAnswerDate,
                toSubsequentDeliveryDate: action.payload.toSubsequentDeliveryDate,
            },
            updatedAt: Date.now(),
        };


    case DISMISS_ANSWER_FROM_PROCESS_STATEMENT_SUCCESS:
    case SUBMIT_PROCESS_STATEMENT_SUCCESS:
    case UPDATE_STATEMENT_PROCESS_SUCCESS:
    case UPDATE_OPEN_PROCESS_STATEMENT_SUCCESS:
    case FIND_PROCESS_STATEMENT_SUCCESS:
    case FIND_OPEN_PROCESS_STATEMENT_SUCCESS: {
        const processStatement = getSingleObject(action.payload.processStatement);

        return {
            ...state,
            lastInteractiveGuid: processStatement.id,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
            processStatements: {
                ...state.processStatements,
                entries: addSingleObjectToReducer(state.processStatements.entries, action.payload.processStatement)
            },
        };
    }

    case FIND_REF_RESULTS_SUCCESS:
    case FIND_PROCESS_STATEMENT_RESULTS_SUCCESS: {
        const { processStatement, results } = action.payload;

        return {
            ...state,
            lastInteractiveGuid: processStatement?.id,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
            processStatementDetailResults: results || [],
            processStatements: {
                ...state.processStatements,
                entries: addSingleObjectToReducer(state.processStatements.entries, processStatement)
            },
        };

    }

    case UPDATE_PROCESS_STATEMENTS_SUCCESS: {
        let entries = { ...state.processStatements.entries };
        Object.keys(action.payload.processStatements).forEach(key => {
            entries = addSingleObjectToReducer(entries, action.payload.processStatements[key])
        });

        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
            processStatements: {
                ...state.processStatements,
                entries,
            },
        };

    }


    case DELETE_PROCESS_STATEMENT_SUCCESS:
        const processStatementEntries = { ...state.processStatements.entries };
        if(processStatementEntries[action.payload.id]) {
            delete processStatementEntries[action.payload.id];
        }

        return {
            ...state,
            lastInteractiveId: action.payload.id,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
            processStatements: {
                maxCount: state.processStatements.maxCount - 1,
                entries: processStatementEntries
            },
        };

    case GET_POOL_REQUEST_INBOX_SUCCESS:
        return {
            ...state,
            poolRequestInboxCount: action.payload.poolRequestInboxCount,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
        };

    case DOWNLOAD_PROCESS_STATEMENT_RTF_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
        };

    case GET_ANSWER_INBOX_PROCESS_STATEMENT_COUNT_SUCCESS:
        return {
            ...state,
            answeredInboxCount: action.payload.inboxCount,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
        };

    case FIND_OPEN_PROCESS_STATEMENTS_SUCCESS:
    case FIND_OPEN_ANSWER_PROCESS_STATEMENTS_SUCCESS:
    case FIND_PROCESS_STATEMENTS_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            processStatements: {
                entries: action.payload.processStatements,
                maxCount: (action.meta) ? action.meta.response.count : state.processStatements.maxCount,
            },
            updatedAt: Date.now(),
        };

    case CLEAR_PROCESS_STATEMENTS:
        return {
            ...state,
            processStatements: {
                entries: {},
                maxCount: 0,
            },
            updatedAt: Date.now(),
        };

    case DOWNLOAD_PROCESS_STATEMENT_RTF_FAILURE:
    case DELETE_PROCESS_STATEMENT_FAILURE:
    case DISMISS_ANSWER_FROM_PROCESS_STATEMENT_FAILURE:
    case GET_POOL_REQUEST_INBOX_FAILURE:
    case UPDATE_STATEMENT_PROCESS_FAILURE:
    case FIND_PROCESS_STATEMENT_FAILURE:
    case FIND_OPEN_PROCESS_STATEMENT_FAILURE:
    case GET_LAST_DEADLINE_DATES_FAILURE:
    case FIND_PROCESS_STATEMENT_RESULTS_FAILURE:
    case FIND_OPEN_PROCESS_STATEMENTS_FAILURE:
    case UPDATE_OPEN_PROCESS_STATEMENT_FAILURE:
    case SUBMIT_PROCESS_STATEMENT_FAILURE:
    case UPDATE_PROCESS_STATEMENTS_FAILURE:
    case FIND_PROCESS_STATEMENTS_FAILURE:
    case GET_ANSWER_INBOX_PROCESS_STATEMENT_COUNT_FAILURE:
    case FIND_OPEN_ANSWER_PROCESS_STATEMENTS_FAILURE:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            failedRequests: [...state.failedRequests, action.type],
            updatedAt: Date.now(),
        };



    default:
        return state;
    }
};
