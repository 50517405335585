import {
    AUTHENTICATE_REQUEST,
    AUTHENTICATE_SUCCESS,
    AUTHENTICATE_FAILURE,

    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE,

    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,

    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE,

    REGISTER_REQUEST,
    REGISTER_FAILURE,
    REGISTER_SUCCESS,

    DISABLE_USER_REQUEST,
    DISABLE_USER_SUCCESS,
    DISABLE_USER_FAILURE,

    LOGOUT_USER,
    APPLICATION_BOOTSTRAPPED,

} from 'Redux/Action';
import UserState from 'Redux/Store/User/UserState';
import {  removeOpenRequest } from 'ClientService/ReducerUtil/ReducerUtil';
import { GHOST_USER } from "AppConfig/Config";
const initialState = UserState.getInitialState();

export const userReducer = (state = initialState, action) => {
    switch (action.type) {

    case LOGOUT_USER :
    case DISABLE_USER_SUCCESS :
    case AUTHENTICATE_FAILURE :
        return {
            ...initialState,
        };


    case UPDATE_USER_REQUEST:
    case REGISTER_REQUEST:
    case AUTHENTICATE_REQUEST:
    case DISABLE_USER_REQUEST:
    case FORGOT_PASSWORD_REQUEST:
    case RESET_PASSWORD_REQUEST:
        return {
            ...state,
            openRequests: [...state.openRequests, action.type],
            failedRequests: [],
            updatedAt: Date.now(),
            error: false,
        };

    case APPLICATION_BOOTSTRAPPED:
        return {
            ...state,
            openRequests: [],
            failedRequests: [],
            updatedAt: Date.now(),
            error: false,
        };

    case RESET_PASSWORD_SUCCESS:
    case FORGOT_PASSWORD_SUCCESS:
    case REGISTER_SUCCESS: {
        return {
            ...state,
            openRequests: [],
            updatedAt: Date.now(),
            error: false,
        };
    }

    case UPDATE_USER_SUCCESS: {
        return {
            ...state,
            openRequests: [],
            updatedAt: Date.now(),
            user: action.payload.user,
            error: false,
        };
    }



    case AUTHENTICATE_SUCCESS: {
        const authentication = (action.payload.authentication) ? action.payload.authentication : state.authentication;
        let role           = (action.payload.role) ? action.payload.role : state.role;
        if(action.payload.user.isGhostUser) {
            role = { id: GHOST_USER };
        }
        return {
            ...state,
            openRequests: [],
            updatedAt: Date.now(),
            user: action.payload.user,
            role,
            authentication,
        };
    }


    case DISABLE_USER_FAILURE:
    case FORGOT_PASSWORD_FAILURE:
    case RESET_PASSWORD_FAILURE:
    case REGISTER_FAILURE:
    case UPDATE_USER_FAILURE:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            failedRequests: [...state.failedRequests, action.type],
            updatedAt: Date.now(),
            error: (action.payload && action.payload.data && action.payload.data.message) ? action.payload.data.message : true,
        };

    default:
        return state;
    }
};
