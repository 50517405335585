import BaseRepository from '../Base/BaseRepository';
import TargetActual from 'Domain/Model/TargetActual/TargetActual';


class TargetActualRepository extends BaseRepository {

    updateComment( id = null, data ) {
        return this.makeRequest({
            path: `/${this.baseRoute}/comment/${id}`,
            data: data,
            type: 'PATCH',
            callback: this.initializeSingleModel
        });
    }

}

export default new TargetActualRepository({
    model: TargetActual,
    baseRoute: 'target-actual',
    singular: 'targetActual',
    plural: 'targetActuals',
});
