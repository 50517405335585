import { ArrayContains } from 'ClientService';
import {
    REGISTER_FAILURE,
} from 'Redux/Action';

export const hasRegisterError = (state) =>
    ArrayContains(state.user.failedRequests, [
        REGISTER_FAILURE,
    ]);



