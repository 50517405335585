import { FIND_EMPLOYEES_REQUEST, findEmployeesFailure, findEmployeesSuccess} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function findEmployeesEpic(action$, store$, { EmployeeRepository }) {
    return action$.pipe(
        ofType( FIND_EMPLOYEES_REQUEST ),
        mergeMap(action => from(
            EmployeeRepository.findAll(action.payload)
                .then(({ employees, meta }) => findEmployeesSuccess({ employees, response: meta, request: action}))
                .catch(error => findEmployeesFailure({ error, request: action })),
        )),
    );
}
