import {
    UPDATE_PROCESS_STATEMENTS_REQUEST,
    updateProcessStatementsSuccess,
    updateProcessStatementsFailure,
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { message } from "antd";

export function updateProcessStatementsEpic(action$, store$, { ProcessStatementRepository }) {
    return action$.pipe(
        ofType(UPDATE_PROCESS_STATEMENTS_REQUEST),
        mergeMap( action => from(ProcessStatementRepository.bulkUpdate( action.payload.entries ))
            .pipe(
                mergeMap( ({ statements }) => {
                    message.success('Die Stellungnahmen wurden erfolgreich aktualisiert');
                    return from ([
                        updateProcessStatementsSuccess(statements),
                    ]);
                }),
                catchError( error => {
                    return from([
                        updateProcessStatementsFailure(error),
                    ])
                })
            )
        ),
    );
}
