export const DISMISS_ANSWER_FROM_PROCESS_STATEMENT_REQUEST = 'DISMISS_ANSWER_FROM_PROCESS_STATEMENT_REQUEST';

export const dismissAnswerFromProcessStatementRequest = ( id, {
    deleteAnswer,
    reason,
    toAnswerDate,
    toReminderDate,
    toSubsequentDeliveryDate,
}) => ({
    type: DISMISS_ANSWER_FROM_PROCESS_STATEMENT_REQUEST,
    payload: {
        id,
        deleteAnswer,
        reason,
        toAnswerDate,
        toReminderDate,
        toSubsequentDeliveryDate,
    }
});
