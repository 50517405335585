import { lazy } from "react";
import {
    RESULT_OVERVIEW_GROUPS,
    ALL,
    GHOST_USER,
    ADM,
    DAS_ALL,
    DAS,
    DAS_KV,
    DAS_KH,
    VA,
    KH,
    LAG_FK,
    LAG_GS, SHOW_EDOK_MENU,
    ADM_KH,
} from 'AppConfig/Config';

export const internalRoutes = {

    documentUpload: {
        path: '/',
        Component: lazy(() => (import('Page/Internal/Document/Upload'))),
        access: {
            groups: [ ...ALL ]
        },
        sideBoard: {
            sorting: 10,
            icon: 'download',
            label: 'Dokumente',
        }
    },

    detailDownloadDocument: {
        path: '/dokument/:id',
        Component: lazy(() => (import('Page/Internal/Document/DocumentDetailPage'))),
        access: {
            groups: [ ...ALL ]
        },
    },

    dashboard: {
        path: '/berichtsannahme',
        Component: lazy(() => (import('Page/Internal/XmlUpload/XmlUpload'))),
        access: {
            groups: [ VA, DAS, DAS_KV ]
        },
        sideBoard: {
            sorting: 80,
            icon: 'file',
            label: 'XML-Dateien',
        }
    },

    poolRequestOverview: {
        path: '/ergebnis/pool-anfragen-verwaltung/uebersicht',
        Component: lazy(() => (import('Page/Internal/PoolRequest/PoolRequestOverview'))),
        access: {
            groups: [ ...RESULT_OVERVIEW_GROUPS ]
        },
    },

    poolRequestDetail: {
        path: '/ergebnis/pool-anfragen-verwaltung/:id',
        Component: lazy(() => (import('Page/Internal/PoolRequest/PoolRequestDetail'))),
        access: {
            groups: [ ...RESULT_OVERVIEW_GROUPS ]
        },
    },

    sendPoolRequest: {
        path: '/ergebnis/pool-anfragen-einleiten',
        Component: lazy(() => (import('Page/Internal/PoolRequest/SendPoolRequest'))),
        access: {
            groups: [ LAG_FK, LAG_GS ]
        },
    },

    updatePoolRequest: {
        path: '/ergebnis/pool-anfragen/:id',
        Component: lazy(() => (import('Page/Internal/PoolRequest/UpdatePoolRequest'))),
        access: {
            groups: [ LAG_FK, LAG_GS ]
        },
    },

    resultsOverview: {
        path: '/ergebnis-uebersicht',
        Component: lazy(() => (import('Page/Internal/Result/ResultOverview'))),
        access: {
            groups: [ ...RESULT_OVERVIEW_GROUPS ]
        },
        sideBoard: {
            sorting: 50,
            icon: 'table',
            label: 'Ergebnisse',
            render: lazy(() => (import('Component/OpenPoolRequestsCount/OpenPoolRequestsCount')))
        }
    },

    processStatementOverview: {
        path: '/stellungnahme-uebersicht',
        Component: lazy(() => (import('Page/Internal/ProcessStatement/ProcessStatementOverview'))),
        access: {
            groups: [ KH, VA, ADM_KH, LAG_GS, LAG_FK, GHOST_USER ]
        },
        visibleForGhostUser: true,
        sideBoard: {
            sorting: 70,
            icon: 'form',
            label: 'Stellungnahmen',
            render: lazy(() => (import('Component/OpenAnsweredPoolRequestsCount/OpenAnsweredPoolRequestsCount')))
        }
    },

    resultProcessStatementDetail: {
        path: '/stellungnahme-uebersicht/ergebnis/:id',
        Component: lazy(() => (import('Page/Internal/ProcessStatement/ProcessStatementDetailPage'))),
        access: {
            groups: [ ...RESULT_OVERVIEW_GROUPS ]
        },
    },

    poolRequestPostBox: {
        path: '/ergebnis-uebersicht/postbox',
        Component: lazy(() => (import('Page/Internal/ProcessStatement/ProcessStatementInbox'))),
        access: {
            groups: [ ...RESULT_OVERVIEW_GROUPS ]
        },
    },

    answeredPoolRequestPostBox: {
        path: '/stellungnahme-uebersicht/postbox',
        Component: lazy(() => (import('Page/Internal/ProcessStatement/ProcessStatementAnsweredInbox'))),
        access: {
            groups: [ KH, VA, ADM_KH ]
        },
    },

    resultsDetail: {
        path: '/ergebnis/:id',
        Component: lazy(() => (import('Page/Internal/Result/ResultDetailPage'))),
        access: {
            groups: [ ...RESULT_OVERVIEW_GROUPS ]
        },
    },

    detailDownloadFile: {
        path: '/download-datei/:guid',
        Component: lazy(() => (import('Page/Internal/File/FileDetailPage'))),
    },

    reportOverview: {
        path: '/berichte',
        Component: lazy(() => (import('Page/Internal/Report/ReportOverviewPage'))),
        access: {
            groups: [ KH, VA, ADM_KH, DAS, DAS_KH ]
        },
        sideBoard: {
            sorting: 60,
            icon: 'report',
            label: 'Berichte',
        }
    },

    careProviderList: {
        path: '/leistungserbringer/uebersicht',
        Component: lazy(() => (import('Page/Internal/CareProvider/Overview'))),
        access: {
            groups: [ ADM, ...DAS_ALL ]
        },
        sideBoard: {
            sorting: 20,
            icon: 'medicine-box',
            label: 'Leistungserbringer',
        }
    },

    careProviderDetail: {
        path: '/leistungserbringer/:id',
        Component: lazy(() => (import('Page/Internal/CareProvider/Detail'))),
        access: {
            groups: [ ADM, ...DAS_ALL ]
        },
    },

    pseudonymList: {
        path: '/pseudonyme/uebersicht',
        Component: lazy(() => (import('Page/Internal/Pseudonym/Overview'))),
        access: {
            groups: [ ADM, DAS ]
        },
        sideBoard: {
            sorting: 25,
            icon: 'eye-invisible',
            label: 'Pseudonyme',
        }
    },

    pseudonymDetail: {
        path: '/pseudonyme/:id',
        Component: lazy(() => (import('Page/Internal/Pseudonym/Detail'))),
        access: {
            groups: [ ADM, DAS ]
        },
    },

    targetActualList: {
        path: '/soll-ist-vergleich/uebersicht',
        Component: lazy(() => (import('Page/Internal/TargetActual/TargetActualOverview'))),
        access: {
            groups: [ DAS, DAS_KH, KH, VA, ADM_KH, ADM ]
        },
        sideBoard: {
            sorting: 40,
            icon: 'comment',
            label: 'Soll-Ist-Übersicht',
        }
    },

    employeeList: {
        path: '/mitarbeiter/uebersicht',
        Component: lazy(() => (import('Page/Internal/Employee/Overview'))),
        access: {
            groups: [ ADM, ADM_KH, ...DAS_ALL ]
        },
        sideBoard: {
            sorting: 30,
            icon: 'team',
            label: 'Mitarbeiter',
        }
    },

    employeeDetail: {
        path: '/mitarbeiter/:id',
        Component: lazy(() => (import('Page/Internal/Employee/Detail'))),
        access: {
            groups: [ ADM, ADM_KH, ...DAS_ALL ]
        },
    },


    edokList: {
        path: '/edok/uebersicht',
        Component: lazy(() => (import('Page/Internal/Edok/EdokOverview'))),
        access: {
            groups: SHOW_EDOK_MENU,
        },
        sideBoard: {
            sorting: 90,
            icon: 'AuditOutlined',
            label: 'EDOK',
            customVisibleCallback: (route, { user }) => {
                if([DAS, DAS_KV].includes(user.role)) {
                    return true;
                }
                return user.modules && (user.modules.length === 0 || !!user.modules.includes('WI'));
            }
        }
    },

    edokDetail: {
        path: '/edok/:module/:id',
        Component: lazy(() => (import('Page/Internal/Edok/EdokDetail'))),
        access: {
            groups: SHOW_EDOK_MENU,
        },
    },


    chatOverview: {
        path: '/messenger',
        Component: lazy(() => (import('Page/Internal/Chat/ChatOverviewPage'))),
        access: {
            groups: [ LAG_GS, KH, VA, ADM_KH ]
        },
        sideBoard: {
            sorting: 75,
            icon: 'message',
            label: 'Nachrichten',
            render: lazy(() => (import('Component/Chat/OpenChatConversationsCount/OpenChatConversationsCount')))

        }
    },
    chatDetail: {
        path: '/messenger/:id',
        Component: lazy(() => (import('Page/Internal/Chat/ChatDetailPage'))),
        access: {
            groups: [ LAG_GS, KH, VA, ADM_KH ]
        },
    },


    toggleZipMenu: {
        path: '/',
        sideBoard: {
            sorting: 100,
            label: 'ZIP-Download',
            defaultRendererDisabled: true,
            render: lazy(() => (import('Component/ZipDownload/ZipDownloadMenuElement/ZipDownloadMenuElement'))),
            bottom: true,
        }
    },

    profile: {
        path: '/profil',
        Component: lazy(() => (import('Page/Internal/Profile/Profile'))),
        sideBoard: {
            sorting: 110,
            icon: 'user',
            label: 'Mein Konto',
            bottom: true,
        }
    },

    settings: {
        path: '/settings',
        Component: lazy(() => (import('Page/Internal/Settings/Settings'))),
        access: {
            groups: [ ADM, LAG_GS ]
        },
        sideBoard: {
            sorting: 120,
            icon: 'setting',
            label: 'Systemeinstellungen',
            bottom: true,
        }
    },

    logout: {
        path: '/',
        visibleForGhostUser: true,
        sideBoard: {
            sorting: 130,
            icon: 'logout',
            label: 'Abmelden',
            bottom: true,
        }
    },
}
