import {
    CREATE_PSEUDONYM_REQUEST,
    CREATE_PSEUDONYM_FAILURE,
    CREATE_PSEUDONYM_SUCCESS,

    UPDATE_PSEUDONYM_REQUEST,
    UPDATE_PSEUDONYM_FAILURE,
    UPDATE_PSEUDONYM_SUCCESS,

    FIND_PSEUDONYM_REQUEST,
    FIND_PSEUDONYM_FAILURE,
    FIND_PSEUDONYM_SUCCESS,

    FIND_PSEUDONYMS_REQUEST,
    FIND_PSEUDONYMS_FAILURE,
    FIND_PSEUDONYMS_SUCCESS,

    FIND_PSEUDONYM_BY_MODULE_AND_CARE_PROVIDER_REQUEST,
    FIND_PSEUDONYM_BY_MODULE_AND_CARE_PROVIDER_FAILURE,
    FIND_PSEUDONYM_BY_MODULE_AND_CARE_PROVIDER_SUCCESS,

    LOGOUT_USER,
    DISABLE_USER_SUCCESS,
    AUTHENTICATE_FAILURE,

} from 'Redux/Action';
import PseudonymState from 'Redux/Store/Pseudonym/PseudonymState';
import { removeOpenRequest, removeFailedRequest, addSingleObjectToReducer, getSingleObject } from 'ClientService/ReducerUtil/ReducerUtil';

const initialState = PseudonymState.getInitialState();

export const pseudonymReducer = (state = initialState, action) => {

    switch (action.type) {

    case AUTHENTICATE_FAILURE :
    case LOGOUT_USER :
    case DISABLE_USER_SUCCESS :
        return {
            ...initialState,
        };

    case FIND_PSEUDONYM_BY_MODULE_AND_CARE_PROVIDER_REQUEST:
    case CREATE_PSEUDONYM_REQUEST:
    case UPDATE_PSEUDONYM_REQUEST:
    case FIND_PSEUDONYM_REQUEST:
    case FIND_PSEUDONYMS_REQUEST:
        return {
            ...state,
            lastInteractiveId: null,
            openRequests: [...state.openRequests, action.type],
            failedRequests: removeFailedRequest(action, state),
            updatedAt: Date.now(),
        };

    case FIND_PSEUDONYM_BY_MODULE_AND_CARE_PROVIDER_SUCCESS:
    case CREATE_PSEUDONYM_SUCCESS:
    case UPDATE_PSEUDONYM_SUCCESS:
    case FIND_PSEUDONYM_SUCCESS:
        const pseudonym = getSingleObject(action.payload.pseudonym);

        return {
            ...state,
            lastInteractiveId: pseudonym.id,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
            pseudonyms: {
                ...state.pseudonyms,
                entries: addSingleObjectToReducer(state.pseudonyms.entries, action.payload.pseudonym)
            },
        };

    case FIND_PSEUDONYMS_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            pseudonyms: {
                entries: action.payload.pseudonyms,
                maxCount: (action.meta) ? action.meta.response.count : state.pseudonyms.maxCount,
            },
            updatedAt: Date.now(),
        };

    case CREATE_PSEUDONYM_FAILURE:
    case FIND_PSEUDONYM_BY_MODULE_AND_CARE_PROVIDER_FAILURE:
    case UPDATE_PSEUDONYM_FAILURE:
    case FIND_PSEUDONYM_FAILURE:
    case FIND_PSEUDONYMS_FAILURE:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            failedRequests: [...state.failedRequests, action.type],
            updatedAt: Date.now(),
        };



    default:
        return state;
    }
};
