export const FIND_EVAL_YEARS_FAILURE = 'FIND_EVAL_YEARS_FAILURE';

export const findEvalYearsFailure = ({ error, request }) => ({
    type: FIND_EVAL_YEARS_FAILURE,
    payload: error,
    error: true,
    meta: {
        request
    }
});
