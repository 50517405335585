import BaseRepository from '../Base/BaseRepository';
import StatementTemplate from 'Domain/Model/StatementTemplate/StatementTemplate';

class StatementTemplateRepository extends BaseRepository {

    findByIndicatorId( indicatorId ) {
        return this.makeRequest({
            path: `/${this.baseRoute}/indicator/${indicatorId}`,
            type: 'GET',
            callback: this.initializeSingleModel,
        });
    };

    findAllIndicatorIds (filterSettings = {}) {
        return this.setFilterSettings(filterSettings).makeRequest({
            path: `/${this.baseRoute}/indicator`,
            data: this.getFilterSettings(),
            type: 'GET',
            callback: ( response ) => {
                return response.indicatorIds;
            }
        });
    };

}

export default new StatementTemplateRepository({
    model: StatementTemplate,
    baseRoute: 'statement-template',
    singular: 'statementTemplate',
    plural: 'statementTemplates',
});
