import BaseRepository from '../Base/BaseRepository';
import NwieForm from 'Domain/Model/Edok/NwieForm';

class EdokRepository extends BaseRepository {

    getCurrentEdokYear() {
        return this.makeRequest({
            path: `/${this.baseRoute}/current-year`,
            type: 'GET',
            callback: (response) => response.year,
        });

    }

    findAllDocumentations({ year }) {
        return this.makeRequest({
            path: `/edok-documentation/${year}`,
            type: 'GET',
            callback: (response) => response.documentations,
        });

    }

    updateDocumentations({ year, updateData }) {
        return this.makeRequest({
            path: `/edok-documentation/${year}`,
            type: 'POST',
            data: {
                documentations: updateData,
            },
            callback: (response) => response.documentations,
        });

    }

    findByModuleAndYear({ year, module, identNumber }) {
        return this.makeRequest({
            path: `/${this.baseRoute}/${year}/${module}/${identNumber}`,
            type: 'GET',
            callback: this.initializeSingleModel
        });
    }


    getAvailableModules() {
        return this.makeRequest({
            path: `/${this.baseRoute}/available-modules`,
            type: 'GET',
            callback: (response) => ({
                modules: response.modules,
                moduleForms: response?.moduleForms || {}
            }),
        });

    }

    send(id) {
        return this.makeRequest({
            path: `/${this.baseRoute}/send/${id}`,
            type: 'POST',
            callback: this.initializeSingleModel
        });
    }



}

export default new EdokRepository({
    model: NwieForm,
    baseRoute: 'edok',
    singular: 'form',
    plural: 'forms',
});
