import {
    FIND_EMPLOYEE_REQUEST,
    FIND_EMPLOYEES_REQUEST,
    CREATE_EMPLOYEE_REQUEST,
    UPDATE_EMPLOYEE_REQUEST,
    DELETE_EMPLOYEE_REQUEST,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isEmployeeLoading = (state) =>
    ArrayContains(state.employee.openRequests, [
        FIND_EMPLOYEE_REQUEST,
        FIND_EMPLOYEES_REQUEST,
        CREATE_EMPLOYEE_REQUEST,
        UPDATE_EMPLOYEE_REQUEST,
        DELETE_EMPLOYEE_REQUEST,
    ]);