import {
    SHOW_ERROR_NOTIFICATION, errorActionConstants,
    checkUserSession, logoutUser
}                                   from 'Redux/Action';
import { ofType }                   from 'redux-observable';
import { EMPTY, from, of }          from 'rxjs';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { message as modal }         from 'antd';
import { defaultErrorMessages }     from 'ClientService';
import isString                     from 'lodash/isString';

export function showErrorNotificationEpic(action$, store$) {
    return action$.pipe(
        ofType(SHOW_ERROR_NOTIFICATION, ...errorActionConstants),
        withLatestFrom(store$),
        mergeMap(([action, store]) => {
            let message = defaultErrorMessages(action.payload.error || action.payload);
            if(message && message === 'Unauthorized') {
                return of(checkUserSession());
            }
            if( !!message && isString(message) ) {
                modal.error(message);
                return EMPTY;
            }

            if(!message && !action.payload.data?.errors && !action.payload.data?.message) {
                return EMPTY;
            }

            if(message && message.hasOwnProperty('message') && !!message.message && message.message.match(/No Authorization/i)){
                if(action.payload.error.hasOwnProperty('loginFailure')) {
                    modal.error('Nutzername oder Passwort stimmen nicht überein.');
                    return EMPTY;
                }
                modal.error('Bitte melden Sie sich neu an.');
                return from([
                    logoutUser()
                ]);
            }

            if(!!action.payload.data?.errors && action.payload.data.errors[0].errorMessage) {
                modal.error(action.payload.data.errors[0].errorMessage);
                return EMPTY;
            }
            if(!!action.payload.data?.message && !!action.payload.data?.route) {
                modal.error(`${action.payload.data.message} (${action.payload.data.route})`);
                return EMPTY;
            }
            if(!!action.payload.data?.message) {
                modal.error(`${action.payload.data.message}`);
                return EMPTY;
            }
            if(action.payload.hasOwnProperty('message')) {
                modal.error(action.payload.message);
                return EMPTY;
            }

            modal.error(message?.message || message);
            return EMPTY
        }),
    );
}
