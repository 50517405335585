import {
    UPDATE_MAIL_TEMPLATE_REQUEST,
    updateMailTemplateSuccess,
    updateMailTemplateFailure,
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { message } from "antd";

export function updateMailTemplateEpic(action$, store$, { MailTemplateRepository }) {
    return action$.pipe(
        ofType(UPDATE_MAIL_TEMPLATE_REQUEST),
        mergeMap( action => from(MailTemplateRepository.update(action.payload.id, action.payload.updateData ))
            .pipe(
                mergeMap( mailTemplate => {
                    message.success('Die Mail-Vorlage wurde erfolgreich gespeichert.');
                    return from ([
                        updateMailTemplateSuccess({ mailTemplate }),
                    ]);
                }),
                catchError( error => {
                    return from([
                        updateMailTemplateFailure(error),
                    ])
                })
            )
        ),
    );
}
