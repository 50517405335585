import { combineEpics } from 'redux-observable';

import { findPseudonymsEpic } from './FindPseudonyms/FindPseudonymsEpic';
import { findPseudonymEpic } from './FindPseudonym/FindPseudonymEpic';
import { createPseudonymRequestEpic } from './CreatePseudonym/CreatePseudonymRequestEpic';
import { updatePseudonymRequestEpic } from './UpdatePseudonym/UpdatePseudonymRequestEpic';
import { findPseudonymByModuleAndCareProviderEpic } from './FindPseudonymByModuleAndCareProvider/FindPseudonymByModuleAndCareProviderEpic';


export default combineEpics(
    findPseudonymsEpic,
    findPseudonymEpic,
    createPseudonymRequestEpic,
    updatePseudonymRequestEpic,
    findPseudonymByModuleAndCareProviderEpic,
);
