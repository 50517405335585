import { UPLOAD_DOCUMENT_REQUEST, uploadDocumentSuccess, uploadDocumentFailure} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function uploadDocumentEpic(action$, store$, { DocumentRepository }) {
    return action$.pipe(
        ofType(UPLOAD_DOCUMENT_REQUEST),
        mergeMap( action =>{
            return from(
                DocumentRepository.uploadDocument( action.payload.formData )
                    .then(document => uploadDocumentSuccess(document))
                    .catch(error => uploadDocumentFailure(error)),
            );
        }),
    );
}
