import { LE }                                     from 'AppConfig/Config';
import { DOWNLOAD_PROCESS_STATEMENT_RTF_SUCCESS } from 'Redux/Action';
import { ofType }                                 from 'redux-observable';
import { userContainsRole }                       from 'Redux/Query';
import { EMPTY }                                  from 'rxjs';
import { mergeMap, withLatestFrom }               from 'rxjs/operators';

export function downloadProcessStatementRtfFileSuccessEpic(action$, store$) {
    return action$.pipe(
        ofType(DOWNLOAD_PROCESS_STATEMENT_RTF_SUCCESS),
        withLatestFrom(store$),
        mergeMap(([_, state]) => {
            if(userContainsRole(state)( [ ...LE ])) {
                // window.location.reload();
            }
            return EMPTY;
        }),
    );
}
