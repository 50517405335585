export default class ResultRating {

    constructor(data) {

        this.id              = data.id;
        this.createdAt       = data.createdAt;
        this.code            = data.code;
        this.rateValue       = data.rateValue;
        this.classValue      = data.classValue;
        this.ak              = data.ak;
        this.qi              = data.qi;
    }

}
