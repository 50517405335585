export const FIND_MODULES_SUCCESS = 'FIND_MODULES_SUCCESS';

export const findModulesSuccess = ({ modules, request, response }) => ({
    type: FIND_MODULES_SUCCESS,
    payload: { modules },
    meta: {
        request,
        response,
    }
});
