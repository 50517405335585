import {
    UPDATE_COMMENT_BY_TARGET_ACTUAL_REQUEST,
    updateCommentByTargetActualSuccess,
    updateCommentByTargetActualFailure,
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { message } from "antd";

export function updateCommentByTargetActualEpic(action$, store$, { TargetActualRepository }) {
    return action$.pipe(
        ofType(UPDATE_COMMENT_BY_TARGET_ACTUAL_REQUEST),
        mergeMap( action => from(TargetActualRepository.updateComment(action.payload.id, action.payload.updateData ))
            .pipe(
                mergeMap( targetActual => {
                    message.success('Soll-Ist Kommentar wurde erfolgreich gespeichert.');
                    return from ([
                        updateCommentByTargetActualSuccess(targetActual),
                    ]);
                }),
                catchError( error => {
                    return from([
                        updateCommentByTargetActualFailure(error),
                    ])
                })
            )
        ),
    );
}
