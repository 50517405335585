const getInitialState = () => ({
    openRequests: [],
    failedRequests: [],
    authentication: {
        accessToken: null,
        refreshToken: null,
        accessTokenExpiresAt: null,
        refreshTokenExpiresAt: null,
    },
    updatedAt: 0,
    user: {},
    role: {},
    hasError: false,
});

export default {
    getInitialState
};
