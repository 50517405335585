import {
    UPDATE_OPEN_PROCESS_STATEMENT_REQUEST,
    updateOpenProcessStatementSuccess,
    updateOpenProcessStatementFailure,
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { message } from "antd";

export function updateOpenProcessStatementEpic(action$, store$, { ProcessStatementRepository }) {
    return action$.pipe(
        ofType(UPDATE_OPEN_PROCESS_STATEMENT_REQUEST),
        mergeMap( action => from(ProcessStatementRepository.updateInbox(action.payload.id, action.payload.formData ))
            .pipe(
                mergeMap( statement => {
                    message.success('Stellungnahme wurde erfolgreich gespeichert.');
                    return from ([
                        updateOpenProcessStatementSuccess( statement ),
                    ]);
                }),
                catchError( error => {
                    return from([
                        updateOpenProcessStatementFailure(error),
                    ])
                })
            )
        ),
    );
}
