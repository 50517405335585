const getInitialState = () => ({
    updatedAt: 0,
    openRequests: [],
    failedRequests: [],
    rtfTemplate: null,
});

export default {
    getInitialState
};
