import React from 'react';
import { connect } from 'react-redux';
import Icon from "Common/Icon/Icon";
import Wrapper from './LoggedInAs.style';
import { getUser, isGhostUser, isUserStateEqual } from 'Redux/Query';
import Link from 'Common/Link/Link';

class LoggedInAs extends React.Component {


    constructor(props) {
        super(props);

        this.state = {

        }
    }

    renderCareProviders = () => {
        const { careProviders } = this.props.user;
        if(!careProviders || careProviders.length === 0) {
            return null;
        }

        return (
            <div className="userCareProviders">
                Leistungserbringer:
                { careProviders.map(careProvider =>
                    <span key={ `userCareProvider${careProvider.id}` }>
                        { ( careProvider.destinationKey ? careProvider.destinationKey : careProvider.identNumber) }
                    </span>
                )}

            </div>
        )

    }

    renderUser = () => {
        return (
            <span className="user bold">
                {this.props.user.name}
                { this.renderCareProviders() }
            </span>
        );
    }

    renderUserInformation = () => {
        if( this.props.skeleton ) {
            return this.renderUser();
        }
        return (
            <Link href="profile">
                { this.renderUser() }
            </Link>
        );
    }

    render() {
        const { isGhostUser, dispatch, skeleton, ...props } = this.props;
        return (
            <Wrapper { ...props }>
                <Icon type="user" />
                <span>
                    <span>Angemeldet als </span>
                    { this.props.isGhostUser ?
                        <span className="user bold"> {this.props.user.name}</span> :
                        this.renderUserInformation()

                    }
                </span>
            </Wrapper>
        );
    }

}

function mapStateToProps(state, props) {
    return {
        user: getUser(state),
        isGhostUser: isGhostUser(state),
    };
}


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
};

const options = {
    areStatesEqual: (nextStore, prevStore) => {
        return (
            isUserStateEqual(nextStore, prevStore)
        );
    }
};

export default connect( mapStateToProps, undefined, mergeProps, options )(LoggedInAs);

