export const COUNT_OPEN_CHAT_CONVERSATIONS_FAILURE = 'COUNT_OPEN_CHAT_CONVERSATIONS_FAILURE';

export const countOpenChatConversationsFailure = ({ error, request }) => ({
    type: COUNT_OPEN_CHAT_CONVERSATIONS_FAILURE,
    payload: error,
    error: true,
    meta: {
        request
    }
});
