import Employee         from 'Domain/Model/Employee/Employee';
import ProcessStatement from "Domain/Model/ProcessStatement/ProcessStatement";
import ResultCsvModel   from "./Result.csv";

export default class Result {

    constructor( data ) {

        this.id                     = data.id;
        this.module                 = data.module;
        this.subModule              = data.subModule;
        this.evalYear               = data.evalYear;
        this.reportPeriod           = data.reportPeriod;
        this.specYear               = data.specYear;
        this.indicatorId            = data.indicatorId;
        this.indicatorType          = data.indicatorType;
        this.indicatorDesc          = data.indicatorDesc;
        this.careProvider           = data.careProvider;
        this.pseudonym              = data.pseudonym || {};
        this.numerator              = data.numerator;
        this.numeratorE             = data.numeratorE;
        this.denominator            = data.denominator;
        this.result                 = data.result;
        this.ciTop                  = data.ciTop;
        this.ciBottom               = data.ciBottom;
        this.assessmentCalc         = data.assessmentCalc;
        this.assessmentStat         = data.assessmentStat;
        this.indicatorUnit          = data.indicatorUnit;
        this.significanceValue      = data.significanceValue;
        this.refValue               = data.refValue;
        this.refOp                  = data.refOp;
        this.refCalc                = data.refCalc;
        this.tendency               = data.tendency;
        this.comparability          = data.comparability;
        this.abnormalOps            = data.abnormalOps;
        this.thresholdValue         = data.thresholdValue;
        this.compYear               = data.compYear;
        this.evalDate               = data.evalDate;
        this.listType               = data.listType;
        this.qikType                = data.qikType;
        this.origin                 = data.origin;
        this.qualityReport          = data.qualityReport;

        // Bewertung und Kommentare
        this.ratingCode             = data.ratingCode;
        this.sendCommentMail        = data.sendCommentMail;
        this.commentQb              = data.commentQb;
        this.resultComment          = data.resultComment;
        this.resultCommentDate      = data.resultCommentDate;
        this.resultCommentEditorId  = data.resultCommentEditorId;
        this.commentInternal        = data.commentInternal;
        this.commentInternalGs      = data.commentInternalGs;

        // QSEB - Allgemein
        this.reportStprocAssessment = data.reportStprocAssessment;
        this.reportStprocIntro      = data.reportStprocIntro;
        this.reportStprocAction_1   = data.reportStprocAction_1;
        this.reportStprocAction_2   = data.reportStprocAction_2;

        // QSEB - Stellungnahme
        this.assessmentType         = data.assessmentType;
        this.assessmentComment      = data.assessmentComment;
        this.perfStprocWrite        = data.perfStprocWrite;
        this.perfStprocTalk         = data.perfStprocTalk;
        this.perfStprocInspection   = data.perfStprocInspection;
        this.reasonNoStproc         = data.reasonNoStproc;
        this.recomFk                = data.recomFk;
        this.recomFkDiffReason      = data.recomFkDiffReason;
        this.reasonNoEndStproc      = data.reasonNoEndStproc;
        this.commentQseb            = data.commentQseb;
        this.reasonAction           = data.reasonAction;

        // QSEB - Maßnahmen 1
        this.actionTraining         = data.actionTraining;
        this.actionQcircle          = data.actionQcircle;
        this.actionPathway          = data.actionPathway;
        this.actionAudit            = data.actionAudit;
        this.actionPeerReview       = data.actionPeerReview;
        this.actionGuideline        = data.actionGuideline;
        this.actionOtherComment     = data.actionOtherComment;
        this.reasonActionDiff       = data.reasonActionDiff;

        // QSEB - Maßnahmen 2
        this.actionTwoComment        = data.actionTwoComment;

        this.statementProcesses        = !!data.statementProcesses ? data.statementProcesses.map(entry => new ProcessStatement(entry)) : [];
        this.openStatementProcesses    = !!data.openStatementProcesses ? data.openStatementProcesses.map(entry => new ProcessStatement(entry)) : [];
        this.careProviderResponses     = !!data.careProviderResponses ? data.careProviderResponses.map(entry => new ProcessStatement(entry)) : [];
        this.openCareProviderResponses = !!data.openCareProviderResponses ? data.openCareProviderResponses.map(entry => new ProcessStatement(entry)) : [];

        this.reference              = data.reference;
        this.createdAt              = data.createdAt;

        this.lagRatingResponseDate  = data.lagRatingResponseDate;
        this.editor                 = (!!data.editor) ? new Employee(data.editor) : {};
    }

    mapToCsvEntry({ checkUserRole, resultLand }) {
        const entity = {
            LAND: resultLand,
        };
        const parseType = (value, type) => {
            switch(type) {
                case 'INTEGER' : return parseInt(value) || '';
                case 'FLOAT'   : return `${parseFloat(value)}`.replace('.', ',').replace('NaN','') || '';
                case 'BOOLEAN' : return !!value && !!(value?.length > 0 || !!`${value}`.match(/true/i));
                case 'NULLABLE_BOOLEAN' : return (value === null || value === '') ? '' : (!!value && !!(value?.length > 0 || !!`${value}`.match(/true/i))) ? 'true' : 'false';
                default : return value;
            }
        };
        const _self = this;

        const csvModel = new ResultCsvModel(this);
        csvModel.checkUserRole = checkUserRole;

        csvModel.mapFieldToCsv.forEach(({ key, name, type = 'STRING', parse = (key, type) => { return parseType(_self[key], type); } }) => {
            if(!this.hasOwnProperty(key)) { return; }
            const parsedValue = parse(key, type);
            if(parsedValue === '__NO_PRINT') {
                return;
            }
            entity[name] = parsedValue;
        })

        return entity;
    }

    getCareProviderKey() {
        // pseudonym.key (pseudonym_id) - care_provider_identity (care_provider_id – ident_number)
        const { pseudonym, careProvider } = this;
        const { identity } = careProvider;
        if(!identity || !identity.destinationKey) {
            return `${pseudonym.key}`;
        }
        return `${pseudonym.key}-${identity.destinationKey}`;
    }

}
