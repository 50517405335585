import { combineEpics } from 'redux-observable';

import { findEmployeesEpic }             from './FindEmployees/FindEmployeesEpic';
import { findEmployeeEpic }              from './FindEmployee/FindEmployeeEpic';
import { createEmployeeRequestEpic }     from './CreateEmployee/CreateEmployeeRequestEpic';
import { updateEmployeeRequestEpic }     from './UpdateEmployee/UpdateEmployeeRequestEpic';
import { deleteEmployeeEpic }            from './DeleteEmployee/DeleteEmployeeEpic';
import { updateEmployeeSuccessEpic }     from './UpdateEmployee/UpdateEmployeeSuccessEpic';
import { resendActivateLinkRequestEpic } from './ResendActivateLink/ResendActivateLinkRequestEpic';
import { resendActivateLinkSuccessEpic } from './ResendActivateLink/ResendActivateLinkSuccessEpic';


export default combineEpics(
    findEmployeesEpic,
    findEmployeeEpic,
    createEmployeeRequestEpic,
    updateEmployeeRequestEpic,
    updateEmployeeSuccessEpic,
    deleteEmployeeEpic,
    resendActivateLinkRequestEpic,
    resendActivateLinkSuccessEpic,
);
