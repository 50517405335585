export const version = '2024.02.09';
export const ServerUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:5500';
export const ClientUrl = process.env.REACT_APP_CLIENT_URL || 'http://localhost:3000';
export const UploadClientUrl = process.env.REACT_APP_UPLOAD_URL || 'http://localhost:3100';

// ACCESS_GROUPS
export const ADM    =   1; // Administrator des Systems
export const ADM_KH =  14; // Administrator of the system only for KH
export const DAS    =   2; // Mitarbeiter der DAS
export const KZV    =   3; // Mitarbeiter der Kassenzahnärztliche Vereinigung
export const KV     =   4; // Mitarbeiter der Kassenärztliche Vereinigung
export const LAG_FK =   5; // Mitarbeiter der Fachkommissionen der LAG
export const LAG_LG =   6; // Mitarbeiter des Lenkungsgremiums der LAG
export const LAG_GS =   7; // Mitarbeiter der Geschäftsstelle der LAG
export const VA     =   8; // Vertragsärzte
export const KH     =   9; // Krankenhäuser
export const LKG    =  10; // Mitarbeiter der LKG
export const EXT    =  11; // Externer Mitarbeiter
export const DAS_KH =  12; // Mitarbeiter der DAS-KH
export const DAS_KV =  13; // Mitarbeiter der DAS-KV
export const API    =  15; // Mitarbeiter der DAS-KV

export const LAG = [ LAG_FK, LAG_LG, LAG_GS ];
export const LE  = [ KH, VA, ADM_KH ];
export const ALL = [ ...LAG, LKG, KH, VA, KV, KZV, DAS, DAS_KH, DAS_KV, ADM, EXT, ADM_KH, API ];
export const HAS_ACCESS_GRANTS = [ LAG_GS, LAG_FK, KH, VA, ADM_KH ];
export const HAS_SEND_ACCESS_GRANTS   = [ LAG_GS, LAG_FK ];
export const HAS_ANSWER_ACCESS_GRANTS = [ KH, VA, ADM_KH ];
export const DAS_ALL                  = [ DAS, DAS_KH, DAS_KV ]
export const SHOW_EDOK_MENU           = [ DAS, DAS_KV, ADM, VA ];

export const RESULT_OVERVIEW_GROUPS = [ DAS, DAS_KH, LAG_GS, KH, VA, ADM_KH ];

export const CARE_PROVIDER_ROLES = {
    KH:  0, // Krankenhäuser
    VA:  1, // Vertragsärzte
};


export const GHOST_USER = 'GHOST_USER';

export const handbookUrl = {
    [KH]: 'https://www.unitrend.de/qsp_handbuch_kh.pdf',
    [ADM_KH]: 'https://www.unitrend.de/qsp_handbuch_kh.pdf',
    [VA]: 'https://www.unitrend.de/qsp_handbuch_va.pdf',
    [LAG_FK]: 'https://www.unitrend.de/qsp_handbuch_fk.pdf',
    default: 'https://www.unitrend.de/qsp_handbuch.pdf'
}
