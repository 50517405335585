import { UPDATE_POOL_REQUEST_REQUEST, updatePoolRequestSuccess, updatePoolRequestFailure } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function updatePoolRequestRequestEpic(action$, store$, { PoolRequestRepository }) {
    return action$.pipe(
        ofType(UPDATE_POOL_REQUEST_REQUEST),
        mergeMap(action => from(
            PoolRequestRepository.update( action.payload.id, action.payload.updateData )
                .then(( poolRequest ) => updatePoolRequestSuccess({ poolRequest }))
                .catch(error => updatePoolRequestFailure(error)),
        )),
    );
}
