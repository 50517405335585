export const FIND_FILES_SUCCESS = 'FIND_FILES_SUCCESS';

export const findFilesSuccess = ({ files, request, response }) => ({
    type: FIND_FILES_SUCCESS,
    payload: { files },
    meta: {
        request,
        response,
    }
});
