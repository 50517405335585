import {
    FIND_PROCESS_STATEMENT_RESULTS_REQUEST,
    findProcessStatementResultsSuccess,
    findProcessStatementResultsFailure
}                      from 'Redux/Action';
import { ofType }      from 'redux-observable';
import { from, EMPTY } from 'rxjs';
import { mergeMap }    from 'rxjs/operators';

export function findProcessStatementResultsEpic(action$, store$, { ProcessStatementRepository }) {
    return action$.pipe(
        ofType(FIND_PROCESS_STATEMENT_RESULTS_REQUEST),
        mergeMap(action => {
            const { id } = action.payload;

            if (!`${id}`.trim()) {
                return EMPTY;
            }

            return from(
                ProcessStatementRepository.findResultsById(id)
                    .then(({ statement, results }) => findProcessStatementResultsSuccess({ processStatement: statement, results }))
                    .catch(error => findProcessStatementResultsFailure(error)),
            );
        }),
    );
}
