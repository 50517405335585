export const ADD_ZIP_FILE = 'ADD_ZIP_FILE';

export const addZipFile = ({ name, url, id, modelType, base64File }) => ({
    type: ADD_ZIP_FILE,
    payload: {
        name,
        url,
        id,
        modelType,
        base64File,
    }
});
