import { linkHandler }           from 'ClientService';
import React                     from 'react';
import { useSelector }           from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { isLoggedIn }            from 'Redux/Query';
import { publicRoutes }          from 'AppConfig/Routes';

export const IsUserLoggedIn = () => {
    const location  = useLocation();
    const { pathname } = location;
    const isUserLoggedIn = useSelector(isLoggedIn);

    if(!isUserLoggedIn && pathname.length < 60 && !Object.values(publicRoutes).map(route => route.path).includes(pathname) ) {
        return <Navigate to={ linkHandler.get('signin') } />
    }

    if(!isUserLoggedIn || (isUserLoggedIn && pathname !== linkHandler.get('signin'))) {
        return null;
    }
    return <Navigate to={'/'} />
}
