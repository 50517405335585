export default class Option {

    constructor( data  ) {

        this.id           = data.id;
        this.name         = data.name;
        this.value        = data.value;

    }

}
