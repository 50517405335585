import CareProvider from "../CareProvider/CareProvider";
import Module from "../Module/Module";

export default class Pseudonym {

    constructor( data  ) {

        this.id           = data.id;
        this.createdAt    = data.createdAt;
        this.careProvider = data.careProvider;
        this.module       = data.module;
        this.key          = data.key;
        this.validSince   = data.validSince;
        this.validTo      = data.validTo;
        this.careProvider = data.careProvider ? new CareProvider(data.careProvider) : {};
        this.module       = data.module       ? new Module(data.module) : {};
    }

}
