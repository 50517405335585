import { LAG_FK, LAG_GS }           from 'AppConfig/Config';
import {
    CHECK_RESULT_RATING_MAIL_REQUEST,
    checkResultRatingMailSuccess,
    checkResultRatingMailFailure,
}                                   from 'Redux/Action';
import { ofType }                   from 'redux-observable';
import { userContainsRole }         from 'Redux/Query';
import { EMPTY, from }              from 'rxjs';
import { mergeMap, withLatestFrom } from 'rxjs/operators';

export function checkResultRatingMailEpic(action$, store$, { ResultRepository }) {
    return action$.pipe(
        ofType(CHECK_RESULT_RATING_MAIL_REQUEST),
        withLatestFrom(store$),
        mergeMap(([ action, store ]) => {
            if(!userContainsRole(store)([ LAG_FK, LAG_GS ])) {
                return EMPTY;
            }
            return from(
                ResultRepository.checkResultRatingMail()
                    .then(allowToSendMail => checkResultRatingMailSuccess(allowToSendMail))
                    .catch(error => checkResultRatingMailFailure(error)),
            );
        }),
    );
}
