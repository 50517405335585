import {
    FIND_RESULTS_FAILURE,
    FIND_RESULT_FAILURE,
    UPLOAD_RESULTS_FAILURE,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isResultFailed = (state) => ArrayContains(state.result.failedRequests, [
    FIND_RESULTS_FAILURE,
    FIND_RESULT_FAILURE,
    UPLOAD_RESULTS_FAILURE,
]);