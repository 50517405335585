import { FIND_CHAT_CONVERSATIONS_REQUEST, findChatConversationsSuccess, findChatConversationsFailure } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function findChatConversationsEpic(action$, store$, { ChatRepository }) {
    return action$.pipe(
        ofType( FIND_CHAT_CONVERSATIONS_REQUEST ),
        mergeMap(action => from(
            ChatRepository.findAll(action.payload)
                .then(({ conversations, meta }) => findChatConversationsSuccess({ conversations, response: meta, request: action}))
                .catch(error => findChatConversationsFailure({ error, request: action })),
        )),
    );
}
