import {
    FIND_EDOK_FORM_REQUEST,
    FIND_EDOK_FORMS_REQUEST,
    CREATE_EDOK_FORM_REQUEST,
    UPDATE_EDOK_FORM_REQUEST,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isEdokLoading = (state) =>
    ArrayContains(state.edok.openRequests, [
        FIND_EDOK_FORM_REQUEST,
        FIND_EDOK_FORMS_REQUEST,
        CREATE_EDOK_FORM_REQUEST,
        UPDATE_EDOK_FORM_REQUEST,
    ]);