import {
    AUTHENTICATE_SUCCESS,
    findModulesRequest,
    findResultRatingsRequest,
    findEmployeeRolesRequest,
    getPoolRequestInboxRequest,
    getAnswerInboxProcessStatementCountRequest,
    getEncryptedPseudonymsRequest,
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { ADM, DAS_ALL, LAG_FK, LAG_GS, KH, VA, ADM_KH } from "AppConfig/Config";
import { userContainsRole } from "Redux/Query";

export function initializeLoadEpic(action$, store$, { UserRepository }) {
    return action$.pipe(
        ofType(AUTHENTICATE_SUCCESS),
        withLatestFrom(store$),
        mergeMap(([ action, store ]) => {
            const actions = [];

            actions.push( findEmployeeRolesRequest({}) );

            if(userContainsRole(store)([ ADM, ADM_KH, ...DAS_ALL, LAG_FK, LAG_GS ])) {
                actions.push( findModulesRequest({}) );
            }

            if(userContainsRole(store)([ LAG_GS ])) {
                actions.push( getEncryptedPseudonymsRequest() );
            }

            if(userContainsRole(store)([ ADM, ...DAS_ALL, LAG_FK, LAG_GS, KH, VA, ADM_KH ])) {
                actions.push( findResultRatingsRequest({}) );
            }

            actions.push( getPoolRequestInboxRequest() );
            actions.push( getAnswerInboxProcessStatementCountRequest() );

            return from(actions);

        }),
    );
}
