import GlobalState from 'Redux/Store/Global/GlobalState';
import {

    DASHBOARD_PAGE_APPEAR,
    FILE_DETAIL_PAGE_APPEAR,
    PROFILE_PAGE_APPEAR,
    EMPLOYEE_DETAIL_PAGE_APPEAR,
    EMPLOYEE_OVERVIEW_PAGE_APPEAR,
    CARE_PROVIDER_OVERVIEW_PAGE_APPEAR,
    CARE_PROVIDER_DETAIL_PAGE_APPEAR,
    DOCUMENT_UPLOAD_PAGE_APPEAR,
    DOCUMENT_DETAIL_PAGE_APPEAR,
    RESULTS_OVERVIEW_PAGE_APPEAR,
    RESULTS_DETAIL_PAGE_APPEAR,
    POOL_REQUEST_OVERVIEW_PAGE_APPEAR,
    POOL_REQUEST_DETAIL_PAGE_APPEAR,
    SEND_POOL_REQUEST_PAGE_APPEAR,
    PROCESS_STATEMENT_OVERVIEW_PAGE_APPEAR,
    RESULT_PROCESS_STATEMENT_DETAIL_PAGE_APPEAR,
    PSEUDONYM_OVERVIEW_PAGE_APPEAR,
    PSEUDONYM_DETAIL_PAGE_APPEAR,
    SETTINGS_DETAIL_PAGE_APPEAR,
    POOL_REQUEST_INBOX_PAGE_APPEAR,
    UPDATE_POOL_REQUEST_PAGE_APPEAR,
    POOL_REQUEST_ANSWERED_INBOX_PAGE_APPEAR,
    TARGET_ACTUAL_OVERVIEW_PAGE_APPEAR,
    REPORT_OVERVIEW_PAGE_APPEAR,
    EDOK_OVERVIEW_PAGE_APPEAR,
    EDOK_DETAIL_PAGE_APPEAR,
    CHAT_DETAIL_PAGE_APPEAR,
    CHAT_OVERVIEW_PAGE_APPEAR,
} from "Page/Page";

import {
    LOGOUT_USER,
    DISABLE_USER_SUCCESS,
    APPLICATION_BOOTSTRAPPED,
    AUTHENTICATE_FAILURE,

    GET_SETTINGS_FAILURE,
    GET_SETTINGS_SUCCESS,

    SET_TEMP_STORE_DATA,
    CLEAR_TEMP_STORE_DATA,


} from "Redux/Action";

const initialState = GlobalState.getInitialState();

export const globalReducer = (state = initialState, action) => {

    switch (action.type) {

    case AUTHENTICATE_FAILURE :
    case DISABLE_USER_SUCCESS :
    case LOGOUT_USER :
        return {
            ...initialState,
        };


    case SET_TEMP_STORE_DATA:
        return {
            ...state,
            updatedAt: Date.now(),
            temporaryData: {
                ...state.temporaryData,
                [action.payload.identifier]: action.payload.data,
            }
        };

    case CLEAR_TEMP_STORE_DATA:
        return {
            ...state,
            updatedAt: Date.now(),
            temporaryData: {
                ...state.temporaryData,
                [action.payload.identifier]: action.payload.data,
            }
        };

    case FILE_DETAIL_PAGE_APPEAR:
    case DASHBOARD_PAGE_APPEAR:
        return {
            ...state,
            updatedAt: Date.now(),
            activeSideMenuTab: 'dashboard',
            breadcrumb: []
        };

    case DOCUMENT_DETAIL_PAGE_APPEAR:
    case DOCUMENT_UPLOAD_PAGE_APPEAR:
        return {
            ...state,
            updatedAt: Date.now(),
            activeSideMenuTab: 'documentUpload',
            breadcrumb: []
        };

    case EMPLOYEE_DETAIL_PAGE_APPEAR:
    case EMPLOYEE_OVERVIEW_PAGE_APPEAR:
        return {
            ...state,
            updatedAt: Date.now(),
            activeSideMenuTab: 'employeeList',
            breadcrumb: []
        };

    case EDOK_OVERVIEW_PAGE_APPEAR:
    case EDOK_DETAIL_PAGE_APPEAR:
        return {
            ...state,
            updatedAt: Date.now(),
            activeSideMenuTab: 'edokList',
            breadcrumb: []
        };

    case CHAT_OVERVIEW_PAGE_APPEAR:
    case CHAT_DETAIL_PAGE_APPEAR:
        return {
            ...state,
            updatedAt: Date.now(),
            activeSideMenuTab: 'chatOverview',
            breadcrumb: []
        };

    case CARE_PROVIDER_DETAIL_PAGE_APPEAR:
    case CARE_PROVIDER_OVERVIEW_PAGE_APPEAR:
        return {
            ...state,
            updatedAt: Date.now(),
            activeSideMenuTab: 'careProviderList',
            breadcrumb: []
        };

    case REPORT_OVERVIEW_PAGE_APPEAR:
        return {
            ...state,
            updatedAt: Date.now(),
            activeSideMenuTab: 'reportOverview',
            breadcrumb: []
        };

    case PSEUDONYM_OVERVIEW_PAGE_APPEAR:
    case PSEUDONYM_DETAIL_PAGE_APPEAR:
        return {
            ...state,
            updatedAt: Date.now(),
            activeSideMenuTab: 'pseudonymList',
            breadcrumb: []
        };

    case SEND_POOL_REQUEST_PAGE_APPEAR:
    case POOL_REQUEST_DETAIL_PAGE_APPEAR:
    case POOL_REQUEST_OVERVIEW_PAGE_APPEAR:
    case RESULTS_OVERVIEW_PAGE_APPEAR:
    case POOL_REQUEST_INBOX_PAGE_APPEAR:
    case UPDATE_POOL_REQUEST_PAGE_APPEAR:
    case RESULTS_DETAIL_PAGE_APPEAR:
        return {
            ...state,
            updatedAt: Date.now(),
            activeSideMenuTab: 'resultsOverview',
            breadcrumb: []
        };

    case RESULT_PROCESS_STATEMENT_DETAIL_PAGE_APPEAR:
    case POOL_REQUEST_ANSWERED_INBOX_PAGE_APPEAR:
    case PROCESS_STATEMENT_OVERVIEW_PAGE_APPEAR:
        return {
            ...state,
            updatedAt: Date.now(),
            activeSideMenuTab: 'processStatementOverview',
            breadcrumb: []
        };

    case TARGET_ACTUAL_OVERVIEW_PAGE_APPEAR:
        return {
            ...state,
            updatedAt: Date.now(),
            activeSideMenuTab: 'targetActualList',
            breadcrumb: []
        };


    case PROFILE_PAGE_APPEAR:
        return {
            ...state,
            updatedAt: Date.now(),
            activeSideMenuTab: 'profile',
            breadcrumb: []
        };


    case SETTINGS_DETAIL_PAGE_APPEAR:
        return {
            ...state,
            updatedAt: Date.now(),
            activeSideMenuTab: 'settings',
            breadcrumb: []
        };

    case APPLICATION_BOOTSTRAPPED:
        return {
            ...state,
            updatedAt: Date.now(),
        };

    case GET_SETTINGS_SUCCESS:
        return {
            ...state,
            networkError: false,
            updatedAt: Date.now(),
        };

    case GET_SETTINGS_FAILURE:
        return {
            ...state,
            networkError: true,
            updatedAt: Date.now(),
        };

    default:
        return state;
    }
};
