import {
    FIND_CHAT_CONVERSATION_PARTNERS_REQUEST,
    findChatConversationPartnersSuccess,
    findChatConversationPartnersFailure
} from 'Redux/Action';
import { ofType }      from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap }    from 'rxjs/operators';

export function findChatConversationPartnersEpic(action$, store$, { ChatRepository }) {
    return action$.pipe(
        ofType(FIND_CHAT_CONVERSATION_PARTNERS_REQUEST),
        mergeMap( () =>{
            return from(
                ChatRepository.findChatConversationPartners()
                    .then(conversationPartners => findChatConversationPartnersSuccess({ conversationPartners }))
                    .catch(error => findChatConversationPartnersFailure(error)),
            );
        }),
    );
}
