import {
    FIND_STATEMENT_TEMPLATE_BY_INDICATOR_ID_REQUEST,
    findStatementTemplateByIndicatorIdSuccess,
    findStatementProcessByIndicatorIdFailure,
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import StatementTemplate from "Domain/Model/StatementTemplate/StatementTemplate";
import { createUniqKey } from "ClientService";

export function findStatementTemplateByIndicatorIdEpic(action$, store$, { StatementTemplateRepository }) {
    return action$.pipe(
        ofType( FIND_STATEMENT_TEMPLATE_BY_INDICATOR_ID_REQUEST ),
        mergeMap( action => from(StatementTemplateRepository.findByIndicatorId(action.payload.indicatorId))
            .pipe(
                mergeMap( statementTemplate => {
                    return from ([
                        findStatementTemplateByIndicatorIdSuccess(statementTemplate),
                    ]);
                }),
                catchError( error => {
                    if(error.data.message_code === 'STATEMENT_TEMPLATE_NOT_FOUND') {
                        return from ([
                            findStatementTemplateByIndicatorIdSuccess(new StatementTemplate({ id: `_new_${createUniqKey()}`, indicatorId: action.payload.indicatorId, template: '' })),
                        ]);
                    }

                    return from([
                        findStatementProcessByIndicatorIdFailure(error),
                    ])
                })
            )
        ),
    );
}
