export const FIND_CHAT_CONVERSATIONS_REQUEST = 'FIND_CHAT_CONVERSATIONS_REQUEST';

export const findChatConversationsRequest = ({
     filter = [],
     sorting = { field: 'lastResponse', order: 'DESC' },
     pagination = { page: 1, take: 50 },
 }) => ({
    type: FIND_CHAT_CONVERSATIONS_REQUEST,
    payload: { filter, sorting, pagination },
    meta: {
        sorting,
        pagination,
    }
});
