import { KH, VA } from 'AppConfig/Config';

export const createFormDataStructure = ({ elements, userRole, careProvider, formData }) => {
    const formDataStructure     = {};
    const requiredFields        = {};
    let editable                = [VA, KH].includes(userRole);

    elements.forEach(({ fieldName, required, ...element }) => {
        if(!fieldName || `${fieldName}`.trim() === '') { return; }

        formDataStructure[fieldName] = { value: formData[fieldName]?.value !== undefined ? formData[fieldName].value : null, editable, _visible: true };
        switch(element.datatype) {
            case 3 : {
                let value = formDataStructure[fieldName].value;
                switch(value) {
                    case '1' :
                    case true :
                        value = '1';
                        break;
                    case '0' :
                    case false :
                        value = '0';
                        break;
                    default :
                        value = null;
                        break;

                }
                formDataStructure[fieldName].value = value;
                break;
            }
        }
        if(required) {
            requiredFields[fieldName] = { ...element, fieldName, required };
        }

        if(fieldName === 'STATUSLEA') {
            formDataStructure[fieldName] = { value: userRole === KH ? 1 : userRole === VA ? 2 : formData[fieldName]?.value || null, editable: false, _visible: true };
        }

        if(['IKNRKH', 'BSNRAMBULANT'].includes(fieldName)) {
            formDataStructure[fieldName] = { value: formData[fieldName]?.value || null, editable: false, _visible: true };

            if(fieldName === 'IKNRKH' && userRole === KH) {
                formDataStructure[fieldName].value = careProvider.identNumber;
            }
            if(fieldName === 'BSNRAMBULANT' && userRole === VA) {
                formDataStructure[fieldName].value = careProvider.identNumber;
            }
        }
    });



    return { formDataStructure, requiredFields };
}
