import { FIND_EMPLOYEE_ROLES_REQUEST, findEmployeeRolesFailure, findEmployeeRolesSuccess} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import isEmpty from "lodash/isEmpty";

export function findEmployeeRolesEpic(action$, store$, { EmployeeRoleRepository }) {
    return action$.pipe(
        ofType( FIND_EMPLOYEE_ROLES_REQUEST ),
        withLatestFrom(store$),
        mergeMap(([ action, store ]) => {
            const { entries } = store.employeeRole.employeeRoles;
            if(!isEmpty(entries)) {
                return of(findEmployeeRolesSuccess({ entries }));
            }

            return from(
                EmployeeRoleRepository.findAll(action.payload)
                    .then(({ employeeRoles, meta }) => findEmployeeRolesSuccess({ employeeRoles, response: meta, request: action}))
                    .catch(error => findEmployeeRolesFailure({ error, request: action })),
            )
        }),
    );
}
