
export default class Module {

    constructor( data  ) {

        this.id           = data.id;
        this.name         = data.name;
        this.procedure    = data.procedure;
        this.description  = data.description;

    }

}
