import {
    UPDATE_STATEMENT_PROCESS_REQUEST,
    updateStatementProcessSuccess,
    updateStatementProcessFailure,
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { message } from "antd";

export function updateProcessStatementRequestEpic(action$, store$, { ProcessStatementRepository }) {
    return action$.pipe(
        ofType(UPDATE_STATEMENT_PROCESS_REQUEST),
        mergeMap( action => from(ProcessStatementRepository.update(action.payload.id, action.payload.formData ))
            .pipe(
                mergeMap( result => {
                    message.success('Stellungnahme wurde erfolgreich gespeichert.');
                    return from ([
                        updateStatementProcessSuccess(result),
                    ]);
                }),
                catchError( error => {
                    return from([
                        updateStatementProcessFailure(error),
                    ])
                })
            )
        ),
    );
}
