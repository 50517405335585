import {
    UPLOAD_NOTICE_RESULTS_REQUEST,
    uploadNoticeResultsSuccess,
    uploadNoticeResultsFailure,
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { message } from "antd";

export function uploadNoticeResultsEpic(action$, store$, { ResultRepository }) {
    return action$.pipe(
        ofType(UPLOAD_NOTICE_RESULTS_REQUEST),
        mergeMap( action => from(ResultRepository.uploadNoticeResults( action.payload.formData ))
            .pipe(
                mergeMap(() => {
                    message.success('Die auffälligen Vorgangsnummern wurden erfolgreich hochgeladen');
                    return from([ uploadNoticeResultsSuccess() ]);
                }),
                catchError( error => from([ uploadNoticeResultsFailure(error) ]))
            )
        ),
    );
}
