import React, { Component } from 'react';
import { connect }          from 'react-redux';
import { Layout, message }  from 'antd';
import Header               from 'Application/Header/Header';
import Content              from 'Application/Content/Content';
import Footer               from 'Application/Footer/Footer';
import Sideboard            from 'Application/Sideboard/Sideboard';
import {
    isApplicationLoading,
    getWindowMode,
    getActiveMenuTab,
    isLoggedIn,
}                           from 'Redux/Query';


message.config({
    top: 50,
    duration: 5,
    maxCount: 5,
});

class App extends Component {

    render () {
        return (
            <Layout>
                <Sideboard />
                <Layout className="layoutWrapper" style={{ minHeight: '100vh' }}>
                    <Header />
                    <Content style={{ overflow: 'auto', flex: 1}}>
                        { this.props.children }
                    </Content>
                    <Footer />
                </Layout>
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    return {
        isLoggedIn: isLoggedIn(state),
        isLoading: isApplicationLoading(state),
        windowMode: getWindowMode(state),
        activeTab: getActiveMenuTab(state),
    };
}

export default connect(mapStateToProps)(App);
