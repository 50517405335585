import { REGISTER_REQUEST, registerSuccess, registerFailure } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function registerRequestEpic(action$, store$, { UserRepository }) {
    return action$.pipe(
        ofType(REGISTER_REQUEST),
        mergeMap(action => from(
            UserRepository.signup( action.payload )
                .then(() => registerSuccess())
                .catch(error => registerFailure(error)),
        )),
    );
}
