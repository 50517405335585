export const FIND_EMPLOYEES_SUCCESS = 'FIND_EMPLOYEES_SUCCESS';

export const findEmployeesSuccess = ({ employees, request, response }) => ({
    type: FIND_EMPLOYEES_SUCCESS,
    payload: { employees },
    meta: {
        request,
        response,
    }
});
