export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';

export const getSettingsSuccess = ({ settings, request, response }) => ({
    type: GET_SETTINGS_SUCCESS,
    payload: { settings },
    meta: {
        request,
        response,
    }
});
