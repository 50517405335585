import { LAG_FK }                                               from 'AppConfig/Config';
import { filterObjectStore, getUser, getUserRole, queryFilter } from 'Redux/Query';

export const getModules = (state, filter = null) => {
    if(filter === null) {
        filter = queryFilter({sorting: { by: 'createdAt', order: 'DESC' }})
    }
    const userRole = getUserRole(state);
    const modules = filterObjectStore(state.module.modules.entries, filter);
    if(userRole === LAG_FK) {
        const user =  getUser(state);
        const userModules = user.modules;

        return modules.filter(module => userModules.includes(module.name));
    }

    return modules;
};
