import { persistCombineReducers, persistStore } from 'redux-persist';
import storage                                  from 'redux-persist/lib/storage';
import { applyMiddleware, createStore }         from 'redux';
import { createEpicMiddleware }                 from 'redux-observable';
import logger                                   from 'redux-logger';
import Reducers                                 from 'Redux/Reducer';
import Epics                                    from 'Redux/Epic';
import Repositories                             from 'Domain/Repository';


const persistConfig = {
    key: 'unitrend-portal',
    storage,
    debug: true,

    whitelist: [ 'user', 'persistStore', 'table' ],
};

const epicMiddleware = createEpicMiddleware({
    dependencies: { ...Repositories },
});


export let Persistor;

export const rehydrateStore = storeToHydrate => new Promise((resolve, reject) => {
    Persistor = persistStore(storeToHydrate, null, () => {
        resolve();
    });
});

const middlewares = [
    epicMiddleware,
];

if(process.env.NODE_ENV === 'development') {
     middlewares.push(logger);
}

export const Store = createStore(
    persistCombineReducers(persistConfig, { ...Reducers }),
    applyMiddleware( ...middlewares ),
)


epicMiddleware.run(Epics);