import { filterObjectStore, queryFilter } from 'Redux/Query';

export const getProcessStatements = (state, filter = null) => {

    if(filter === null) {
        filter = queryFilter({sorting: { by: 'id', order: 'DESC' }})
    }

    return filterObjectStore(state.processStatement.processStatements.entries, filter);
};
