import BaseRepository from '../Base/BaseRepository';
import Option from 'Domain/Model/Settings/Option';

class SettingsRepository extends BaseRepository {

    findAll(filterSettings = {}) {
        return this.makeRequest({
            path: `/public/client/settings`,
            type: 'GET',
            callback: this.initializeSettings,
        });
    }

    loadProcessStatementRtf() {
        return this.makeRequest({
            path: `/admin/settings/process-statement-rtf`,
            type: 'GET',
            callback: ({ template }) => template
        });
    }

    updateProcessStatementRtf(template) {
        return this.makeRequest({
            path: `/admin/settings/process-statement-rtf`,
            type: 'POST',
            data: {
                template,
            },
            callback: ({ template }) => template
        });
    }

    update(updateData = {}) {
        return this.makeRequest({
            path: `/admin/settings`,
            type: 'PATCH',
            data: updateData,
            callback: this.initializeSettings,
        });
    }

    reloadSettings() {
        return this.makeRequest({
            path: `/admin/settings/reload`,
            type: 'GET',
            callback: this.initializeSettings,
        });
    }

    initializeSettings = (responseObject) => {
        if(responseObject.hasOwnProperty('settings')){
            return { settings: responseObject.settings };
        }

        return null;
    };

}

export default new SettingsRepository({
    model: Option,
    baseRoute: 'public',
    singular: 'option',
    plural: 'settings',
});
