import {
    UPDATE_COMMENT_BY_RESULT_REQUEST,
    updateCommentByResultSuccess,
    updateCommentByResultFailure,
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { message } from "antd";

export function updateCommentByResultEpic(action$, store$, { ResultRepository }) {
    return action$.pipe(
        ofType(UPDATE_COMMENT_BY_RESULT_REQUEST),
        mergeMap( action => from(ResultRepository.updateComment(action.payload.id, action.payload.updateData ))
            .pipe(
                mergeMap( result => {
                    message.success('Ergebnis-Kommentar wurde erfolgreich gespeichert.');
                    return from ([
                        updateCommentByResultSuccess(result),
                    ]);
                }),
                catchError( error => {
                    return from([
                        updateCommentByResultFailure(error),
                    ])
                })
            )
        ),
    );
}
