import { combineEpics } from 'redux-observable';

import { findFilesEpic } from './FindFiles/FindFilesEpic';
import { findFileEpic } from './FindFile/FindFileEpic';
import { downloadFileEpic } from './DownloadFile/DownloadFileEpic';
import { deleteFileEpic } from './DeleteFile/DownloadFileEpic';
import { downloadFilesAsZipEpic } from './DownloadFilesAsZip/DownloadFilesAsZipEpic';


export default combineEpics(
    findFilesEpic,
    findFileEpic,
    downloadFileEpic,
    deleteFileEpic,
    downloadFilesAsZipEpic
);
