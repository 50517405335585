import BaseRepository from '../Base/BaseRepository';
import CareProvider from 'Domain/Model/CareProvider/CareProvider';
import isEmpty from "lodash/isEmpty";

class CareProviderRepository extends BaseRepository {

    dePseudoCareProvider = (id) => {
        return this.makeRequest({
            path: `/${this.baseRoute}/de-pseudo/${id}`,
            type: 'GET',
            callback: this.initializeSingleModel
        });
    }

    massDePseudo = ({ year, module, forAllLagGs }) => {
        return this.makeRequest({
            path: `/${this.baseRoute}/mass-de-pseudo`,
            type: 'POST',
            data: { year, module, forAllLagGs },
            callback: ({ careProviders, resultIds }) => ({
                careProviders: this.initializeModels({ careProviders }),
                resultIds
            })
        });
    }

    findEncryptedPseudonyms = () => {
        return this.makeRequest({
            path: `/${this.baseRoute}/encrypted-pseudonym`,
            type: 'GET',
            callback: ({ careProviders, resultIds }) => ({
                careProviders: this.initializeModels({ careProviders }),
                resultIds
            })
        });
    }

    initializeModels = (responseObject) => {
        if(responseObject.hasOwnProperty('careProviders')){
            return { careProviders: this.iterate(responseObject.careProviders), meta: responseObject.meta };
        }

        return null;
    };

    initializeSingleModel = (response) => {
        if(isEmpty(response)) {
            return {};
        }
        if(!response.hasOwnProperty('careProvider')){
            return null;
        }

        const newModel  = this.initializeModel(response.careProvider);
        return { [newModel.id]: newModel };
    };
}

export default new CareProviderRepository({
    model: CareProvider,
    baseRoute: 'care-provider',
    singular: 'careProvider',
    plural: 'careProviders',
});
