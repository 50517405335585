export const FIND_DOCUMENTS_SUCCESS = 'FIND_DOCUMENTS_SUCCESS';

export const findDocumentsSuccess = ({ documents, request, response }) => ({
    type: FIND_DOCUMENTS_SUCCESS,
    payload: { documents },
    meta: {
        request,
        response,
    }
});
