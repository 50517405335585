import { UPDATE_POOL_REQUEST_SUCCESS, showNotification } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

export function updatePoolRequestSuccessEpic(action$) {
    return action$.pipe(
        ofType(UPDATE_POOL_REQUEST_SUCCESS),
        mergeMap(() => {

            return of(showNotification('Die Speicherung der Anfrage-Vorlage war erfolgreich'));
        })
    );
}
