import { RESET_PASSWORD_REQUEST, resetPasswordFailure, resetPasswordSuccess } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function resetPasswordRequestEpic(action$, store$, { UserRepository }) {
    return action$.pipe(
        ofType(RESET_PASSWORD_REQUEST),
        mergeMap(action => from(
            UserRepository.resetPassword({ password: action.payload.password, hash: action.payload.hash, confirmationHash: action.payload.confirmationHash })
                .then(() => resetPasswordSuccess())
                .catch(error => resetPasswordFailure(error)),
        )),
    );
}
