import { combineEpics } from 'redux-observable';

import { findReportsEpic }    from './FindReports/FindReportsEpic';
import { findReportEpic }     from './FindReport/FindReportEpic';
import { downloadReportEpic } from './DownloadReport/DownloadReportEpic';


export default combineEpics(
    findReportsEpic,
    findReportEpic,
    downloadReportEpic,
);
