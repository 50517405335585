import { combineEpics } from 'redux-observable';

import { updateProcessStatementRequestEpic }          from './UpdateProcessStatementRequest/UpdateProcessStatementRequestEpic';
import { updateProcessStatementsEpic }                from './UpdateProcessStatements/UpdateProcessStatementsEpic';
import { findProcessStatementsEpic }                  from './FindProcessStatements/FindProcessStatementsEpic';
import { findOpenProcessStatementsEpic }              from './FindOpenProcessStatements/FindOpenProcessStatementsEpic';
import { findProcessStatementEpic }                   from './FindProcessStatement/FindProcessStatementEpic';
import { findOpenProcessStatementEpic }               from './FindOpenProcessStatement/FindOpenProcessStatementEpic';
import { updateOpenProcessStatementEpic }             from './UpdateOpenProcessStatement/UpdateOpenProcessStatementEpic';
import { findOpenAnswerProcessStatementsEpic }        from './FindOpenAnswerProcessStatements/FindOpenAnswerProcessStatementsEpic';
import { getAnswerInboxProcessStatementCountEpic }    from './GetAnswerInboxProcessStatementCount/GetAnswerInboxProcessStatementCountEpic';
import { updateAsReadProcessStatementEpic }           from './UpdateAsReadProcessStatement/UpdateAsReadProcessStatementEpic';
import { deleteProcessStatementEpic }                 from './DeleteProcessStatement/DeleteProcessStatementEpic';
import { dismissAnswerFromProcessStatementEpic }      from './DismissAnswerFromProcessStatement/DismissAnswerFromProcessStatementEpic';
import { findProcessStatementResultsEpic }            from './FindProcessStatementResults/FindProcessStatementResultsEpic';
import { submitProcessStatementEpic }                 from './SubmitProcessStatement/SubmitProcessStatementEpic';
import { getLastDeadlineDatesEpic }                   from './GetLastDeadlineDates/GetLastDeadlineDatesEpic';
import { downloadProcessStatementRtfFileRequestEpic } from 'Redux/Epic/ProcessStatement/DownloadProcessStatementRtfFile/DownloadProcessStatementRtfFileRequestEpic';
import { downloadProcessStatementRtfFileSuccessEpic } from 'Redux/Epic/ProcessStatement/DownloadProcessStatementRtfFile/DownloadProcessStatementRtfFileSuccessEpic';
import { loadProcessStatementRtfEpic }                from './LoadProcessStatementRtf/LoadProcessStatementRtfEpic';
import { updateProcessStatementRtfEpic }              from './UpdateProcessStatementRtf/UpdateProcessStatementRtfEpic';

export default combineEpics(
    updateProcessStatementRequestEpic,
    updateProcessStatementsEpic,
    updateOpenProcessStatementEpic,
    findProcessStatementsEpic,
    findOpenProcessStatementsEpic,
    findProcessStatementEpic,
    findOpenProcessStatementEpic,
    findOpenAnswerProcessStatementsEpic,
    getAnswerInboxProcessStatementCountEpic,
    updateAsReadProcessStatementEpic,
    deleteProcessStatementEpic,
    dismissAnswerFromProcessStatementEpic,
    findProcessStatementResultsEpic,
    submitProcessStatementEpic,
    getLastDeadlineDatesEpic,
    downloadProcessStatementRtfFileRequestEpic,
    downloadProcessStatementRtfFileSuccessEpic,
    loadProcessStatementRtfEpic,
    updateProcessStatementRtfEpic,
);
