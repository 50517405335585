import { message }                              from 'antd';
import { LAG_FK, LAG_GS }                       from 'AppConfig/Config';
import {
    SEND_RESULT_RATING_MAIL_REQUEST,
    sendResultRatingMailSuccess,
    sendResultRatingMailFailure
}                                               from 'Redux/Action';
import { ofType }                               from 'redux-observable';
import { userContainsRole }                     from 'Redux/Query';
import { EMPTY, from }                          from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';

export function sendResultRatingMailEpic(action$, store$, { ResultRepository }) {
    return action$.pipe(
        ofType(SEND_RESULT_RATING_MAIL_REQUEST),
        withLatestFrom(store$),
        mergeMap(([ action, store ]) => {
            if (!userContainsRole(store)([ LAG_FK, LAG_GS ])) {
                return EMPTY;
            }
            return from(ResultRepository.sendResultRatingMail({ module: action.payload.module })).pipe(
                mergeMap((allowToSendMail) => {
                    message.success('Bewertungen wurden erfolgreich versendet');
                    return from([
                        sendResultRatingMailSuccess({ allowToSendMail }),
                    ]);
                }),
                catchError(error => {
                    return from([
                        sendResultRatingMailFailure(error),
                    ]);
                })
            );
        }),
    );
}
