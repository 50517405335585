export const AUTHENTICATE_SUCCESS = 'AUTHENTICATE_SUCCESS';

export const authenticateSuccess = ({ user, authentication = null, role = null }) => ({
    type: AUTHENTICATE_SUCCESS,
    payload: {
        user,
        authentication,
        role,
    },
});
