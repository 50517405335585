import { combineEpics } from 'redux-observable';

import { findEmployeeRolesEpic } from './FindEmployeeRoles/FindEmployeeRolesEpic';
import { findEmployeeRoleEpic } from './FindEmployeeRole/FindEmployeeRoleEpic';


export default combineEpics(
    findEmployeeRolesEpic,
    findEmployeeRoleEpic,
);
