import {
    CREATE_STATEMENT_TEMPLATE_REQUEST,
    createStatementTemplateSuccess,
    createStatementTemplateFailure,
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { message } from "antd";

export function createStatementTemplateEpic(action$, store$, { StatementTemplateRepository }) {
    return action$.pipe(
        ofType(CREATE_STATEMENT_TEMPLATE_REQUEST),
        mergeMap( action => from(StatementTemplateRepository.create(action.payload))
            .pipe(
                mergeMap( statementTemplate => {
                    message.success('Der Textbaustein wurde erfolgreich gespeichert.');
                    return from ([
                        createStatementTemplateSuccess(statementTemplate),
                    ]);
                }),
                catchError( error => {
                    return from([
                        createStatementTemplateFailure(error),
                    ])
                })
            )
        ),
    );
}
