import { combineEpics }     from 'redux-observable';

import { findEdokFormsEpic }            from './FindEdokForms/FindEdokFormsEpic';
import { findEdokFormEpic }             from './FindEdokForm/FindEdokFormEpic';
import { createEdokFormEpic }           from './CreateEdokForm/CreateEdokFormEpic';
import { updateEdokFormEpic }           from './UpdateEdokForm/UpdateEdokFormEpic';
import { getCurrentEdokYearEpic }       from './GetCurrentEdokYear/GetCurrentEdokYearEpic';
import { getAvailableEdokModulesEpic }  from './GetAvailableEdokModules/GetAvailableEdokModulesEpic';
import { getEdokFormByModuleEpic }      from './GetEdokFormByModule/GetEdokFormByModuleEpic';
import { sendEdokFormEpic }             from './SendEdokForm/SendEdokFormEpic';
import { findEdokDocumentationsEpic }   from './FindEdokDocumentations/FindEdokDocumentationsEpic';
import { updateEdokDocumentationsEpic } from './UpdateEdokDocumentations/UpdateEdokDocumentationsEpic';


export default combineEpics(
    findEdokFormsEpic,
    findEdokFormEpic,
    createEdokFormEpic,
    updateEdokFormEpic,
    getCurrentEdokYearEpic,
    getAvailableEdokModulesEpic,
    getEdokFormByModuleEpic,
    sendEdokFormEpic,
    findEdokDocumentationsEpic,
    updateEdokDocumentationsEpic,
);
