import { ruleParser, transformConditionText } from 'ClientService/EdokUtility';

/**
 * datatypes:
 *  // Eingabetypen
 *  1 => ganze Zahl (integer)
 *  2 => aufzählung (enum)
 *  3 => Ja/Nein/leer
 *  4 => Ja/leer
 *  5 => Monatsdatum
 *  // Struktur
 *  6 => H1
 *  7 => H2
 *  8 => Alle weiteren Einträge einrücken bis aufgehoben wird mit datatype:9
 *  9 => letzte Einrückung aufheben
 */

export default class NwieForm {

    constructor( data  ) {

        this.id               = data.id;
        this.year             = data.year;
        this.createdAt        = data.createdAt;
        this.employee         = data.employee;
        this.elements         = this.parseElements(data.elements);
        this.module           = data.module;
        this.identNumber      = data.identNumber;
        this.state            = data.state;
        this.year             = data.year;
        this.careProviderId   = data.careProviderId;
        this.documentationUrl = data.documentationUrl;
        this.rules            = !!data.rules?.length ? ruleParser(data.rules) : [];
        this.groups           = [];

        if(data.groups) {
            this.groups = data.groups.map(group => (
                { ...group, rawCondition: group.condition, ...transformConditionText(group.condition) }
            ))
        }

        const answers = {};
        Object.keys(data.answers ||[]).forEach(fieldName => {
            answers[fieldName] = { value: data.answers[fieldName], editable: true, _visible: true };
        })

        this.answers = answers;

    }

    parseElements(elements) {
        const byRow = {};

        const getByDataType = (elements, datatype) => {
            return elements.filter((el, index) => {
                if(el.datatype !== datatype) {
                    return false;
                }
                el._index = index;
                return true;
            })
        }

        const appendByDataType = (elements, datatype) => {
            let newOrderedElements = [ ...elements ];

            const matchedElements = getByDataType(newOrderedElements, datatype);
            if(!!matchedElements.length) {
                newOrderedElements = [ ...matchedElements.map(({ _index, ...el }) => {
                    newOrderedElements[_index] = null;
                    return el;
                }), ...newOrderedElements.filter(el => !!el)];
            }

            return newOrderedElements;
        }

        elements.forEach(el => {
            const { rowNumber } = el;
            const [row, col] = rowNumber.split('.');
            if(!byRow[row]) { byRow[row] = []; }
            byRow[row].push({ ...el, row, col });
        });

        const newOrderedElements = [];

        let indent = 0;
        for(const row in byRow) {
            byRow[row] = appendByDataType(byRow[row], 8);
            byRow[row] = appendByDataType(byRow[row], 7);
            byRow[row] = appendByDataType(byRow[row], 6);

            byRow[row].forEach(el => {
                if(el.datatype === 8) { indent++; }
                if(el.datatype === 9) { indent--; }

                newOrderedElements.push({ ...el, indent });
            })
        }

        const cleanUpElements = [];
        let parentElements    = [];

        newOrderedElements.forEach(element => {
            if([6,7,8].includes(element.datatype)){
                return parentElements.push(element);
            }
            if(element.datatype === 9) { return }

            cleanUpElements.push({ ...element, parents: parentElements});
            parentElements  = [];
        })

        return cleanUpElements;
    }

}
