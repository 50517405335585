import {
    GET_ENCRYPTED_PSEUDONYMS_REQUEST,
    getEncryptedPseudonymsSuccess,
    getEncryptedPseudonymsFailure
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from, EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function findEncryptedPseudonymsEpic(action$, store$, { CareProviderRepository }) {
    return action$.pipe(
        ofType(GET_ENCRYPTED_PSEUDONYMS_REQUEST),
        mergeMap( () => from(
            CareProviderRepository.findEncryptedPseudonyms()
                .then(({ careProviders, resultIds }) => getEncryptedPseudonymsSuccess(careProviders, resultIds))
                .catch(error => getEncryptedPseudonymsFailure(error)),
        )),
    );
}
