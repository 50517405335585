import {
    DELETE_DOCUMENT_REQUEST,
    deleteDocumentSuccess,
    deleteDocumentFailure,
    showNotification
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';

export const deleteDocumentEpic = (action$, store$, { DocumentRepository }) => {
    return action$.pipe(
        ofType(DELETE_DOCUMENT_REQUEST),
        mergeMap(action => from(DocumentRepository.remove( action.payload.id ))
            .pipe(
                mergeMap(() => {
                    return from([
                        showNotification(`Das Dokument wurde erfolgreich gelöscht.`),
                        deleteDocumentSuccess(action.payload.id),
                    ]);
                }),
                catchError( error => {
                    return from([
                        deleteDocumentFailure(error),
                    ])
                })
            )
        ),
    );
}
