import {
    UPDATE_PSEUDONYM_REQUEST,
    updatePseudonymSuccess,
    updatePseudonymFailure,
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { message } from "antd";

export function updatePseudonymRequestEpic(action$, store$, { PseudonymRepository }) {
    return action$.pipe(
        ofType(UPDATE_PSEUDONYM_REQUEST),
        mergeMap( action => from(PseudonymRepository.update( action.payload.id, action.payload.updateData ))
            .pipe(
                mergeMap((pseudonym) => {
                    message.success('Das Pseudonym wurde erfolgreich geändert.');
                    return from([
                        updatePseudonymSuccess({ pseudonym }),
                    ]);
                }),
                catchError( error => {
                    return from([
                        updatePseudonymFailure(error),
                    ])
                })
            )
        ),
    );
}