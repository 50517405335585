import {
    CREATE_POOL_REQUEST_FAILURE,
    UPDATE_POOL_REQUEST_FAILURE,
    FIND_POOL_REQUEST_FAILURE,
    FIND_POOL_REQUESTS_FAILURE,
    DELETE_POOL_REQUESTS_FAILURE,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isPoolRequestFailed = (state) => ArrayContains(state.poolRequest.failedRequests, [
    CREATE_POOL_REQUEST_FAILURE,
    UPDATE_POOL_REQUEST_FAILURE,
    FIND_POOL_REQUEST_FAILURE,
    FIND_POOL_REQUESTS_FAILURE,
    DELETE_POOL_REQUESTS_FAILURE,
]);