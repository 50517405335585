import { UPDATE_CARE_PROVIDER_SUCCESS, showNotification } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

export function updateCareProviderSuccessEpic(action$) {
    return action$.pipe(
        ofType(UPDATE_CARE_PROVIDER_SUCCESS),
        mergeMap(() => {

            return of(showNotification('Die Speicherung des Leistungserbringers war erfolgreich.'));
        })
    );
}
