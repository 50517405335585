import { GET_LAST_DEADLINE_DATES_REQUEST, getLastDeadlineDatesSuccess, getLastDeadlineDatesFailure } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function getLastDeadlineDatesEpic(action$, store$, { ProcessStatementRepository }) {
    return action$.pipe(
        ofType(GET_LAST_DEADLINE_DATES_REQUEST),
        mergeMap( action =>{
            return from(
                ProcessStatementRepository.getLastDeadlineDates( action.payload.proceeding )
                    .then(dates => getLastDeadlineDatesSuccess( dates ))
                    .catch(error => getLastDeadlineDatesFailure(error)),
            );
        }),
    );
}
