import { FIND_OPEN_PROCESS_STATEMENT_REQUEST, findOpenProcessStatementSuccess, findOpenProcessStatementFailure } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from, EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function findOpenProcessStatementEpic(action$, store$, { ProcessStatementRepository }) {
    return action$.pipe(
        ofType(FIND_OPEN_PROCESS_STATEMENT_REQUEST),
        mergeMap( action =>{
            const { id } = action.payload;

            if(!`${id}`.trim()) {
                return EMPTY;
            }

            return from(
                ProcessStatementRepository.findOpenById( id )
                    .then(statement => findOpenProcessStatementSuccess( statement ))
                    .catch(error => findOpenProcessStatementFailure(error)),
            );
        }),
    );
}
