export const UPDATE_SERVER_SETTINGS_SUCCESS = 'UPDATE_SERVER_SETTINGS_SUCCESS';

export const updateServerSettingsSuccess = ({ settings, request, response }) => ({
    type: UPDATE_SERVER_SETTINGS_SUCCESS,
    payload: { settings },
    meta: {
        request,
        response,
    }
});
