import { combineEpics } from 'redux-observable';

import { findDocumentsEpic }    from './FindDocuments/FindDocumentsEpic';
import { findDocumentEpic }     from './FindDocument/FindDocumentEpic';
import { deleteDocumentEpic }   from './DeleteDocument/DeleteDocumentEpic';
import { downloadDocumentEpic } from './DownloadDocument/DownloadDocumentEpic';
import { uploadDocumentEpic }   from './UploadDocument/UploadDocumentEpic';


export default combineEpics(
    findDocumentsEpic,
    findDocumentEpic,
    deleteDocumentEpic,
    downloadDocumentEpic,
    uploadDocumentEpic,
);
