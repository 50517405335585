export const FIND_CARE_PROVIDERS_SUCCESS = 'FIND_CARE_PROVIDERS_SUCCESS';

export const findCareProvidersSuccess = ({ careProviders, request, response }) => ({
    type: FIND_CARE_PROVIDERS_SUCCESS,
    payload: { careProviders },
    meta: {
        request,
        response,
    }
});
