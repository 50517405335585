import {
    FIND_RESULTS_REQUEST,
    findResultsSuccess,
    findResultsFailure,
    checkResultRatingMailRequest,
} from 'Redux/Action';
import { ofType }               from 'redux-observable';
import { from, of }             from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

export function findResultsEpic(action$, store$, { ResultRepository }) {
    return action$.pipe(
        ofType( FIND_RESULTS_REQUEST ),
        mergeMap(action => {
            return from(ResultRepository.findAll(action.payload)).pipe(
                mergeMap(( { results, meta } ) => {
                    return from([
                        findResultsSuccess({ results, response: meta, request: action}),
                        checkResultRatingMailRequest(),
                    ]);
                }),
                catchError(error => of(findResultsFailure({ error, request: action })))
            )
        })
    );
}
