import { FIND_DOCUMENTS_REQUEST, findDocumentsSuccess, findDocumentsFailure} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function findDocumentsEpic(action$, store$, { DocumentRepository }) {
    return action$.pipe(
        ofType( FIND_DOCUMENTS_REQUEST ),
        mergeMap(action => from(
            DocumentRepository.findAll(action.payload)
                .then(({ documents, meta }) => findDocumentsSuccess({ documents, response: meta, request: action}))
                .catch(error => findDocumentsFailure({ error, request: action })),
        )),
    );
}
