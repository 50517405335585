const getInitialState = () => ({
    openRequests: [],
    failedRequests: [],
    updatedAt: 0,
    reports: {
        entries: {},
        maxCount: 0,
    },
    lastInteractiveGuid: null,
});

export default {
    getInitialState
};
