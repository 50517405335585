import { combineEpics } from 'redux-observable';

import { findPoolRequestsEpic } from './FindPoolRequests/FindPoolRequestsEpic';
import { findPoolRequestEpic } from './FindPoolRequest/FindPoolRequestEpic';
import { createPoolRequestRequestEpic } from './CreatePoolRequest/CreatePoolRequestRequestEpic';
import { updatePoolRequestRequestEpic } from './UpdatePoolRequest/UpdatePoolRequestRequestEpic';
import { updatePoolRequestSuccessEpic } from './UpdatePoolRequest/UpdatePoolRequestSuccessEpic';
import { deletePoolRequestsEpic } from './DeletePoolRequests/DeletePoolRequestsEpic';
import { sendPoolRequestRequestEpic } from './SendPoolRequest/SendPoolRequestRequestEpic';
import { sendOpenPoolRequestRequestsEpic } from './SendOpenPoolRequests/SendOpenPoolRequestRequestsEpic';


export default combineEpics(
    findPoolRequestsEpic,
    findPoolRequestEpic,
    createPoolRequestRequestEpic,
    updatePoolRequestRequestEpic,
    updatePoolRequestSuccessEpic,
    deletePoolRequestsEpic,
    sendPoolRequestRequestEpic,
    sendOpenPoolRequestRequestsEpic,
);
