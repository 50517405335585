import { FIND_INDICATOR_IDS_REQUEST, findIndicatorIdsSuccess, findIndicatorIdsFailure } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function findIndicatorIdsEpic(action$, store$, { StatementTemplateRepository }) {
    return action$.pipe(
        ofType( FIND_INDICATOR_IDS_REQUEST ),
        mergeMap(action => from(
            StatementTemplateRepository.findAllIndicatorIds(action.payload)
                .then(( indicatorIds ) => findIndicatorIdsSuccess({ indicatorIds }))
                .catch(error => findIndicatorIdsFailure({ error, request: action })),
        )),
    );
}
