import { message as modal }                     from 'antd';
import {
    APPLICATION_BOOTSTRAPPED, authenticateSuccess,
    authenticateFailure, logoutUser, CHECK_USER_SESSION
}                                               from 'Redux/Action';
import { ofType }                               from 'redux-observable';
import { EMPTY, from, of }                      from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';

export function checkSessionRequestEpic(action$, store$, { UserRepository }) {
    return action$.pipe(
        ofType(APPLICATION_BOOTSTRAPPED, CHECK_USER_SESSION),
        withLatestFrom(store$),
        mergeMap(([ _, store ]) => {
            const { user, authentication } = store.user;

            const now = new Date();
            const { accessToken, accessTokenExpiresAt, refreshToken, refreshTokenExpiresAt } = authentication;
            const accessTokenExpiresAtDate = accessTokenExpiresAt ? new Date(accessTokenExpiresAt) : null;
            const refreshTokenExpiresAtDate = refreshTokenExpiresAt ? new Date(refreshTokenExpiresAt) : null;

            if (accessTokenExpiresAtDate && accessTokenExpiresAtDate > now) {
                UserRepository.setAuthorisationHeader(accessToken);
                return from(UserRepository.checkAuthorization()).pipe(
                    mergeMap(refreshData => of(authenticateSuccess({ user, ...refreshData }))),
                    catchError(error => {
                        return from(UserRepository.refreshAccessToken(refreshToken)).pipe(
                            mergeMap(refreshData => of(authenticateSuccess({ user, ...refreshData }))),
                            catchError(error => {
                                modal.error('Bitte melden Sie sich neu an.');
                                return from([
                                    authenticateFailure(error),
                                    logoutUser()
                                ])
                            }),
                        );

                    }),
                );
            }
            if (accessTokenExpiresAtDate && accessTokenExpiresAtDate < now && refreshTokenExpiresAtDate && refreshTokenExpiresAtDate > now) {
                return from(UserRepository.refreshAccessToken(refreshToken)).pipe(
                    mergeMap(refreshData => of(authenticateSuccess({ user, ...refreshData }))),
                    catchError(error => {
                        modal.error('Bitte melden Sie sich neu an.');
                        return from([
                            authenticateFailure(error),
                            logoutUser()
                        ])
                    }),
                );
            }

            if (accessTokenExpiresAtDate && accessTokenExpiresAtDate < now && refreshTokenExpiresAtDate && refreshTokenExpiresAtDate < now) {
                modal.error('Bitte melden Sie sich neu an.');
                return from([
                    authenticateFailure({ error: new Error('No Authorization') }),
                    logoutUser()
                ]);
            }

            return EMPTY;

        }),
    );
}
