export const FIND_CHAT_CONVERSATIONS_FAILURE = 'FIND_CHAT_CONVERSATIONS_FAILURE';

export const findChatConversationsFailure = ({ error, request }) => ({
    type: FIND_CHAT_CONVERSATIONS_FAILURE,
    payload: error,
    error: true,
    meta: {
        request
    }
});
