import { FIND_CARE_PROVIDERS_REQUEST, findCareProvidersSuccess, findCareProvidersFailure} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function findCareProvidersEpic(action$, store$, { CareProviderRepository }) {
    return action$.pipe(
        ofType( FIND_CARE_PROVIDERS_REQUEST ),
        mergeMap(action => from(
            CareProviderRepository.findAll(action.payload)
                .then(({ careProviders, meta }) => findCareProvidersSuccess({ careProviders, response: meta, request: action}))
                .catch(error => findCareProvidersFailure({ error, request: action })),
        )),
    );
}
