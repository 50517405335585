import {
    FIND_PSEUDONYM_REQUEST,
    FIND_PSEUDONYMS_REQUEST,
    CREATE_PSEUDONYM_REQUEST,
    UPDATE_PSEUDONYM_REQUEST,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isPseudonymLoading = (state) =>
    ArrayContains(state.pseudonym.openRequests, [
        FIND_PSEUDONYM_REQUEST,
        FIND_PSEUDONYMS_REQUEST,
        CREATE_PSEUDONYM_REQUEST,
        UPDATE_PSEUDONYM_REQUEST,
    ]);