import { Buffer }      from 'buffer';
import {
    DOWNLOAD_PROCESS_STATEMENT_RTF_REQUEST,
    downloadProcessStatementRtfFileSuccess,
    downloadProcessStatementRtfFileFailure,
    findProcessStatementRequest
} from 'Redux/Action';
import { ofType }      from 'redux-observable';
import { from, of, catchError, EMPTY } from 'rxjs';
import { mergeMap }    from 'rxjs/operators';
import { saveAs }      from 'file-saver';

export function downloadProcessStatementRtfFileRequestEpic(action$, store$, { ProcessStatementRepository }) {
    return action$.pipe(
        ofType(DOWNLOAD_PROCESS_STATEMENT_RTF_REQUEST),
        mergeMap(action => {
            const { id } = action.payload;

            if (!`${id}`.trim()) {
                return EMPTY;
            }

            return from(ProcessStatementRepository.downloadRtfFile(id)).pipe(
                mergeMap(({ file, fileName }) => {
                    saveAs(new Blob([ new Buffer(file, 'binary') ]), fileName);
                    return from([
                        downloadProcessStatementRtfFileSuccess(),
                        findProcessStatementRequest(id)
                    ]);
                }),
                catchError(error => of(downloadProcessStatementRtfFileFailure(error))),
            );
        }),
    );
}
