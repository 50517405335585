export const FIND_EVAL_YEARS_REQUEST = 'FIND_EVAL_YEARS_REQUEST';

export const findEvalYearsRequest = ({
    filter = [],
    sorting = { field: 'eval_year', order: 'ASC' },
    pagination = { page: 1, take: 50 },
}) => ({
    type: FIND_EVAL_YEARS_REQUEST,
    payload: { filter, sorting, pagination },
    meta: {
        sorting,
        pagination,
    }
});

