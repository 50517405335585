import {
    UPDATE_AS_READ_PROCESS_STATEMENT_REQUEST,
    updateAsReadProcessStatementSuccess,
    updateAsReadProcessStatementFailure,
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { message } from "antd";

export function updateAsReadProcessStatementEpic(action$, store$, { ProcessStatementRepository }) {
    return action$.pipe(
        ofType(UPDATE_AS_READ_PROCESS_STATEMENT_REQUEST),
        mergeMap( action => from(ProcessStatementRepository.markAsRead(action.payload.id ))
            .pipe(
                mergeMap( statement => {
                    return from ([
                        updateAsReadProcessStatementSuccess(statement),
                    ]);
                }),
                catchError( error => {
                    return from([
                        updateAsReadProcessStatementFailure(error),
                    ])
                })
            )
        ),
    );
}
