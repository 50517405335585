import styled from 'styled-components';
import { Layout } from 'antd';

export default styled(Layout.Content) `
  
    padding: 0 24px 24px;
    overflow: hidden;
    position: relative;

`;
