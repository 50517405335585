import {
    CREATE_EDOK_FORM_REQUEST,
    CREATE_EDOK_FORM_FAILURE,
    CREATE_EDOK_FORM_SUCCESS,

    UPDATE_EDOK_FORM_REQUEST,
    UPDATE_EDOK_FORM_FAILURE,
    UPDATE_EDOK_FORM_SUCCESS,

    SEND_EDOK_FORM_REQUEST,
    SEND_EDOK_FORM_FAILURE,
    SEND_EDOK_FORM_SUCCESS,

    FIND_EDOK_FORM_REQUEST,
    FIND_EDOK_FORM_FAILURE,
    FIND_EDOK_FORM_SUCCESS,

    FIND_EDOK_FORMS_REQUEST,
    FIND_EDOK_FORMS_FAILURE,
    FIND_EDOK_FORMS_SUCCESS,

    GET_CURRENT_EDOK_YEAR_REQUEST,
    GET_CURRENT_EDOK_YEAR_SUCCESS,
    GET_CURRENT_EDOK_YEAR_FAILURE,

    GET_AVAILABLE_EDOK_MODULES_REQUEST,
    GET_AVAILABLE_EDOK_MODULES_SUCCESS,
    GET_AVAILABLE_EDOK_MODULES_FAILURE,

    GET_EDOK_FORM_BY_MODULE_REQUEST,
    GET_EDOK_FORM_BY_MODULE_SUCCESS,
    GET_EDOK_FORM_BY_MODULE_FAILURE,

    FIND_EDOK_DOCUMENTATIONS_REQUEST,
    FIND_EDOK_DOCUMENTATIONS_SUCCESS,
    FIND_EDOK_DOCUMENTATIONS_FAILURE,

    UPDATE_EDOK_DOCUMENTATIONS_REQUEST,
    UPDATE_EDOK_DOCUMENTATIONS_SUCCESS,
    UPDATE_EDOK_DOCUMENTATIONS_FAILURE,

    LOGOUT_USER,
    DISABLE_USER_SUCCESS,
    AUTHENTICATE_FAILURE,
} from 'Redux/Action';
import EdokState from 'Redux/Store/Edok/EdokState';
import { removeOpenRequest, removeFailedRequest, addSingleObjectToReducer, getSingleObject } from 'ClientService/ReducerUtil/ReducerUtil';

const initialState = EdokState.getInitialState();

export const edokReducer = (state = initialState, action) => {

    switch (action.type) {

    case AUTHENTICATE_FAILURE :
    case LOGOUT_USER :
    case DISABLE_USER_SUCCESS :
        return {
            ...initialState,
        };


    case SEND_EDOK_FORM_REQUEST:
    case CREATE_EDOK_FORM_REQUEST:
    case UPDATE_EDOK_DOCUMENTATIONS_REQUEST:
    case GET_CURRENT_EDOK_YEAR_REQUEST:
    case GET_AVAILABLE_EDOK_MODULES_REQUEST:
    case UPDATE_EDOK_FORM_REQUEST:
    case FIND_EDOK_FORM_REQUEST:
    case FIND_EDOK_FORMS_REQUEST:
        return {
            ...state,
            lastInteractiveId: null,
            openRequests: [...state.openRequests, action.type],
            failedRequests: removeFailedRequest(action, state),
            updatedAt: Date.now(),
        };

        case FIND_EDOK_DOCUMENTATIONS_REQUEST:
        return {
            ...state,
            openRequests: [...state.openRequests, action.type],
            failedRequests: removeFailedRequest(action, state),
            updatedAt: Date.now(),
            documentations: { nwiea: null, nwies: null }
        };

    case GET_EDOK_FORM_BY_MODULE_REQUEST:
        return {
            ...state,
            activeEdokForm: null,
            lastInteractiveId: null,
            openRequests: [...state.openRequests, action.type],
            failedRequests: removeFailedRequest(action, state),
            updatedAt: Date.now(),
        };

    case CREATE_EDOK_FORM_SUCCESS:
    case FIND_EDOK_FORM_SUCCESS: {
        const form = getSingleObject(action.payload.form);

        return {
            ...state,
            lastInteractiveId: form.id,
            openRequests     : removeOpenRequest(action, state),
            updatedAt        : Date.now(),
            forms            : {
                ...state.forms,
                entries: addSingleObjectToReducer(state.forms.entries, form)
            },
        };
    }

    case SEND_EDOK_FORM_SUCCESS:
    case UPDATE_EDOK_FORM_SUCCESS: {
        const form = getSingleObject(action.payload.form);

        return {
            ...state,
            lastInteractiveId: form.id,
            openRequests     : removeOpenRequest(action, state),
            updatedAt        : Date.now(),
            forms            : {
                ...state.forms,
                entries: addSingleObjectToReducer(state.forms.entries, form)
            },
            activeEdokForm: form,
        };
    }

    case FIND_EDOK_DOCUMENTATIONS_SUCCESS:
    case UPDATE_EDOK_DOCUMENTATIONS_SUCCESS: {
        const { documentations } = action.payload;
        const docs = {};

        documentations.forEach(doc => {
            docs[`${doc.module}`.toLowerCase()] = doc;
        })

        return {
            ...state,
            openRequests     : removeOpenRequest(action, state),
            updatedAt        : Date.now(),
            documentations   : { ...state.documentations, ...docs },
        };
    }

    case GET_CURRENT_EDOK_YEAR_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
            year: action.payload.year,
        };

    case GET_EDOK_FORM_BY_MODULE_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            updatedAt   : Date.now(),
            activeEdokForm: getSingleObject(action.payload.form),
        };

    case GET_AVAILABLE_EDOK_MODULES_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
            availableModules: action.payload.modules,
            availableForms: action.payload.moduleForms,
        };

    case FIND_EDOK_FORMS_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            forms: {
                entries: action.payload.forms,
                maxCount: (action.meta) ? action.meta.response.count : state.forms.maxCount,
            },
            updatedAt: Date.now(),
        };

    case GET_EDOK_FORM_BY_MODULE_FAILURE:
    case SEND_EDOK_FORM_FAILURE:
    case CREATE_EDOK_FORM_FAILURE:
    case UPDATE_EDOK_FORM_FAILURE:
    case FIND_EDOK_DOCUMENTATIONS_FAILURE:
    case UPDATE_EDOK_DOCUMENTATIONS_FAILURE:
    case FIND_EDOK_FORM_FAILURE:
    case GET_CURRENT_EDOK_YEAR_FAILURE:
    case GET_AVAILABLE_EDOK_MODULES_FAILURE:
    case FIND_EDOK_FORMS_FAILURE:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            failedRequests: [...state.failedRequests, action.type],
            updatedAt: Date.now(),
        };



        default:
        return state;
    }
};
