import validator from 'validator';
import _isEmpty from 'lodash/isEmpty';

export const validateString = ({ settings, value, field }) => {
    if(validator.isEmpty(`${value}`, { ignore_whitespace: true })) {
        return `${field.toUpperCase()}_IS_EMPTY`;
    }
    const rangeSettings = { min: 0, max: undefined };
    if(settings.hasOwnProperty('min')) {
        rangeSettings.min = settings.min;
    }
    if(settings.hasOwnProperty('max')) {
        rangeSettings.max = settings.max;
    }
    if(!validator.isByteLength(`${value}`, rangeSettings)) {
        return `${field.toUpperCase()}_NOT_IN_RANGE`;
    }

    return true;
};

export const validatePhone = ({ settings, value, field }) => {
    if(validator.isEmpty(`${value}`, { ignore_whitespace: true })) {
        return `${field.toUpperCase()}_IS_EMPTY`;
    }

    if(!isValidPhoneNumber(value)) {
        return `${field.toUpperCase()}_NOT_VALID`;
    }

    return true;
};

export const validateMail = ({ settings = {}, value, field }) => {
    if(validator.isEmpty(`${value}`, { ignore_whitespace: true })) {
        return `${field.toUpperCase()}_IS_EMPTY`;
    }

    if(!validator.isEmail(`${value}`, settings)) {
        return `${field.toUpperCase()}_NOT_VALID`;
    }

    return true;
};

export const validateArray = ({ settings, array, field }) => {
    if(!Array.isArray(array)) {
        return `${field.toUpperCase()}_IS_EMPTY`;
    }
    if(settings.hasOwnProperty('min') && array.length < settings.min ) {
        return `${field.toUpperCase()}_IS_EMPTY`;
    }

    return true;
};

export const validateCurrency = ({ settings = {}, value, field }) => {
    if(validator.isEmpty(`${value}`, { ignore_whitespace: true })) {
        return `${field.toUpperCase()}_IS_INVALID`;
    }

    if(!validator.isCurrency(`${value}`, settings.options || {})) {
        return `${field.toUpperCase()}_IS_INVALID`;
    }


    return true;
};

export const validate = ({ settings, value, field }) => {
    switch(settings.type) {
        case 'string' :
            return validateString({ settings, value, field });

        case 'mobile' :
        case 'phone' :
            return validatePhone({ settings, value, field});

        case 'email' :
        case 'mail' :
            return validateMail({ settings, value, field});

        case 'array' :
            return validateArray({ settings, array: value, field });

        case 'currency' :
            return validateCurrency({ settings, value, field });

        case 'custom' :
            if(settings.hasOwnProperty('validate') && typeof settings.validate === 'function') {
                return settings.validate({ value, field });
            }
            console.warning('no validation function exists')
            return false;

        default : return false;
    }

};



export const isEmpty = (value) => {
    if(!value) {
        return true;
    }
    const type = typeof value;
    if(type === 'string') {
        return value.trim() === '';
    }
    if(type === 'object' && !Array.isArray(value)) {
        return _isEmpty(value);
    }
    if(type === 'number') {
        return value === 0;
    }
    return value.length === 0;
};

export const isNotEmpty = (value) => {
    return !isEmpty(value);
};

export const isValidEmail = (value) => {
    return validator.isEmail(`${value}`);
};

export const isValidHouseNumber = (value) => {
    if(!isNotEmpty(value)){
        return false;
    }
    const RegHouseNum = /^\d+[a-zA-Z]*$/;
    return RegHouseNum.test(value);
};

export const isValidIBAN = (value) => {
    if(!isNotEmpty(value)){
        return false;
    }
    const RegNum = /^[A-Z]+\d*$/;
    return RegNum.test(value);
};

export const isValidPhoneNumber = (value) => {
    if(!isNotEmpty(value)){
        return false;
    }

    const RegNum = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
    return RegNum.test(value);
};

export const isValidNumber = (value) => {
    if(!isNotEmpty(value)){
        return false;
    }
    const RegNum = /^\d+$/;
    return RegNum.test(value);
};


export const isValidPassword = (value) => {
    const regExPwd = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@!%*#?&.,;#_+-])[A-Za-zäöüÄÖÜß\d$@!%*?&.,;#_+-]{8,}$/;
    return regExPwd.test(value);
};