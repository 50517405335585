import React, { Suspense }                        from 'react';
import { BrowserRouter, Route, Routes }           from 'react-router-dom';
import LoadingMessage                             from 'Common/LoadingMessage/LoadingMessage';
import { connect }                                from 'react-redux';
import { linkHandler }                            from 'ClientService';
import { internalRoutes, publicRoutes }           from 'AppConfig/Routes';
import { isLoggedIn, hasNetworkError }            from 'Redux/Query';
import App                                        from 'Application/App';
import PublicApp                                  from 'Application/PublicApp/PublicApp';
import { bindActionCreators }                     from 'redux';
import { getSettingsRequest }                     from 'Redux/Action';
import Sideboard                                  from 'Application/Sideboard/Sideboard';
import { Layout }                                 from 'antd';
import Header                                     from 'Application/Header/Header';
import Content                                    from 'Application/Content/Content';
import Footer                                     from 'Application/Footer/Footer';
import { IsUserLoggedIn }                         from './IsUserLoggedIn';


class Router extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoggedIn: props.isLoggedIn || false,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.isLoggedIn !== prevProps.isLoggedIn) {
            this.setState(() => ({ isLoggedIn: this.props.isLoggedIn }));
        }
    }

    addRoutes = (routes) => {
        const routesView = [];

        for (const key in routes) {
            const route      = routes[key];
            const routeProps = {
                exact    : route.exact || true,
                path     : route.path || linkHandler.get('signin'),
                Component: route.Component || (() => (<div/>))
            };

            if (route.arguments) {
                // noinspection JSAnnotator
                routeProps.path += `/:${route.arguments.join('/:')}`;
            }
            const Component = routeProps.Component;

            routesView.push(
                <Route key={`route_${key}`} path={routeProps.path} element={
                    <Suspense fallback={this.renderFallBack()}>
                        {this.props.isLoggedIn ?
                            <App>
                                <Component/>
                            </App> :
                            <PublicApp>
                                <Component/>
                            </PublicApp>
                        }

                    </Suspense>
                }/>
            );
        }

        return routesView;
    };

    renderFallBack = () => {
        if (this.props.isLoggedIn) {
            return (
                <Layout>
                    <Sideboard skeleton={true}/>
                    <Layout className="layoutWrapper" style={{ minHeight: '100vh' }}>
                        <Header skeleton={true}/>
                        <Content style={{ overflow: 'auto', flex: 1 }}/>
                        <Footer/>
                    </Layout>
                </Layout>
            );
        }
        return <LoadingMessage className="pageLoader"/>;
    };

    render() {
        const { isLoggedIn } = this.props;
        let routesView       = this.addRoutes(publicRoutes);
        if (isLoggedIn) {
            routesView = [ ...routesView, ...this.addRoutes(internalRoutes) ];
        }

        if (!!this.props.isAuthenticationRequest) {
            return <LoadingMessage/>;
        }

        const FallBackComponent = publicRoutes.signin.Component;

        return (
            <BrowserRouter>
                <IsUserLoggedIn stateIsLoggedIn={this.state.isLoggedIn}/>
                <Routes>
                    {routesView}
                    <Route
                        path="*"
                        element={
                            <Suspense fallback={this.renderFallBack()}>
                                <PublicApp>
                                    <FallBackComponent/>
                                </PublicApp>
                            </Suspense>
                        }
                    />
                </Routes>
            </BrowserRouter>
        );
    }

}

function mapStateToProps(state) {
    return {
        isLoggedIn             : isLoggedIn(state),
        hasNetworkError        : hasNetworkError(state),
        isAuthenticationRequest: state.user.openRequests.includes('AUTHENTICATE_REQUEST'),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getSettingsRequest,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Router);
