import { combineEpics } from 'redux-observable';

import { authenticateRequestEpic } from './Authenticate/AuthenticateRequestEpic';
import { authenticateSuccessEpic } from './Authenticate/AuthenticateSuccessEpic';
import { registerRequestEpic } from './Register/RegisterRequestEpic';
import { forgotPasswordRequestEpic } from './ForgotPassword/ForgotPasswordRequestEpic';
import { resetPasswordRequestEpic } from './ResetPassword/ResetPasswordRequestEpic';
import { updateUserRequestEpic } from './UpdateUser/UpdateUserRequestEpic';
import { disableUserRequestEpic } from './DisableUser/DisableUserRequestEpic';


export default combineEpics(
    updateUserRequestEpic,
    disableUserRequestEpic,

    authenticateRequestEpic,
    authenticateSuccessEpic,

    registerRequestEpic,
    forgotPasswordRequestEpic,
    resetPasswordRequestEpic,

);
