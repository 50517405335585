import styled from 'styled-components';
import { black, white, lightGrey } from 'Public/css/colors';

export default styled.div `
    
    float: left;
    color: ${ black };
    
    .anticon {
        background-color: ${ lightGrey };
        color: ${ white };
        border-radius: 100%;
        padding: 6px;
        font-size: 18px;
        display: inline-block;
        margin-right: 10px;
        vertical-align: middle;
    }
  
`;