import { RESEND_ACTIVATE_LINK_REQUEST, resendActivateLinkSuccess, resendActivateLinkFailure } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function resendActivateLinkRequestEpic(action$, store$, { EmployeeRepository }) {
    return action$.pipe(
        ofType(RESEND_ACTIVATE_LINK_REQUEST),
        mergeMap(action => from(
            EmployeeRepository.resendActivateLink( action.payload.id )
                .then(( employee ) => resendActivateLinkSuccess({ employee }))
                .catch(error => resendActivateLinkFailure(error)),
        )),
    );
}
