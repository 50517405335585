import { RELOAD_SERVER_SETTINGS_REQUEST, reloadServerSettingsSuccess, reloadServerSettingsFailure} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, mergeMap } from "rxjs/operators";

export function reloadServerSettingsEpic(action$, store$, { SettingsRepository }) {
    return action$.pipe(
        ofType( RELOAD_SERVER_SETTINGS_REQUEST ),
        mergeMap(action => {
            return from(SettingsRepository.reloadSettings()).pipe(
                mergeMap(( { settings, meta } ) => {
                    return from([
                        reloadServerSettingsSuccess({ settings, response: meta, request: action})
                    ]);
                }),
                catchError(error => of(reloadServerSettingsFailure({ error, request: action })))
            )
        })

    );
}
