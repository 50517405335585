import {
    FIND_PSEUDONYM_BY_MODULE_AND_CARE_PROVIDER_REQUEST,
    findPseudonymByModuleAndCareProviderFailure,
    findPseudonymByModuleAndCareProviderSuccess,
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function findPseudonymByModuleAndCareProviderEpic(action$, store$, { PseudonymRepository }) {
    return action$.pipe(
        ofType(FIND_PSEUDONYM_BY_MODULE_AND_CARE_PROVIDER_REQUEST),
        mergeMap( action =>{
            return from(
                PseudonymRepository.findByModuleAndCareProvider(action.payload)
                    .then(pseudonym => findPseudonymByModuleAndCareProviderSuccess(pseudonym))
                    .catch(error => findPseudonymByModuleAndCareProviderFailure(error)),
            );
        }),
    );
}
