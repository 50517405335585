import { MASS_DE_PSEUDO_REQUEST, massDePseudoSuccess, massDePseudoFailure } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from, EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function massDePseudoEpic(action$, store$, { CareProviderRepository }) {
    return action$.pipe(
        ofType(MASS_DE_PSEUDO_REQUEST),
        mergeMap( action =>{
            const { module, year, forAllLagGs } = action.payload;

            return from(
                CareProviderRepository.massDePseudo({ module, year, forAllLagGs })
                    .then(({ careProviders, resultIds }) => massDePseudoSuccess(careProviders, resultIds))
                    .catch(error => massDePseudoFailure(error)),
            );
        }),
    );
}
