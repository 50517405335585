import Pseudonym from "../Pseudonym/Pseudonym";

export default class CareProvider {

    constructor( data  ) {

        this.id             = data.id;
        this.name           = data.name;
        this.kv             = data.kv;
        this.city           = data.city;
        this.postCode       = data.postCode;
        this.street         = data.street;
        this.validationMail = data.validationMail;
        this.identNumber    = data.identNumber;
        this.destinationKey = data.destinationKey;
        this.identity       = '';
        if(!!this.identNumber && !!this.destinationKey) {
            this.identity = `${this.identNumber} (${this.destinationKey})`;
        }
        else if(!!this.identNumber) {
            this.identity = `${this.identNumber}`;
        }
        else if(!!this.destinationKey) {
            this.identity = `${this.destinationKey}`;
        }

        this.validSince     = data.validSince ? new Date(data.validSince) : new Date();
        this.validTo        = data.validTo;
        this.pseudonyms     = [];
        if(data.pseudonyms && Array.isArray(data.pseudonyms) && data.pseudonyms.length > 0) {
            this.pseudonyms = data.pseudonyms.map(pseudonym => new Pseudonym(pseudonym));
        }
    }

}
