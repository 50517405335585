export const FIND_EMPLOYEES_REQUEST = 'FIND_EMPLOYEES_REQUEST';

export const findEmployeesRequest = ({
     filter = [],
     sorting = { field: 'originalname', order: 'ASC' },
     pagination = { page: 1, take: 50 },
 }) => ({
    type: FIND_EMPLOYEES_REQUEST,
    payload: { filter, sorting, pagination },
    meta: {
        sorting,
        pagination,
    }
});
