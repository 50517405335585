import { SEND_POOL_REQUEST_REQUEST, sendPoolRequestSuccess, sendPoolRequestFailure, clearResultsForPoolRequest } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { of, from } from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import { message } from "antd";

export function sendPoolRequestRequestEpic(action$, store$, { PoolRequestRepository }) {
    return action$.pipe(
        ofType(SEND_POOL_REQUEST_REQUEST),
        mergeMap(action =>
            from( PoolRequestRepository.sendPoolRequest( action.payload.poolRequestData )).pipe(
                mergeMap(() => {
                    message.success('Das Stellungnahmeverfahren wurde erfolgreich eingeleitet.');
                    return from([
                        sendPoolRequestSuccess(),
                        clearResultsForPoolRequest(),
                    ])
                }),
                catchError(error => of(sendPoolRequestFailure(error))
            ))
        ),
    );
}
