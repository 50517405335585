export const FIND_RESULTS_REQUEST = 'FIND_RESULTS_REQUEST';

export const findResultsRequest = ({
     filter = [],
     sorting = { field: 'id', order: 'ASC' },
     pagination = { page: 1, take: 50 },
 }) => ({
    type: FIND_RESULTS_REQUEST,
    payload: { filter, sorting, pagination },
    meta: {
        sorting,
        pagination,
    }
});
