import { HAS_ACCESS_GRANTS  } from "AppConfig/Config";
import CareProvider from "Domain/Model/CareProvider/CareProvider";

export class User {

    constructor( data  ) {

        this.isGhostUser = data.isGhostUser || false;
        this.mail        = data.mail;
        this.password    = data.password;
        this.title       = data.title;
        this.firstName   = data.firstName;
        this.lastName    = data.lastName;
        this.name        = '';
        this.role        = data.role;
        this.pseudonym   = data.pseudonym;


        if(data.title) { this.name += `${data.title} `; }
        if(data.firstName) { this.name += `${data.firstName} `; }
        if(data.lastName) { this.name += `${data.lastName} `; }

        if(this.isGhostUser) {
            this.name = data.username;
        }

        this.phone       = data.phone;
        this.validSince  = data.validSince;
        this.validTo     = data.validTo;

        this.careProviders = (data.careProviders) ? data.careProviders.map(careProvider => new CareProvider(careProvider || {})) : [];
        this.modules       = (data.modules) ? data.modules.map(module => module.name) : [];

        if(HAS_ACCESS_GRANTS.includes(this.role)) {
            this.accessGrants           = {};
            data.accessGrants?.forEach(accessGrant => {
                this.accessGrants[accessGrant.key] = accessGrant;
            })
            this.createProcessStatement = data.createProcessStatement || 'SEND';
            this.answerProcessStatement = data.answerProcessStatement || 'SEND';
        }
    }

}
