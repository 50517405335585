import { CREATE_CARE_PROVIDER_REQUEST, createCareProviderSuccess, createCareProviderFailure } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function createCareProviderRequestEpic(action$, store$, { CareProviderRepository }) {
    return action$.pipe(
        ofType(CREATE_CARE_PROVIDER_REQUEST),
        mergeMap(action => from(
            CareProviderRepository.create( action.payload )
                .then(( careProvider ) => createCareProviderSuccess({ careProvider }))
                .catch(error => createCareProviderFailure(error)),
        )),
    );
}
