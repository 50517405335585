import { combineEpics } from 'redux-observable';

import { findTargetActualsEpic } from './FindTargetActuals/FindTargetActualsEpic';
import { updateCommentByTargetActualEpic } from './UpdateCommentByTargetActual/UpdateCommentByTargetActualEpic';


export default combineEpics(
    findTargetActualsEpic,
    updateCommentByTargetActualEpic,

);
