import BaseRepository from '../Base/BaseRepository';
import EmployeeRole from 'Domain/Model/EmployeeRole/EmployeeRole';

class EmployeeRoleRepository extends BaseRepository {

}

export default new EmployeeRoleRepository({
    model: EmployeeRole,
    baseRoute: 'employee-role',
    singular: 'employeeRole',
    plural: 'employeeRoles',
});
