import { getModules } from "Redux/Query";

export const getActiveModules = (state, employee = null) => {
    let employeeModules = getModules(state).map(module => module.id);

    if(employee !== null && !!employee.modules && employee.modules.length > 0) {
        employeeModules = employee.modules.map( module => module.id );
    }

    return employeeModules;
};
