import {
    FIND_REPORT_REQUEST,
    FIND_REPORT_SUCCESS,
    FIND_REPORT_FAILURE,

    FIND_REPORTS_REQUEST,
    FIND_REPORTS_SUCCESS,
    FIND_REPORTS_FAILURE,

    DOWNLOAD_REPORT_REQUEST,
    DOWNLOAD_REPORT_SUCCESS,
    DOWNLOAD_REPORT_FAILURE,

    LOGOUT_USER,

    DISABLE_USER_SUCCESS,
    AUTHENTICATE_FAILURE,

} from 'Redux/Action';
import ReportState from 'Redux/Store/Report/ReportState';
import { removeOpenRequest, removeFailedRequest, addSingleObjectToReducer, getSingleObject } from 'ClientService/ReducerUtil/ReducerUtil';

const initialState = ReportState.getInitialState();

export const reportReducer = (state = initialState, action) => {

    switch (action.type) {

    case AUTHENTICATE_FAILURE :
    case LOGOUT_USER :
    case DISABLE_USER_SUCCESS :
        return {
            ...initialState,
        };


    case FIND_REPORT_REQUEST:
    case FIND_REPORTS_REQUEST:
        return {
            ...state,
            lastInteractiveId: null,
            openRequests: [...state.openRequests, action.type],
            failedRequests: removeFailedRequest(action, state),
            updatedAt: Date.now(),
        };

    case DOWNLOAD_REPORT_REQUEST:
        return {
            ...state,
            openRequests: [...state.openRequests, action.type],
            failedRequests: removeFailedRequest(action, state),
            updatedAt: Date.now(),
        };

    case FIND_REPORT_SUCCESS:
        const report = getSingleObject(action.payload.report);

        return {
            ...state,
            lastInteractiveId: report.id,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
            reports: {
                ...state.reports,
                entries: addSingleObjectToReducer(state.reports.entries, report)
            },
        };
        
    case DOWNLOAD_REPORT_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
        };

    case FIND_REPORTS_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            reports: {
                entries: action.payload.reports,
                maxCount: (action.meta) ? action.meta.response.count : state.reports.maxCount,
            },
            updatedAt: Date.now(),
        };

    case FIND_REPORT_FAILURE:
    case FIND_REPORTS_FAILURE:
    case DOWNLOAD_REPORT_FAILURE:
        return {
            ...state,
            lastInteractiveId: null,
            openRequests: removeOpenRequest(action, state),
            failedRequests: [...state.failedRequests, action.type],
            updatedAt: Date.now(),
        };



    default:
        return state;
    }
};
