import {
    UPDATE_SERVER_SETTINGS_REQUEST,
    updateServerSettingsSuccess,
    updateServerSettingsFailure,
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import {from, of} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';

export function updateServerSettingsRequestEpic(action$, store$, { SettingsRepository }) {
    return action$.pipe(
        ofType(UPDATE_SERVER_SETTINGS_REQUEST),
        mergeMap(action => {
            return from(SettingsRepository.update(action.payload.updateData)).pipe(
                mergeMap(( { settings, meta } ) => {
                    return from([
                        updateServerSettingsSuccess({ settings, response: meta, request: action })
                    ]);
                }),
                catchError(error => of(updateServerSettingsFailure({ error, request: action })))
            )
        })
    );
}
