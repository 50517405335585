import {
    LOAD_PROCESS_STATEMENT_RTF_REQUEST,
    LOAD_PROCESS_STATEMENT_RTF_SUCCESS,
    LOAD_PROCESS_STATEMENT_RTF_FAILURE,

    UPDATE_PROCESS_STATEMENT_RTF_REQUEST,
    UPDATE_PROCESS_STATEMENT_RTF_SUCCESS,
    UPDATE_PROCESS_STATEMENT_RTF_FAILURE,

    LOGOUT_USER,
    DISABLE_USER_SUCCESS,
    AUTHENTICATE_FAILURE,
} from 'Redux/Action';
import { removeFailedRequest, removeOpenRequest } from "ClientService/ReducerUtil/ReducerUtil";
import TempStoreState from 'Redux/Store/TempStore/TempStoreState';

const initialState = TempStoreState.getInitialState();

export const tempStoreReducer = (state = initialState, action ) => {
    switch( action.type ) {

        case AUTHENTICATE_FAILURE :
        case LOGOUT_USER :
        case DISABLE_USER_SUCCESS :
            return {
                ...initialState,
            };

        case UPDATE_PROCESS_STATEMENT_RTF_REQUEST:
        case LOAD_PROCESS_STATEMENT_RTF_REQUEST:
            return {
                ...state,
                openRequests: [...new Set([...state.openRequests, action.type])],
                failedRequests: removeFailedRequest(action, state),
                updatedAt: Date.now(),
            };

        case UPDATE_PROCESS_STATEMENT_RTF_SUCCESS:
        case LOAD_PROCESS_STATEMENT_RTF_SUCCESS:
            return {
                ...state,
                openRequests: removeOpenRequest(action, state),
                updatedAt: Date.now(),
                rtfTemplate: action.payload.rtfTemplate
            };

        case LOAD_PROCESS_STATEMENT_RTF_FAILURE:
        case UPDATE_PROCESS_STATEMENT_RTF_FAILURE:
            return {
                ...state,
                openRequests: removeOpenRequest(action, state),
                failedRequests: [...new Set([...state.failedRequests, action.type])],
                updatedAt: Date.now(),
            };

        default:
            return state;
    }
};
