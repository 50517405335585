import {
    FIND_STATEMENT_TEMPLATE_BY_INDICATOR_ID_REQUEST,
    UPDATE_STATEMENT_TEMPLATE_REQUEST,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isStatementTemplateFindLoading = (state) =>
    ArrayContains(state.statementTemplate.openRequests, [
        FIND_STATEMENT_TEMPLATE_BY_INDICATOR_ID_REQUEST,
        UPDATE_STATEMENT_TEMPLATE_REQUEST,
    ]);