import {
    UPDATE_RATING_REQUEST,
    updateRatingSuccess,
    updateRatingFailure, checkResultRatingMailRequest,
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { message } from "antd";

export function updateRatingEpic(action$, store$, { ResultRepository }) {
    return action$.pipe(
        ofType(UPDATE_RATING_REQUEST),
        mergeMap( action => from(ResultRepository.updateRating( action.payload.resultIds, {
                commentQseb      : action.payload.commentQseb,
                commentQb        : action.payload.commentQb,
                commentInternal  : action.payload.commentInternal,
                commentInternalGs : action.payload.commentInternalGs,
                ratingCode       : action.payload.ratingCode,
                sendCommentMail  : action.payload.sendCommentMail,
        }))
            .pipe(
                mergeMap( ({ results }) => {
                    message.success('Die Bewertung und Kommentare wurden erfolgreich aktualisiert');
                    return from ([
                        updateRatingSuccess(results),
                        checkResultRatingMailRequest(),
                    ]);
                }),
                catchError( error => {
                    return from([
                        updateRatingFailure(error),
                    ])
                })
            )
        ),
    );
}
