export const FIND_INDICATOR_IDS_REQUEST = 'FIND_INDICATOR_IDS_REQUEST';

export const findIndicatorIdsRequest = ({
    filter = [],
    sorting = { field: 'indicator_id', order: 'ASC' },
    pagination = { page: 1, take: 50 },
}) => ({
    type: FIND_INDICATOR_IDS_REQUEST,
    payload: { filter, sorting, pagination },
    meta: {
        sorting,
        pagination,
    }
});

