import { FIND_REPORTS_REQUEST, findReportsSuccess, findReportsFailure} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function findReportsEpic(action$, store$, { ReportRepository }) {
    return action$.pipe(
        ofType( FIND_REPORTS_REQUEST ),
        mergeMap(action => from(
            ReportRepository.findAll(action.payload)
                .then(({ reports, meta }) => findReportsSuccess({ reports, response: meta, request: action}))
                .catch(error => findReportsFailure({ error, request: action })),
        )),
    );
}
