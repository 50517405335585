import {
    FIND_MAIL_TEMPLATE_REQUEST,
    findMailTemplateSuccess,
    findMailTemplateFailure
} from 'Redux/Action';
import { ofType }      from 'redux-observable';
import { from, EMPTY } from 'rxjs';
import { mergeMap }    from 'rxjs/operators';

export function findMailTemplateEpic(action$, store$, { MailTemplateRepository }) {
    return action$.pipe(
        ofType(FIND_MAIL_TEMPLATE_REQUEST),
        mergeMap( action =>{
            const { id } = action.payload;

            if(!id.trim()) {
                return EMPTY;
            }

            return from(
                MailTemplateRepository.findById( id )
                    .then(({ mailTemplate }) => findMailTemplateSuccess(mailTemplate))
                    .catch(error => findMailTemplateFailure(error)),
            );
        }),
    );
}
