import { FIND_FILE_REQUEST, findFileFailure, findFileSuccess} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from, EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function findFileEpic(action$, store$, { FileRepository }) {
    return action$.pipe(
        ofType(FIND_FILE_REQUEST),
        mergeMap( action =>{
            const { guid } = action.payload;

            if(!guid.trim()) {
                return EMPTY;
            }

            return from(
                FileRepository.findByGuid( guid )
                    .then(file => findFileSuccess(file))
                    .catch(error => findFileFailure(error)),
            );
        }),
    );
}
