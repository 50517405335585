import { RESEND_ACTIVATE_LINK_SUCCESS, showNotification } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

export function resendActivateLinkSuccessEpic(action$) {
    return action$.pipe(
        ofType(RESEND_ACTIVATE_LINK_SUCCESS),
        mergeMap(() => {

            return of(showNotification('Ein neuer Aktivierungslink wurde erfolgreich versendet.'));
        })
    );
}
