import Module               from 'Domain/Model/Module/Module';
import File                 from 'Domain/Model/File/File';
import Pseudonym            from 'Domain/Model/Pseudonym/Pseudonym';

export default class Report {

    constructor(data) {

        this.id               = data.id || 'new';
        this.createdAt        = data.createdAt;
        this.guideline        = data.guideline || '';
        this.type             = data.type || '';
        this.date             = data.date || '';
        this.releaseDate      = (data.releaseDate) ? new Date(data.releaseDate) : null;
        this.lastDownloadDate = (data.lastDownloadDate) ? new Date(data.lastDownloadDate) : null;
        this.file             = (data.file) ? new File(data.file) : null;
        this.pseudonym        = new Pseudonym(data.pseudonym);
        this.module           = new Module(data.pseudonym.module);
        const identity        = data.pseudonym.careProviderIdentity;

        let identNumber;

        if (!!identity) {
            if (identity.identNumber) {
                identNumber = identity.identNumber;
            }
            if (identity.destinationKey) {
                if (!!identNumber) {
                    identNumber += ' ';
                }
                identNumber += identity.destinationKey;
            }
        }

        this.identity = identNumber;


    }

}
