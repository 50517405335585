import {
    CREATE_PSEUDONYM_REQUEST,
    createPseudonymSuccess,
    createPseudonymFailure,
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { message } from "antd";

export function createPseudonymRequestEpic(action$, store$, { PseudonymRepository }) {
    return action$.pipe(
        ofType(CREATE_PSEUDONYM_REQUEST),
        mergeMap( action => from(PseudonymRepository.create( action.payload ))
            .pipe(
                mergeMap((pseudonym) => {
                    message.success('Das Pseudonym wurde erfolgreich erstellt.');
                    return from([
                        createPseudonymSuccess({ pseudonym }),
                    ]);
                }),
                catchError( error => {
                    return from([
                        createPseudonymFailure(error),
                    ])
                })
            )
        ),
    );
}
