export const FIND_PROCESS_STATEMENTS_SUCCESS = 'FIND_PROCESS_STATEMENTS_SUCCESS';

export const findProcessStatementsSuccess = ({ processStatements, request, response }) => ({
    type: FIND_PROCESS_STATEMENTS_SUCCESS,
    payload: { processStatements },
    meta: {
        request,
        response,
    }
});
