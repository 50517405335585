import { combineEpics } from 'redux-observable';

import { addNewChatMessageEpic }            from './AddNewChatMessage/AddNewChatMessageEpic';
import { findChatConversationEpic }         from './FindChatConversation/FindChatConversationEpic';
import { findChatConversationsEpic }        from './FindChatConversations/FindChatConversationsEpic';
import { createNewChatConversationEpic }    from './CreateNewChatConversation/CreateNewChatConversationEpic';
import { findChatConversationPartnersEpic } from './FindChatConversationPartners/FindChatConversationPartnersEpic';
import { countOpenChatConversationsEpic }   from './CountOpenChatConversations/CountOpenChatConversationsEpic';
import { downloadChatConversationEpic }     from './DownloadChatConversation/DownloadChatConversationEpic';


export default combineEpics(
    addNewChatMessageEpic,
    findChatConversationEpic,
    findChatConversationsEpic,
    createNewChatConversationEpic,
    findChatConversationPartnersEpic,
    countOpenChatConversationsEpic,
    downloadChatConversationEpic,
);
