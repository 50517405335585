export const UPDATE_RATING_REQUEST = 'UPDATE_RATING_REQUEST';

export const updateRatingRequest = ( resultIds = [], { commentQseb, commentQb, commentInternal, commentInternalGs, ratingCode, sendCommentMail } ) => ({
    type: UPDATE_RATING_REQUEST,
    payload: {
        resultIds,
        commentQseb,
        commentQb,
        commentInternal,
        commentInternalGs,
        ratingCode,
        sendCommentMail
    }
});
