import PersistStoreState from "Redux/Store/PersistStore/PersistStoreState";

import {

    GET_SETTINGS_REQUEST,
    GET_SETTINGS_FAILURE,
    GET_SETTINGS_SUCCESS,

    RELOAD_SERVER_SETTINGS_REQUEST,
    RELOAD_SERVER_SETTINGS_SUCCESS,
    RELOAD_SERVER_SETTINGS_FAILURE,

    UPDATE_SERVER_SETTINGS_REQUEST,
    UPDATE_SERVER_SETTINGS_SUCCESS,
    UPDATE_SERVER_SETTINGS_FAILURE,

    LOAD_PROCESS_STATEMENT_RTF_REQUEST,
    LOAD_PROCESS_STATEMENT_RTF_SUCCESS,
    LOAD_PROCESS_STATEMENT_RTF_FAILURE,

    SET_RESULTS_FOR_POOL_REQUEST,
    CLEAR_RESULTS_FOR_POOL_REQUEST,

    DE_PSEUDO_CARE_PROVIDER_SUCCESS,
    MASS_DE_PSEUDO_SUCCESS,

    GET_ENCRYPTED_PSEUDONYMS_SUCCESS,

    LOGOUT_USER,
    DISABLE_USER_SUCCESS,
    AUTHENTICATE_FAILURE,

} from 'Redux/Action';
import { removeFailedRequest, removeOpenRequest } from "ClientService/ReducerUtil/ReducerUtil";

const initialState = PersistStoreState.getInitialState();

export const persistStoreReducer = (state = initialState, action ) => {
    switch( action.type ) {

        case AUTHENTICATE_FAILURE :
        case LOGOUT_USER :
        case DISABLE_USER_SUCCESS :
            return {
                ...initialState,
                settings: { ...state.settings },
            };

        case RELOAD_SERVER_SETTINGS_REQUEST:
        case UPDATE_SERVER_SETTINGS_REQUEST:
        case LOAD_PROCESS_STATEMENT_RTF_REQUEST:
        case GET_SETTINGS_REQUEST:
            return {
                ...state,
                openRequests: [...new Set([...state.openRequests, action.type])],
                failedRequests: removeFailedRequest(action, state),
                updatedAt: Date.now(),
            };

        case GET_SETTINGS_SUCCESS:
        case UPDATE_SERVER_SETTINGS_SUCCESS:
        case RELOAD_SERVER_SETTINGS_SUCCESS:
            return {
                ...state,
                openRequests: removeOpenRequest(action, state),
                updatedAt: Date.now(),
                settings: action.payload.settings,
            };

        case GET_SETTINGS_FAILURE:
        case UPDATE_SERVER_SETTINGS_FAILURE:
        case LOAD_PROCESS_STATEMENT_RTF_FAILURE:
        case RELOAD_SERVER_SETTINGS_FAILURE:
            return {
                ...state,
                openRequests: removeOpenRequest(action, state),
                failedRequests: [...new Set([...state.failedRequests, action.type])],
                updatedAt: Date.now(),
            };

        case DE_PSEUDO_CARE_PROVIDER_SUCCESS:
            return {
                ...state,
                updatedAt: Date.now(),
                dePseudonymResults: [ ...new Set([ ...state.dePseudonymResults, action.payload.pseudonym ]) ].filter(entry => !!entry),
            };

        case LOAD_PROCESS_STATEMENT_RTF_SUCCESS:
            return {
                ...state,
                updatedAt: Date.now(),
                rtfTemplate: action.payload.rtfTemplate
            };

        case GET_ENCRYPTED_PSEUDONYMS_SUCCESS:
        case MASS_DE_PSEUDO_SUCCESS:
            const { resultIds } = action.payload;
            return {
                ...state,
                updatedAt: Date.now(),
                dePseudonymResultIds: [ ...new Set([ ...state.dePseudonymResultIds, ...resultIds ]) ].filter(entry => !!entry),
            };


        case SET_RESULTS_FOR_POOL_REQUEST:
            return {
                ...state,
                resultPoolRequestIds: [ ...action.payload.resultEntryIds ],
                updatedAt: Date.now(),
            };

        case CLEAR_RESULTS_FOR_POOL_REQUEST:
            return {
                ...state,
                resultPoolRequestIds: [],
                updatedAt: Date.now(),
            };


        default:
            return state;
    }
};
