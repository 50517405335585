import {
    CREATE_PSEUDONYM_FAILURE,
    UPDATE_PSEUDONYM_FAILURE,
    FIND_PSEUDONYM_FAILURE,
    FIND_PSEUDONYMS_FAILURE,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isPseudonymFailed = (state) => ArrayContains(state.pseudonym.failedRequests, [
    CREATE_PSEUDONYM_FAILURE,
    UPDATE_PSEUDONYM_FAILURE,
    FIND_PSEUDONYM_FAILURE,
    FIND_PSEUDONYMS_FAILURE,
]);