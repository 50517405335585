export const FIND_RESULTS_SUCCESS = 'FIND_RESULTS_SUCCESS';

export const findResultsSuccess = ({ results, request, response }) => ({
    type: FIND_RESULTS_SUCCESS,
    payload: { results },
    meta: {
        request,
        response,
    }
});
