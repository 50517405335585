import { AUTHENTICATE_REQUEST, authenticateSuccess, authenticateFailure } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function authenticateRequestEpic(action$, store$, { UserRepository }) {
    return action$.pipe(
        ofType(AUTHENTICATE_REQUEST),
        mergeMap(action => from(
            UserRepository.login({ mail: action.payload.username, password: action.payload.password })
                .then(userData => authenticateSuccess({ ...userData }))
                .catch(error => authenticateFailure(error)),
        )),
    );
}
