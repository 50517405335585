import { combineEpics } from 'redux-observable';

import { findResultsEpic }                      from './FindResults/FindResultsEpic';
import { findResultEpic }                       from './FindResult/FindResultEpic';
import { uploadResultsEpic }                    from './UploadResults/UploadResultsEpic';
import { uploadNoticeResultsEpic }              from './UploadNoticeResults/UploadNoticeResultsEpic';
import { updateResultsQsebEpic }                from './UpdateResultsQseb/UpdateResultsQsebEpic';
import { updateRatingEpic }            from './UpdateRating/UpdateRatingEpic';
import { updateCommentByResultEpic }            from './UpdateCommentByResult/UpdateCommentByResultEpic';
import { checkResultRatingMailEpic }            from './CheckResultRatingMail/CheckResultRatingMailEpic';
import { sendResultRatingMailEpic }             from './SendResultRatingMail/SendResultRatingMailEpic';
import { findRefResultsEpic }                   from './FindRefResults/FindRefResultsEpic';
import { findEvalYearsEpic }                    from './FindEvalYears/FindEvalYearsEpic';
import { findSpecYearsEpic }                    from './FindSpecYears/FindSpecYearsEpic';

export default combineEpics(
    findResultsEpic,
    findResultEpic,
    uploadResultsEpic,
    uploadNoticeResultsEpic,
    updateResultsQsebEpic,
    updateRatingEpic,
    updateCommentByResultEpic,
    checkResultRatingMailEpic,
    sendResultRatingMailEpic,
    findRefResultsEpic,
    findEvalYearsEpic,
    findSpecYearsEpic,
);
