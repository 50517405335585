import {
    FIND_CARE_PROVIDER_REQUEST,
    FIND_CARE_PROVIDERS_REQUEST,
    CREATE_CARE_PROVIDER_REQUEST,
    UPDATE_CARE_PROVIDER_REQUEST,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isCareProviderLoading = (state) =>
    ArrayContains(state.careProvider.openRequests, [
        FIND_CARE_PROVIDER_REQUEST,
        FIND_CARE_PROVIDERS_REQUEST,
        CREATE_CARE_PROVIDER_REQUEST,
        UPDATE_CARE_PROVIDER_REQUEST,
    ]);