export const FIND_SPEC_YEARS_REQUEST = 'FIND_SPEC_YEARS_REQUEST';

export const findSpecYearsRequest = ({
    filter = [],
    sorting = { field: 'spec_year', order: 'ASC' },
    pagination = { page: 1, take: 50 },
}) => ({
    type: FIND_SPEC_YEARS_REQUEST,
    payload: { filter, sorting, pagination },
    meta: {
        sorting,
        pagination,
    }
});

