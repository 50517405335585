import { GET_SETTINGS_REQUEST, getSettingsSuccess, getSettingsFailure} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, mergeMap } from "rxjs/operators";

export function getSettingsEpic(action$, store$, { SettingsRepository }) {
    return action$.pipe(
        ofType( GET_SETTINGS_REQUEST ),
        mergeMap(action => {
            return from(SettingsRepository.findAll()).pipe(
                mergeMap(( { settings, meta } ) => {
                    return from([
                        getSettingsSuccess({ settings, response: meta, request: action})
                    ]);
                }),
                catchError(error => of(getSettingsFailure({ error, request: action })))
            )
        })

    );
}
