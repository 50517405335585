import {
    UPLOAD_RESULTS_REQUEST,
    UPLOAD_NOTICE_RESULTS_REQUEST,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isResultUploading = (state) =>
    ArrayContains(state.result.openRequests, [
        UPLOAD_RESULTS_REQUEST,
        UPLOAD_NOTICE_RESULTS_REQUEST,
    ]);