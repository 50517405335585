import styled from 'styled-components';
import { Button } from 'antd';
import { fontColor, secondaryFontColor, white } from 'Public/css/colors';

export default styled(Button) `
  display: inline-block;
  background-color: ${secondaryFontColor};
  border-color: ${secondaryFontColor};
  color: ${white};
  margin-bottom: 8px;
  height: 40px;

  &[disabled], &[disabled]:hover {
    opacity: 0.7;
  }

  a {
    color: ${white};
  }

  &.warning {
    color: #fa8c16;
    background: #fff7e6;
    border-color: #ffd591;

    &:hover {
      color: #fa8c16;
      background: #fff7e6;
      border-color: #ffd591;
    }

    &:hover:not([disabled]) {
      background: #fa8c16;
      color: #fff7e6;

    }
  }

  &.error {
    color: #e1001a;
    background: #fff7e6;
    border-color: #f37480;

    &:hover {
      color: #e1001a;
      background: #fff7e6;
      border-color: #f37480;
    }

    &:hover:not([disabled]) {
      background: #e1001a;
      color: #fff7e6;

    }
  }

  &.ant-btn-secondary, &.create {
    background-color: transparent;
    color: ${fontColor};
    border-color: ${fontColor};

    a {
      color: ${fontColor};
    }

    &:hover {
      color: ${secondaryFontColor};
      border-color: ${secondaryFontColor};

    }

    &.warning {
      color: #fa8c16;
      border-color: #ffd591;

      &:hover {
        color: #fa8c16;
        border-color: #ffd591;
      }

      &:hover:not([disabled]) {
        background: #fa8c16;
        color: #fff7e6;

      }
    }
    
  }

  &.create {
    color: ${secondaryFontColor};
    font-weight: bold;
    border-color: ${secondaryFontColor};
    box-shadow: none;
    background-color: ${white};
    margin-left: 20px;
  }

  &.small {
    height: 20px;
    padding: 0 10px;
    margin-bottom: 0;
    line-height: 1;
  }
    
    &.transparentButton {
        background-color: transparent;
        border-color: transparent;
        color: ${secondaryFontColor};
        box-shadow: none;
    }
`;
