import { parseQueryArguments } from 'ClientService';

export const getListDefaultState = (props) => {
    const queryArguments = parseQueryArguments(`?${window.location.href.split('?')[1]}`);
    const filter = [];

    for(const queryKey in queryArguments) {
        const value = queryArguments[queryKey];
        if(!queryKey.match('filter_')) {
            continue;
        }
        const key   = queryKey.replace('filter_', '');
        filter.push({
            field: key,
            value,
        })
    }

    if(!!queryArguments.page) {
        queryArguments.page = parseInt(queryArguments.page, 10);
    }
    if(!!queryArguments.take) {
        queryArguments.take = parseInt(queryArguments.take, 10);
    }


    return {
        page: queryArguments.page || props.page ||  1,
        take: queryArguments.take || props.take || 10,
        willBeLoadData: false,
        filter,
        sorting: {
            field: queryArguments.field || undefined,
            order: (['ASC', 'DESC'].includes(queryArguments.order)) ? queryArguments.order : undefined,
        },
    };
};