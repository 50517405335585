const getInitialState = () => ({
    openRequests: [],
    failedRequests: [],
    updatedAt: 0,
    results: {
        entries: {},
        maxCount: 0,
    },
    lastInteractiveGuid: null,
    allowSendResultRatingMail: false,
    evalYears: [],
    specYears: [],
});

export default {
    getInitialState
};
