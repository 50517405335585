import { ruleParser } from 'ClientService/EdokUtility';

export const edokTestRules = ruleParser([
    // {
    //     id: 1,
    //     condition: "(LLPROPHCHECKLISTE = LEER ODER LLPROPHCHECKLISTE <> 1) UND LLPROPHCHECKPRUEF <> LEER",
    //     description: "\"Wurden für alle in der ...",
    //     __test: {
    //         condition: `(LLPROPHCHECKLISTE === null || LLPROPHCHECKLISTE !== '1') && LLPROPHCHECKPRUEF !== null`,
    //         values: [
    //             { expect: true, arguments: {LLPROPHCHECKLISTE: null, LLPROPHCHECKPRUEF: 1} },
    //             { expect: true, arguments: {LLPROPHCHECKLISTE: 0, LLPROPHCHECKPRUEF: 1} },
    //             { expect: false, arguments: {LLPROPHCHECKLISTE: 1, LLPROPHCHECKPRUEF: 1} },
    //             { expect: false, arguments: {LLPROPHCHECKLISTE: null, LLPROPHCHECKPRUEF: null} },
    //         ]
    //     }
    // },
    // {
    //     id: 2,
    //     condition: "LLINITH = 1 UND LLINITHAKTDATUM = LEER",
    //     description: "\"Wurden für alle in der ...",
    //     __test: {
    //         condition: `LLINITH === '1' && LLINITHAKTDATUM === null`,
    //         values: [
    //             { expect: true, arguments: {LLINITH: 1, LLINITHAKTDATUM: null} },
    //             { expect: false, arguments: {LLINITH: null, LLINITHAKTDATUM: null} },
    //             { expect: false, arguments: {LLINITH: 1, LLINITHAKTDATUM: '12.2022'} },
    //         ]
    //     }
    // },
    // {
    //     id: 3,
    //     condition: "RDGWARTUNGSDATUM > '12.2022'",
    //     description: "\"Wurden für alle in der ...",
    //     __test: {
    //         condition: `RDGWARTUNGSDATUM > 1672527599000`,
    //         values: [
    //             { expect: true, arguments: {RDGWARTUNGSDATUM: '01.2023'} },
    //             { expect: true, arguments: {RDGWARTUNGSDATUM: 1672527600000} },
    //             { expect: false, arguments: {RDGWARTUNGSDATUM: '12.2022'} },
    //             { expect: false, arguments: {RDGWARTUNGSDATUM: 1669849200000} },
    //         ]
    //     }
    // },
    // {
    //     id: 4,
    //     condition: "STERILGUTREINIGUNG NICHTIN (2;3;5;7) UND HYGIENESOPRDGMUSTER <> LEER",
    //     description: "\"Wurden für alle in der ...",
    //     __test: {
    //         condition: `!['2','3','5','7'].includes(STERILGUTREINIGUNG) && HYGIENESOPRDGMUSTER !== null`,
    //         values: [
    //             { expect: true, arguments: {STERILGUTREINIGUNG: 1, HYGIENESOPRDGMUSTER: 1} },
    //             { expect: false, arguments: {STERILGUTREINIGUNG: 2, HYGIENESOPRDGMUSTER: 1} },
    //             { expect: false, arguments: {STERILGUTREINIGUNG: 1, HYGIENESOPRDGMUSTER: null} },
    //         ]
    //     }
    // },
    // {
    //     id: 5,
    //     condition: "HYGIENESOP = 1 UND STERILGUTREINIGUNG IN (2;3;5;7) UND HYGIENESOPRDGMUSTER = LEER",
    //     description: "\"Wurden für alle in der ...",
    //     __test: {
    //         condition: `HYGIENESOP === '1' && ['2','3','5','7'].includes(STERILGUTREINIGUNG) && HYGIENESOPRDGMUSTER === null`,
    //         values: [
    //             { expect: true, arguments: {HYGIENESOP: 1, STERILGUTREINIGUNG: 2, HYGIENESOPRDGMUSTER: null} },
    //             { expect: true, arguments: {HYGIENESOP: 1, STERILGUTREINIGUNG: 7, HYGIENESOPRDGMUSTER: null} },
    //             { expect: false, arguments: {HYGIENESOP: null, STERILGUTREINIGUNG: 7, HYGIENESOPRDGMUSTER: null} },
    //             { expect: false, arguments: {HYGIENESOP: 1, STERILGUTREINIGUNG: 7, HYGIENESOPRDGMUSTER: 1} },
    //             { expect: false, arguments: {HYGIENESOP: 1, STERILGUTREINIGUNG: 1, HYGIENESOPRDGMUSTER: null} },
    //         ]
    //     }
    //
    // },
    // {
    //     id: 6,
    //     condition: "LLINITHAKTDATUM > '12.2022'",
    //     description: "Das angegebene Datum liegt nach dem Ende des Erfassungsjahres.",
    //     __test: {
    //         condition: `LLINITHAKTDATUM > 1672527599000`,
    //         values: [
    //             { expect: true, arguments: {LLINITHAKTDATUM: '1676712595000'} },
    //             { expect: true, arguments: {LLINITHAKTDATUM: '02.2023'} },
    //             { expect: true, arguments: {LLINITHAKTDATUM: 1676712595000} },
    //             { expect: false, arguments: {LLINITHAKTDATUM: '05.2022'} },
    //         ]
    //     }
    //
    // },
    {
        id: 7,
        condition: "SCHULUNGANTIB = LEER UND SCHULUNGANTIBNE = LEER UND SCHULUNGANTIBNV = LEER",
        description: "Keines der Felder \"Wie hoch ist der Anteil der Ärzte, die im Jahr 2022 mindestens an einer Informationsveranstaltung oder an einem E-Learning-Programm zur Thematik \"Antibiotikaresistenzlage und -therapie\" teilgenommen haben?\", \"Daten wurden nicht oder nicht in der geforderten Form erhoben\" und \"ärztliches Personal nicht vorhanden oder nicht ganzjährig tätig\" ist ausgefüllt",
        __test: {
            condition: `SCHULUNGANTIB === null && SCHULUNGANTIBNE === null && SCHULUNGANTIBNV === null`,
            values: [
                { expect: true, arguments: {SCHULUNGANTIB: null, SCHULUNGANTIBNE: null, SCHULUNGANTIBNV: null} },
            ]
        }

    },

]);
