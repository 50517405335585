import { FIND_PSEUDONYMS_REQUEST, findPseudonymsSuccess, findPseudonymsFailure} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function findPseudonymsEpic(action$, store$, { PseudonymRepository }) {
    return action$.pipe(
        ofType( FIND_PSEUDONYMS_REQUEST ),
        mergeMap(action => from(
            PseudonymRepository.findAll(action.payload)
                .then(({ pseudonyms, meta }) => findPseudonymsSuccess({ pseudonyms, response: meta, request: action}))
                .catch(error => findPseudonymsFailure({ error, request: action })),
        )),
    );
}
