import {
    DISMISS_ANSWER_FROM_PROCESS_STATEMENT_REQUEST,
    dismissAnswerFromProcessStatementSuccess,
    dismissAnswerFromProcessStatementFailure,
    showNotification
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';

export const dismissAnswerFromProcessStatementEpic = (action$, store$, { ProcessStatementRepository }) => {
    return action$.pipe(
        ofType(DISMISS_ANSWER_FROM_PROCESS_STATEMENT_REQUEST),
        mergeMap(action => from(ProcessStatementRepository.dismissAnswer({ ...action.payload }))
            .pipe(
                mergeMap((statement) => {
                    return from([
                        showNotification(`Die Antwort der Stellungnahme wurde erfolgreich zurückgewiesen.`),
                        dismissAnswerFromProcessStatementSuccess(statement),
                    ]);
                }),
                catchError( error => {
                    return from([
                        dismissAnswerFromProcessStatementFailure(error),
                    ])
                })
            )
        ),
    );
}
