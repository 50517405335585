const getInitialState = () => ({
    updatedAt: 0,
    settings: {},
    resultPoolRequestIds: [],
    dePseudonymResults: [],
    dePseudonymResultIds: [],
    openRequests: [],
    failedRequests: [],
});

export default {
    getInitialState
};
