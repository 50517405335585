import {
    UPDATE_RESULTS_QSEB_REQUEST,
    updateResultsQsebSuccess,
    updateResultsQsebFailure,
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { message } from "antd";

export function updateResultsQsebEpic(action$, store$, { ResultRepository }) {
    return action$.pipe(
        ofType(UPDATE_RESULTS_QSEB_REQUEST),
        mergeMap( action => from(ResultRepository.updateQsebData( action.payload.results ))
            .pipe(
                mergeMap( ({ results }) => {
                    message.success('Die Ergebnisse wurden erfolgreich aktualisiert');
                    return from ([
                        updateResultsQsebSuccess(results),
                    ]);
                }),
                catchError( error => {
                    return from([
                        updateResultsQsebFailure(error)
                    ])
                })
            )
        ),
    );
}
