import {
    FIND_STATEMENT_TEMPLATE_PLACEHOLDERS_REQUEST,
    findStatementTemplatePlaceholdersSuccess,
    findStatementProcessPlaceholdersFailure
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

export function findStatementTemplatePlaceholdersEpic(action$, store$, { StatementTemplateRepository }) {
    return action$.pipe(
        ofType( FIND_STATEMENT_TEMPLATE_PLACEHOLDERS_REQUEST ),
        mergeMap( action => from(StatementTemplateRepository.findAll())
            .pipe(
                mergeMap( ({ statementTemplates }) => {
                    return from ([
                        findStatementTemplatePlaceholdersSuccess({ statementTemplates }
                        ),
                    ]);
                }),
                catchError( error => {
                    return from([
                        findStatementProcessPlaceholdersFailure(error),
                    ])
                })
            )
        ),
    );
}
