import {
    FIND_FILE_REQUEST,
    FIND_FILES_REQUEST,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isFileLoading = (state) =>
    ArrayContains(state.file.openRequests, [
        FIND_FILE_REQUEST,
        FIND_FILES_REQUEST,
    ]);