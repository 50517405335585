import {
    GET_ANSWER_INBOX_PROCESS_STATEMENT_COUNT_REQUEST,
    FIND_OPEN_ANSWER_PROCESS_STATEMENTS_SUCCESS,
    UPDATE_STATEMENT_PROCESS_SUCCESS,
    UPDATE_PROCESS_STATEMENTS_SUCCESS,
    FIND_PROCESS_STATEMENTS_SUCCESS,
    getAnswerInboxProcessStatementCountSuccess,
    getAnswerInboxProcessStatementCountFailure,
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from, of, EMPTY } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from "rxjs/operators";
import {ADM_KH, KH, VA} from "AppConfig/Config";
import {getAccessGrant, userContainsRole} from "Redux/Query";

export function getAnswerInboxProcessStatementCountEpic(action$, store$, { ProcessStatementRepository }) {
    return action$.pipe(
        ofType(
            GET_ANSWER_INBOX_PROCESS_STATEMENT_COUNT_REQUEST,
            FIND_OPEN_ANSWER_PROCESS_STATEMENTS_SUCCESS,
            UPDATE_STATEMENT_PROCESS_SUCCESS,
            UPDATE_PROCESS_STATEMENTS_SUCCESS,
            FIND_PROCESS_STATEMENTS_SUCCESS,
        ),
        withLatestFrom(store$),
        mergeMap(([ action, store ]) => {
            if(!userContainsRole(store)([ KH, VA, ADM_KH ]) || getAccessGrant(store, 'ANSWER_PROCESS_STATEMENT') !== 'SEND') {
                return EMPTY;
            }

            return from(ProcessStatementRepository.getOpenAnswerCount()).pipe(
                mergeMap(( { inboxCount } ) => {
                    return from([
                        getAnswerInboxProcessStatementCountSuccess({ inboxCount, request: action})
                    ]);
                }),
                catchError(error => of(getAnswerInboxProcessStatementCountFailure({ error, request: action })))
            )
        })

    );
}
