import { FIND_EDOK_FORMS_REQUEST, findEdokFormsFailure, findEdokFormsSuccess} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function findEdokFormsEpic(action$, store$, { EdokRepository }) {
    return action$.pipe(
        ofType( FIND_EDOK_FORMS_REQUEST ),
        mergeMap(action => from(
            EdokRepository.findAll(action.payload)
                .then(({ forms, meta }) => findEdokFormsSuccess({ forms, response: meta, request: action}))
                .catch(error => findEdokFormsFailure({ error, request: action })),
        )),
    );
}
