import { FIND_POOL_REQUESTS_REQUEST, findPoolRequestsFailure, findPoolRequestsSuccess } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function findPoolRequestsEpic(action$, store$, { PoolRequestRepository }) {
    return action$.pipe(
        ofType( FIND_POOL_REQUESTS_REQUEST ),
        mergeMap(action => from(
            PoolRequestRepository.findAll(action.payload)
                .then(({ poolRequests, meta }) => findPoolRequestsSuccess({ poolRequests, response: meta, request: action}))
                .catch(error => findPoolRequestsFailure({ error, request: action })),
        )),
    );
}
