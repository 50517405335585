import CareProvider from "Domain/Model/CareProvider/CareProvider";
import Employee from "Domain/Model/Employee/Employee";

export default class TargetActual {

    constructor( data  ) {

        this.id             = data.id;
        this.createdAt      = data.createdAt;
        this.year           = data.year;
        this.module         = data.module;
        this.countingModule = data.countingModule;
        this.name           = data.name;
        this.actual         = data.actual;
        this.actualPrevious = data.actualPrevious;
        this.target         = data.target;
        this.rate           = data.rate;
        this.comment        = data.comment;
        this.commentDate    = data.commentDate;
        this.careProvider   = (!!data.careProvider) ? new CareProvider(data.careProvider) : {};
        this.editor         = (!!data.editor) ? new Employee(data.editor) : {};


    }

}
