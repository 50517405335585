import { CREATE_POOL_REQUEST_REQUEST, createPoolRequestSuccess, createPoolRequestFailure } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function createPoolRequestRequestEpic(action$, store$, { PoolRequestRepository }) {
    return action$.pipe(
        ofType(CREATE_POOL_REQUEST_REQUEST),
        mergeMap(action => from(
            PoolRequestRepository.create( action.payload )
                .then(( poolRequest ) => createPoolRequestSuccess({ poolRequest }))
                .catch(error => createPoolRequestFailure(error)),
        )),
    );
}
