import { combineEpics } from 'redux-observable';

import { findModulesEpic } from './FindModules/FindModulesEpic';
import { findModulesByCareProviderEpic } from './FindModulesByCareProvider/FindModulesByCareProviderEpic';
import { findModuleEpic } from './FindModule/FindModuleEpic';


export default combineEpics(
    findModulesEpic,
    findModuleEpic,
    findModulesByCareProviderEpic,
);
