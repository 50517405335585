import {
    FIND_CHAT_CONVERSATION_REQUEST,
    findChatConversationSuccess,
    findChatConversationFailure
} from 'Redux/Action';
import { ofType }      from 'redux-observable';
import { from, EMPTY } from 'rxjs';
import { mergeMap }    from 'rxjs/operators';

export function findChatConversationEpic(action$, store$, { ChatRepository }) {
    return action$.pipe(
        ofType(FIND_CHAT_CONVERSATION_REQUEST),
        mergeMap( action =>{
            const { id } = action.payload;

            if(!id.trim()) {
                return EMPTY;
            }

            return from(
                ChatRepository.findById( id )
                    .then(({ conversation, count }) => findChatConversationSuccess({ conversation, count }))
                    .catch(error => findChatConversationFailure(error)),
            );
        }),
    );
}
