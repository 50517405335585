export const FIND_EDOK_DOCUMENTATIONS_SUCCESS = 'FIND_EDOK_DOCUMENTATIONS_SUCCESS';

export const findEdokDocumentationsSuccess = ({ documentations, request, response }) => ({
    type: FIND_EDOK_DOCUMENTATIONS_SUCCESS,
    payload: { documentations },
    meta: {
        request,
        response,
    }
});
