import { DELETE_FILE_REQUEST, deleteFileSuccess, deleteFileFailure} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from, EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function deleteFileEpic(action$, store$, { FileRepository }) {
    return action$.pipe(
        ofType(DELETE_FILE_REQUEST),
        mergeMap( action =>{
            const { guid } = action.payload;

            if(!guid.trim()) {
                return EMPTY;
            }

            return from(
                FileRepository.deleteFileReferenceByGuid( guid )
                    .then(() => deleteFileSuccess())
                    .catch(error => deleteFileFailure(error)),
            );
        }),
    );
}
