import CareProvider from "Domain/Model/CareProvider/CareProvider";
import { KH, HAS_ACCESS_GRANTS } from "AppConfig/Config";

export default class Employee {

    constructor( data  ) {

        this.id           = data.id;
        this.mail         = data.mail;
        this.title        = data.title;
        this.firstName    = data.firstName;
        this.lastName     = data.lastName;
        this.name         = ``;
        if(data.title) {
            this.name += `${data.title} `;
        }
        if(data.firstName) {
            this.name += `${data.firstName} `;
        }
        if(data.lastName) {
            this.name += `${data.lastName}`;
        }
        this.phone         = data.phone;
        this.validSince    = (data.validSince) ? new Date(data.validSince) : null;
        this.validTo       = data.validTo;
        this.lastLogin     = (data.lastLogin) ? new Date(data.lastLogin) : null;
        this.role          = (data.employeeRole) ? data.employeeRole.id : KH;
        this.roleName      = (data.employeeRole) ? data.employeeRole.name : 'Krankenhaus';
        this.careProviders = data.careProviders ? data.careProviders.map(careProvider => new CareProvider(careProvider || {})) : [];
        this.modules       = data.modules;
        this.pseudonym     = data.pseudonym;

        if(HAS_ACCESS_GRANTS.includes(this.role)) {
            this.accessGrants           = {};
            data.accessGrants?.forEach(accessGrant => {
                this.accessGrants[accessGrant.key] = accessGrant;
            })
            this.createProcessStatement = data.createProcessStatement || 'SEND';
            this.answerProcessStatement = data.answerProcessStatement || 'SEND';
        }


    }

}
