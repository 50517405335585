import { KH, VA, LAG_GS, LAG_FK, LAG_LG }   from "AppConfig/Config";
import Module       from "Domain/Model/Module/Module";
import CareProvider from "Domain/Model/CareProvider/CareProvider";
import Pseudonym    from "Domain/Model/Pseudonym/Pseudonym";

export default class Document {

    constructor( data  ) {

        this.id               = data.id           || 'new';
        this.title            = data.title        || '';
        this.originalname     = data.originalname || '';
        this.mimetype         = data.mimetype     || '';
        this.username         = data.username     || '';
        this.size             = data.size         || 0;
        this.uploadDate       = (data.uploadDate) ? new Date(data.uploadDate) : new Date();
        this.expireDate       = (data.expireDate) ? new Date(data.expireDate) : null;
        this.lastDownloadDate = (data.lastDownloadDate) ? new Date(data.lastDownloadDate) : null;
        this.createdAt        = data.createdAt;
        this.careProviders    = data.careProviders || null;
        this.employee         = data.employee      || null;
        this.accessRoleId     = data.accessRoleId;
        this.accessRole       = null;
        this.access           = data.access;
        this.accessLabel      = 'Alle LE';
        this.module           = (data.module) ? new Module(data.module) : null;
        this.careProvider     = (data.careProvider) ? new CareProvider(data.careProvider) : null;
        this.pseudonym        = (data.pseudonym) ? new Pseudonym(data.pseudonym) : null;

        switch(this.accessRoleId) {
            case  0 : this.accessRole = 'Alle LE'; break;
            case KH : this.accessRole = 'Alle KH'; break;
            case VA : this.accessRole = 'Alle VÄ'; break;
            case LAG_GS : this.accessRole = 'Geschäftsstelle'; break;
            case LAG_LG : this.accessRole = 'Lenkungsgremium'; break;
            case LAG_FK : this.accessRole = 'Fachkommission'; break;
            default : break;
        }

        let identNumber;

        if(!!this.careProvider) {
            if(this.careProvider.identNumber) {
                identNumber = this.careProvider.identNumber;
            }
            if(this.careProvider.destinationKey) {
                if(!!identNumber) {
                    identNumber += ' ';
                }
                identNumber += this.careProvider.destinationKey;
            }
        }

        switch(this.access) {
            case 'SPECIFIC_CARE_PROVIDER_AND_MODULE' : this.accessLabel = `Ident. + Verfahren`; break;
            case 'ALL_VA_AND_MODULE'      : this.accessLabel = `Alle VÄ + Verfahren`; break;
            case 'ALL_KH_AND_MODULE'      : this.accessLabel = `Alle KH + Verfahren`; break;
            case 'SPECIFIC_MODULE'        : this.accessLabel = `Alle LE + Verfahren`; break;
            case 'SPECIFIC_CARE_PROVIDER' : this.accessLabel = `Ident.`; break;
            case 'SPECIFIC_PSEUDONYM'     : this.accessLabel = `Pseudonym`; break;
            case 'ALL_VA'                 : this.accessLabel = `Alle VÄ`; break;
            case 'ALL_KH'                 : this.accessLabel = `Alle KH`; break;
            case 'ALL_LAG_GS'             : this.accessLabel = `Geschäftsstelle`; break;
            case 'ALL_LAG_FK'             : this.accessLabel = `Fachkommission`; break;
            case 'ALL_LAG_LG'             : this.accessLabel = `Lenkungsgremium`; break;
            default : this.accessLabel = 'Alle LE'; break;
        }


    }

}
