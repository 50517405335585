import styled from 'styled-components';

export default styled.span `
  
    &.transparentButton a {
        display: inline-block;
        width: 50px;
        height: 40px;
        vertical-align: top;
        line-height: 40px;
        margin-right: 12px;
    }
    
`;