import isEmpty from 'lodash/isEmpty';

export const argumentsToQuery = (params = {}) => {
    if(isEmpty(params)) {
        return null;
    }

    let queryString = '';
    for(const key in params) {
        const value = params[key];
        queryString += `${key}=${value}&`;
    }

    return queryString.slice(0, -1);
};

