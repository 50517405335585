import React, { Component, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Wrapper from './Sideboard.style';
import { linkHandler } from 'ClientService';
import { Menu } from 'antd';
import Icon from 'Common/Icon/Icon';
import Link from 'Common/Link/Link';
import { WINDOW_TYPES } from 'AppConfig/Config/WindowTypes';
import { logoutUser } from 'Redux/Action';
import isDifferent                                                                                       from 'ClientService/IsDifferent/IsDifferent';
import { getActiveMenuTab, userContainsRole, isGhostUser, getDisabledSites, getServerSettings, getUser } from "Redux/Query";

class Sideboard extends Component {

    constructor(props) {
        super(props);

        this.sideBoard = linkHandler.getSideBoard();

        this.state = {
            collapsed: (props.windowMode !== WINDOW_TYPES.DESKTOP),
            selected: this.sideBoard[0].key,
        };
    }

    componentDidMount() {
        this.setState(() => ({ selected: this.props.activeTab }));
    }

    componentDidUpdate(prevProps) {
        const { activeTab } = prevProps;
        if(isDifferent(activeTab, this.props.activeTab)) {
            this.setState(() => ({ selected: this.props.activeTab }));
        }
    }

    handleLogout = evt => {
        evt.preventDefault();
        this.props.logout();
    };

    onChangeHandler = ({ key }) => {
        this.setState(() => ({ selected: key }));
    };

    validateUserAccess = (menuEntry) => {
        if(!menuEntry.visibleForGhostUser && !!this.props.isGhostUser) {
            return false;
        }
        if(!menuEntry.hasOwnProperty('access')) {
            return true;
        }
        return menuEntry.access.hasOwnProperty('groups') && this.props.userContainsRole(menuEntry.access.groups);
    };

    renderMenuEntries() {

        if(this.props.skeleton) {
            return {};
        }

        const topMenu = [];
        const bottomMenu = [];

        this.sideBoard
            .filter(entry => {
                if(entry.hasOwnProperty('customVisibleCallback') && typeof entry.customVisibleCallback === 'function') {
                    const result = entry.customVisibleCallback(entry, this.props);

                    if(!result) { return false }
                }

                return !(!this.validateUserAccess(entry) || this.props.disabledSites.hasOwnProperty(entry.key));

            })
            .forEach( entry => {
                let toAdd = topMenu;
                if(entry?.sideBoard?.bottom) {
                    toAdd = bottomMenu;
                }

                if(entry.key === 'logout') {
                    return toAdd.push(
                        {
                            key: entry.key,
                            className: "logoutButton",
                            label: (
                                <Link to={linkHandler.get(entry.key)} onClick={ this.handleLogout }>
                                    {(entry.icon) ? <Icon type={entry.icon} />  : null }
                                    <span>{entry.label}</span>
                                </Link>
                            )
                        }
                    );
                }

                const renderItem = (entry) => {
                    let SideboardAdditionalComponent = null;
                    if(entry.sideBoard.hasOwnProperty('render')) {
                        SideboardAdditionalComponent = entry.sideBoard.render
                    }
                    if(SideboardAdditionalComponent && entry.sideBoard.defaultRendererDisabled) {
                        return (
                            <Suspense fallback={ null } key={`renderItem_${entry.key}`}>
                                <SideboardAdditionalComponent />
                            </Suspense>
                        );
                    }
                    return (
                        <React.Fragment key={`renderItem_${entry.key}`}>
                            {(entry.icon) ? <Icon type={entry.icon} />  : null }
                            <span>{entry.label} { SideboardAdditionalComponent ?
                                <Suspense fallback={ null }>
                                    <SideboardAdditionalComponent />
                                </Suspense>
                                : null }
                            </span>
                        </React.Fragment>
                    );
                }
                if(entry.sideBoard.defaultRendererDisabled) {
                    return toAdd.push({
                        key: entry.key,
                        label: renderItem(entry),
                    });
                }
                toAdd.push({
                    key: entry.key,
                    label: entry.externalLink ? (
                        <a href={ entry.externalLink } target="_blank" rel="noopener noreferrer">
                            { renderItem(entry) }
                        </a>
                    ) : (
                        <Link to={linkHandler.get(entry.key, entry.defaultArgs || {})}>
                            { renderItem(entry) }
                        </Link>
                    ),
                });
            });

        return { topMenu, bottomMenu };
    }

    render() {
        const { topMenu, bottomMenu } = this.renderMenuEntries();

        return (
            <Wrapper width="256">
                <div className="sidebarLogo">
                    <img src={ this.props.logoUrl } alt="Logo" />
                    <h3 className="txtWhite">QS-Portal</h3>
                </div>
                <div className="sidebarMenuWrapper">
                    <Menu
                        selectedKeys={[ this.state.selected ]}
                        onClick={ this.onChangeHandler }
                        items={ topMenu }
                    />
                    <hr width="100%"/>
                    <Menu
                        selectedKeys={[ this.state.selected ]}
                        onClick={ this.onChangeHandler }
                        className="bottomMenu"
                        items={ bottomMenu }
                    />
                </div>
            </Wrapper>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: getUser(state),
        isGhostUser: isGhostUser(state),
        activeTab: getActiveMenuTab(state),
        userContainsRole: userContainsRole(state),
        logoUrl: getServerSettings(state).logo,
        disabledSites: getDisabledSites(state),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            logout: logoutUser,

        },
        dispatch,
    );
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
};


export default connect(mapStateToProps, mapDispatchToProps,  mergeProps)(Sideboard);
