const getInitialState = () => ({
    openRequests: [],
    failedRequests: [],
    updatedAt: 0,
    mailTemplates: {
        entries: {},
        maxCount: 0,
    },
    indicatorIds: [],
    lastInteractiveId: null,
});

export default {
    getInitialState
};
