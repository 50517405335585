import { List }                                from 'antd';
import Icon                                    from 'Common/Icon/Icon';
import Link                                    from 'Common/Link/Link';
import Button                                  from 'Common/Button/Button';
import React                                   from "react";
import { useDispatch, useSelector }            from 'react-redux';
import {
    toggleZipMenu,
    removeZipFile,
    downloadFilesAsZipRequest
}                                              from 'Redux/Action';
import { getZipMenuVisibleState, getZipFiles } from 'Redux/Query';
import Style                                   from "./ZipDownload.style";

export const ZipDownload = ({ children }) => {

    const dispatch = useDispatch();
    const visible  = useSelector(getZipMenuVisibleState);
    const zipFiles = useSelector(getZipFiles);

    const handleOnClose = () => {
        dispatch(toggleZipMenu())
    }

    const handleOnDelete = (file) => () => {
        dispatch(removeZipFile(file));
    }

    const handleOnDownloadZip = () => {
        dispatch(downloadFilesAsZipRequest());
    }

    const renderHeader = () => (
        <div className="headerWrapper">
            ZIP Downloader
            <Button onClick={ handleOnDownloadZip }>
                <Icon type="download" /> Dateien in ZIP herunterladen
            </Button>
        </div>
    )

    return (
        <>
            { children }
            <Style
                title={ renderHeader() }
                placement="left"
                closable={ true }
                open={ visible }
                onClose={handleOnClose}
                width={ 550 }
            >
                <List
                    dataSource={zipFiles}
                    bordered
                    renderItem={item => (
                        <List.Item actions={[
                            <div key="downloadButton" className="inlineBlock valignTop">
                                <Link className="transparentButton" externalLink={ item.url }>
                                    <Icon type="download" />
                                </Link>
                            </div>,
                            <div key="deleteButton" className="inlineBlock valignTop">
                                <Button danger onClick={ handleOnDelete(item) }>
                                    <Icon type="delete" />
                                </Button>
                            </div>,
                        ]}>
                            {item.name} - {item.modelType}
                        </List.Item>
                    )}
                >


                </List>
            </Style>
        </>
    );

}
