export const FIND_MODULES_REQUEST = 'FIND_MODULES_REQUEST';

export const findModulesRequest = ({
     filter = [],
     sorting = { field: 'id', order: 'ASC' },
     pagination = { page: 1, take: 50 },
 }) => ({
    type: FIND_MODULES_REQUEST,
    payload: { filter, sorting, pagination },
    meta: {
        sorting,
        pagination,
    }
});
