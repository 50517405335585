import {
    GET_EDOK_FORM_BY_MODULE_REQUEST,
    getEdokFormByModuleSuccess,
    getEdokFormByModuleFailure
}                      from 'Redux/Action';
import { ofType }      from 'redux-observable';
import { from }        from 'rxjs';
import { mergeMap }    from 'rxjs/operators';

export function getEdokFormByModuleEpic(action$, store$, { EdokRepository }) {
    return action$.pipe(
        ofType(GET_EDOK_FORM_BY_MODULE_REQUEST),
        mergeMap(action => {
            const { module, year, identNumber } = action.payload;

            return from(
                EdokRepository.findByModuleAndYear({ module, year, identNumber })
                    .then(form => getEdokFormByModuleSuccess(form))
                    .catch(error => getEdokFormByModuleFailure(error)),
            );
        }),
    );
}
