export const FIND_SPEC_YEARS_FAILURE = 'FIND_SPEC_YEARS_FAILURE';

export const findSpecYearsFailure = ({ error, request }) => ({
    type: FIND_SPEC_YEARS_FAILURE,
    payload: error,
    error: true,
    meta: {
        request
    }
});
