import { UPDATE_SERVER_SETTINGS_SUCCESS, showNotification } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

export function updateServerSettingsSuccessEpic(action$) {
    return action$.pipe(
        ofType(UPDATE_SERVER_SETTINGS_SUCCESS),
        mergeMap(() => {

            return of(showNotification('Die Speicherung der Systemeinstellungen war erfolgreich.'));
        })
    );
}
