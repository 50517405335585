import { message }              from 'antd';
import {
    UPDATE_EDOK_DOCUMENTATIONS_REQUEST,
    updateEdokDocumentationsSuccess,
    updateEdokDocumentationsFailure
}                               from 'Redux/Action';
import { ofType }               from 'redux-observable';
import { from }                 from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

export function updateEdokDocumentationsEpic(action$, store$, { EdokRepository }) {
    return action$.pipe(
        ofType(UPDATE_EDOK_DOCUMENTATIONS_REQUEST),
        mergeMap(action => from(EdokRepository.updateDocumentations(action.payload))
            .pipe(
                mergeMap(( documentations ) => {
                    message.success('Die EDOK-Einstellungen wurden erfolgreich aktualisiert');
                    return from([
                        updateEdokDocumentationsSuccess(documentations),
                    ]);
                }),
                catchError(error => {
                    return from([
                        updateEdokDocumentationsFailure(error),
                    ]);
                })
            )
        ),
    );
}
