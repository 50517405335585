import BaseRepository from '../Base/BaseRepository';
import Module from 'Domain/Model/Module/Module';

class ModuleRepository extends BaseRepository {

    findAllByCareProvider(careProviderId, filterSettings) {
        return this.setFilterSettings(filterSettings).makeRequest({
            path: `/${this.baseRoute}/care-provider/${careProviderId}`,
            data: this.getFilterSettings(),
            type: 'GET'
        });
    }

}

export default new ModuleRepository({
    model: Module,
    baseRoute: 'module',
    singular: 'module',
    plural: 'modules',
});
