const getInitialState = () => ({
    openRequests: [],
    failedRequests: [],
    updatedAt: 0,
    forms: {
        entries: {},
        maxCount: 0,
    },
    activeEdokForm: null,
    year: null,
    availableModules: [],
    availableForms: {},
    lastInteractiveGuid: null,
    documentations: {
        nwies: null,
        nwiea: null,
    }
});

export default {
    getInitialState
};
