export const FIND_OPEN_ANSWER_PROCESS_STATEMENTS_SUCCESS = 'FIND_OPEN_ANSWER_PROCESS_STATEMENTS_SUCCESS';

export const findOpenAnswerProcessStatementsSuccess = ({ processStatements, request, response }) => ({
    type: FIND_OPEN_ANSWER_PROCESS_STATEMENTS_SUCCESS,
    payload: { processStatements },
    meta: {
        request,
        response,
    }
});
