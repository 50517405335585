import Result           from 'Domain/Model/Result/Result';
import BaseRepository   from '../Base/BaseRepository';
import ProcessStatement from 'Domain/Model/ProcessStatement/ProcessStatement';

class ProcessStatementRepository extends BaseRepository {

    filterSettings = {};

    getOpenRequestCount() {
        return this.makeRequest({
            path: `/${this.baseRoute}/request-inbox/count`,
            type: 'GET',
            callback: (response) => {
                return { poolRequestInboxCount: response.inboxCount };
            }
        });
    }

    getOpenAnswerCount() {
        return this.makeRequest({
            path: `/${this.baseRoute}/answer-inbox/count`,
            type: 'GET',
            callback: (response) => {
                return { inboxCount: response.inboxCount };
            }
        });
    }

    findAllFromInbox(filterSettings = {}) {
        return this.setFilterSettings(filterSettings).makeRequest({
            path: `/${this.baseRoute}/request-inbox`,
            data: this.getFilterSettings(),
            type: 'GET'
        });
    }

    findAllOpenAnsweredByUser(filterSettings = {}) {
        return this.setFilterSettings(filterSettings).makeRequest({
            path: `/${this.baseRoute}/answer-inbox`,
            data: this.getFilterSettings(),
            type: 'GET'
        });
    }

    findOpenById(id) {
        return this.makeRequest({
            path: `/${this.baseRoute}/request-inbox/${id}`,
            type: 'GET',
            callback: this.initializeSingleModel
        });
    }

    findResultsById(id) {
        return this.makeRequest({
            path: `/${this.baseRoute}/${id}/results`,
            type: 'GET',
            callback: (responseObject) => ({
                statement: new ProcessStatement(responseObject.statement),
                results: responseObject.results.map(result => new Result(result)),
            })
        });
    }


    updateInbox( id = null, data ) {
        return this.makeRequest({
            path: `/${this.baseRoute}/request-inbox/${id}`,
            data,
            type: 'PATCH',
            callback: this.initializeSingleModel
        });
    }

    downloadRtfFile( id = null ) {
        return this.makeRequest({
            path: `/${this.baseRoute}/${id}/download-rtf-file`,
            type: 'GET',
            callback: (_, response) => {
                return {
                    fileName: response.headers['x-file-name'],
                    file: response.data,
                }
            }
        }, false);
    }

    dismissAnswer({ id = null, deleteAnswer, reason, toAnswerDate, toReminderDate, toSubsequentDeliveryDate } ) {
        return this.makeRequest({
            path: `/${this.baseRoute}/dismiss-answer/${id}`,
            data: {
                deleteAnswer,
                reason,
                toAnswerDate,
                toReminderDate,
                toSubsequentDeliveryDate
            },
            type: 'DELETE',
            callback: this.initializeSingleModel
        });
    }

    markAsRead( id = null ) {
        return this.makeRequest({
            path: `/${this.baseRoute}/read/${id}`,
            type: 'PATCH',
            callback: this.initializeSingleModel
        });
    }

    getLastDeadlineDates( proceeding ) {
        return this.makeRequest({
            path    : `/${this.baseRoute}/last-deadline-dates`,
            data    : { proceeding },
            type    : 'GET',
            callback: (response) => response,
        });
    }

    submit( id = null, { submitInformation = null }) {
        return this.makeRequest({
            path: `/${this.baseRoute}/submit/${id}`,
            type: 'PATCH',
            data: {
                submitInformation,
            },
            callback: this.initializeSingleModel
        });
    }

}

export default new ProcessStatementRepository({
    model: ProcessStatement,
    baseRoute: 'process-statement',
    singular: 'statement',
    plural: 'statements',
});
