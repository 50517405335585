export const FIND_MAIL_TEMPLATES_SUCCESS = 'FIND_MAIL_TEMPLATES_SUCCESS';

export const findMailTemplatesSuccess = ({ mailTemplates, request, response }) => ({
    type: FIND_MAIL_TEMPLATES_SUCCESS,
    payload: { mailTemplates },
    meta: {
        request,
        response,
    }
});
