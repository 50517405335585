export const isGlobalStateEqual = (prevStore, nextStore) =>
    prevStore.global.updatedAt === nextStore.global.updatedAt;
export const isUserStateEqual = (prevStore, nextStore) =>
    prevStore.user.updatedAt === nextStore.user.updatedAt;
export const isFileStateEqual = (prevStore, nextStore) =>
    prevStore.file.updatedAt === nextStore.file.updatedAt;
export const isEmployeeStateEqual = (prevStore, nextStore) =>
    prevStore.employee.updatedAt === nextStore.employee.updatedAt;
export const isEmployeeRoleStateEqual = (prevStore, nextStore) =>
    prevStore.employeeRole.updatedAt === nextStore.employeeRole.updatedAt;
export const isCareProviderStateEqual = (prevStore, nextStore) =>
    prevStore.careProvider.updatedAt === nextStore.careProvider.updatedAt;
export const isDocumentStateEqual = (prevStore, nextStore) =>
    prevStore.document.updatedAt === nextStore.document.updatedAt;
export const isModuleStateEqual = (prevStore, nextStore) =>
    prevStore.module.updatedAt === nextStore.module.updatedAt;
export const isResultStateEqual = (prevStore, nextStore) =>
    prevStore.result.updatedAt === nextStore.result.updatedAt;
export const isPoolRequestStateEqual = (prevStore, nextStore) =>
    prevStore.poolRequest.updatedAt === nextStore.poolRequest.updatedAt;
export const isPersistStoreStateEqual = (prevStore, nextStore) =>
    prevStore.persistStore.updatedAt === nextStore.persistStore.updatedAt;
export const isProcessStatementStateEqual = (prevStore, nextStore) =>
    prevStore.processStatement.updatedAt === nextStore.processStatement.updatedAt;
export const isPseudonymStateEqual = (prevStore, nextStore) =>
    prevStore.pseudonym.updatedAt === nextStore.pseudonym.updatedAt;
export const isTargetActualStateEqual = (prevStore, nextStore) =>
    prevStore.targetActual.updatedAt === nextStore.targetActual.updatedAt;
export const isStatementTemplateStateEqual = (prevStore, nextStore) =>
    prevStore.statementTemplate.updatedAt === nextStore.statementTemplate.updatedAt;
export const isResultRatingStateEqual = (prevStore, nextStore) =>
    prevStore.resultRating.updatedAt === nextStore.resultRating.updatedAt;
export const isReportStateEqual = (prevStore, nextStore) =>
    prevStore.report.updatedAt === nextStore.report.updatedAt;