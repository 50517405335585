export const DASHBOARD_PAGE                       = 'DASHBOARD_PAGE';
export const PROFILE_PAGE                         = 'PROFILE_PAGE';
export const FILE_DETAIL_PAGE                     = 'FILE_DETAIL_PAGE';
export const EMPLOYEE_OVERVIEW_PAGE               = 'EMPLOYEE_OVERVIEW_PAGE';
export const EMPLOYEE_DETAIL_PAGE                 = 'EMPLOYEE_DETAIL_PAGE';
export const CARE_PROVIDER_OVERVIEW_PAGE          = 'CARE_PROVIDER_OVERVIEW_PAGE';
export const CARE_PROVIDER_DETAIL_PAGE            = 'CARE_PROVIDER_DETAIL_PAGE';
export const DOCUMENT_UPLOAD_PAGE                 = 'DOCUMENT_UPLOAD_PAGE';
export const DOCUMENT_DETAIL_PAGE                 = 'DOCUMENT_DETAIL_PAGE';
export const REPORT_OVERVIEW_PAGE                 = 'REPORT_OVERVIEW_PAGE';
export const RESULTS_OVERVIEW_PAGE                = 'RESULTS_OVERVIEW_PAGE';
export const RESULTS_DETAIL_PAGE                  = 'RESULTS_DETAIL_PAGE';
export const POOL_REQUEST_OVERVIEW_PAGE           = 'POOL_REQUEST_OVERVIEW_PAGE';
export const POOL_REQUEST_DETAIL_PAGE             = 'POOL_REQUEST_DETAIL_PAGE';
export const SEND_POOL_REQUEST_PAGE               = 'SEND_POOL_REQUEST_PAGE';
export const UPDATE_POOL_REQUEST_PAGE             = 'UPDATE_POOL_REQUEST_PAGE';
export const PROCESS_STATEMENT_OVERVIEW_PAGE      = 'PROCESS_STATEMENT_OVERVIEW_PAGE';
export const RESULT_PROCESS_STATEMENT_DETAIL_PAGE = 'RESULT_PROCESS_STATEMENT_DETAIL_PAGE';
export const PSEUDONYM_OVERVIEW_PAGE              = 'PSEUDONYM_OVERVIEW_PAGE';
export const PSEUDONYM_DETAIL_PAGE                = 'PSEUDONYM_DETAIL_PAGE';
export const SETTINGS_DETAIL_PAGE                 = 'SETTINGS_DETAIL_PAGE';
export const POOL_REQUEST_INBOX_PAGE              = 'POOL_REQUEST_INBOX_PAGE';
export const POOL_REQUEST_ANSWERED_INBOX_PAGE     = 'POOL_REQUEST_ANSWERED_INBOX_PAGE';
export const TARGET_ACTUAL_OVERVIEW_PAGE          = 'TARGET_ACTUAL_OVERVIEW_PAGE';
export const EDOK_OVERVIEW_PAGE                   = 'EDOK_OVERVIEW_PAGE';
export const EDOK_DETAIL_PAGE                     = 'EDOK_DETAIL_PAGE';
export const CHAT_OVERVIEW_PAGE                   = 'CHAT_OVERVIEW_PAGE';
export const CHAT_DETAIL_PAGE                     = 'CHAT_DETAIL_PAGE';


export const DASHBOARD_PAGE_APPEAR                       = 'DASHBOARD_PAGE_APPEAR';
export const PROFILE_PAGE_APPEAR                         = 'PROFILE_PAGE_APPEAR';
export const FILE_DETAIL_PAGE_APPEAR                     = 'FILE_DETAIL_PAGE_APPEAR';
export const EMPLOYEE_DETAIL_PAGE_APPEAR                 = 'EMPLOYEE_DETAIL_PAGE_APPEAR';
export const EMPLOYEE_OVERVIEW_PAGE_APPEAR               = 'EMPLOYEE_OVERVIEW_PAGE_APPEAR';
export const CARE_PROVIDER_DETAIL_PAGE_APPEAR            = 'CARE_PROVIDER_DETAIL_PAGE_APPEAR';
export const CARE_PROVIDER_OVERVIEW_PAGE_APPEAR          = 'CARE_PROVIDER_OVERVIEW_PAGE_APPEAR';
export const DOCUMENT_UPLOAD_PAGE_APPEAR                 = 'DOCUMENT_UPLOAD_PAGE_APPEAR';
export const DOCUMENT_DETAIL_PAGE_APPEAR                 = 'DOCUMENT_DETAIL_PAGE_APPEAR';
export const REPORT_OVERVIEW_PAGE_APPEAR                 = 'REPORT_OVERVIEW_PAGE_APPEAR';
export const RESULTS_OVERVIEW_PAGE_APPEAR                = 'RESULTS_OVERVIEW_PAGE_APPEAR';
export const RESULTS_DETAIL_PAGE_APPEAR                  = 'RESULTS_DETAIL_PAGE_APPEAR';
export const POOL_REQUEST_OVERVIEW_PAGE_APPEAR           = 'POOL_REQUEST_OVERVIEW_PAGE_APPEAR';
export const POOL_REQUEST_DETAIL_PAGE_APPEAR             = 'POOL_REQUEST_DETAIL_PAGE_APPEAR';
export const SEND_POOL_REQUEST_PAGE_APPEAR               = 'SEND_POOL_REQUEST_PAGE_APPEAR';
export const UPDATE_POOL_REQUEST_PAGE_APPEAR             = 'UPDATE_POOL_REQUEST_PAGE_APPEAR';
export const PROCESS_STATEMENT_OVERVIEW_PAGE_APPEAR      = 'PROCESS_STATEMENT_OVERVIEW_PAGE_APPEAR';
export const RESULT_PROCESS_STATEMENT_DETAIL_PAGE_APPEAR = 'RESULT_PROCESS_STATEMENT_DETAIL_PAGE_APPEAR';
export const PSEUDONYM_OVERVIEW_PAGE_APPEAR              = 'PSEUDONYM_OVERVIEW_PAGE_APPEAR';
export const PSEUDONYM_DETAIL_PAGE_APPEAR                = 'PSEUDONYM_DETAIL_PAGE_APPEAR';
export const SETTINGS_DETAIL_PAGE_APPEAR                 = 'SETTINGS_DETAIL_PAGE_APPEAR';
export const POOL_REQUEST_INBOX_PAGE_APPEAR              = 'POOL_REQUEST_INBOX_PAGE_APPEAR';
export const POOL_REQUEST_ANSWERED_INBOX_PAGE_APPEAR     = 'POOL_REQUEST_ANSWERED_INBOX_PAGE_APPEAR';
export const TARGET_ACTUAL_OVERVIEW_PAGE_APPEAR          = 'TARGET_ACTUAL_OVERVIEW_PAGE_APPEAR';
export const EDOK_OVERVIEW_PAGE_APPEAR                   = 'EDOK_OVERVIEW_PAGE_APPEAR';
export const EDOK_DETAIL_PAGE_APPEAR                     = 'EDOK_DETAIL_PAGE_APPEAR';
export const CHAT_OVERVIEW_PAGE_APPEAR                   = 'CHAT_OVERVIEW_PAGE_APPEAR';
export const CHAT_DETAIL_PAGE_APPEAR                     = 'CHAT_DETAIL_PAGE_APPEAR';


export const DASHBOARD_PAGE_DISAPPEAR                       = 'DASHBOARD_PAGE_DISAPPEAR';
export const PROFILE_PAGE_DISAPPEAR                         = 'PROFILE_PAGE_DISAPPEAR';
export const FILE_DETAIL_PAGE_DISAPPEAR                     = 'FILE_DETAIL_PAGE_DISAPPEAR';
export const EMPLOYEE_DETAIL_PAGE_DISAPPEAR                 = 'EMPLOYEE_DETAIL_PAGE_DISAPPEAR';
export const EMPLOYEE_OVERVIEW_PAGE_DISAPPEAR               = 'EMPLOYEE_OVERVIEW_PAGE_DISAPPEAR';
export const CARE_PROVIDER_DETAIL_PAGE_DISAPPEAR            = 'CARE_PROVIDER_DETAIL_PAGE_DISAPPEAR';
export const CARE_PROVIDER_OVERVIEW_PAGE_DISAPPEAR          = 'CARE_PROVIDER_OVERVIEW_PAGE_DISAPPEAR';
export const DOCUMENT_UPLOAD_PAGE_DISAPPEAR                 = 'DOCUMENT_UPLOAD_PAGE_DISAPPEAR';
export const DOCUMENT_DETAIL_PAGE_DISAPPEAR                 = 'DOCUMENT_DETAIL_PAGE_DISAPPEAR';
export const REPORT_OVERVIEW_PAGE_DISAPPEAR                 = 'REPORT_OVERVIEW_PAGE_DISAPPEAR';
export const RESULTS_OVERVIEW_PAGE_DISAPPEAR                = 'RESULTS_OVERVIEW_PAGE_DISAPPEAR';
export const RESULTS_DETAIL_PAGE_DISAPPEAR                  = 'RESULTS_DETAIL_PAGE_DISAPPEAR';
export const POOL_REQUEST_OVERVIEW_PAGE_DISAPPEAR           = 'POOL_REQUEST_OVERVIEW_PAGE_DISAPPEAR';
export const POOL_REQUEST_DETAIL_PAGE_DISAPPEAR             = 'POOL_REQUEST_DETAIL_PAGE_DISAPPEAR';
export const SEND_POOL_REQUEST_PAGE_DISAPPEAR               = 'SEND_POOL_REQUEST_PAGE_DISAPPEAR';
export const UPDATE_POOL_REQUEST_PAGE_DISAPPEAR             = 'UPDATE_POOL_REQUEST_PAGE_DISAPPEAR';
export const PROCESS_STATEMENT_OVERVIEW_PAGE_DISAPPEAR      = 'PROCESS_STATEMENT_OVERVIEW_PAGE_DISAPPEAR';
export const RESULT_PROCESS_STATEMENT_DETAIL_PAGE_DISAPPEAR = 'RESULT_PROCESS_STATEMENT_DETAIL_PAGE_DISAPPEAR';
export const PSEUDONYM_OVERVIEW_PAGE_DISAPPEAR              = 'PSEUDONYM_OVERVIEW_PAGE_DISAPPEAR';
export const PSEUDONYM_DETAIL_PAGE_DISAPPEAR                = 'PSEUDONYM_DETAIL_PAGE_DISAPPEAR';
export const SETTINGS_DETAIL_PAGE_DISAPPEAR                 = 'SETTINGS_DETAIL_PAGE_DISAPPEAR';
export const POOL_REQUEST_INBOX_PAGE_DISAPPEAR              = 'POOL_REQUEST_INBOX_PAGE_DISAPPEAR';
export const POOL_REQUEST_ANSWERED_INBOX_PAGE_DISAPPEAR     = 'POOL_REQUEST_ANSWERED_INBOX_PAGE_DISAPPEAR';
export const TARGET_ACTUAL_OVERVIEW_PAGE_DISAPPEAR          = 'TARGET_ACTUAL_OVERVIEW_PAGE_DISAPPEAR';
export const EDOK_OVERVIEW_PAGE_DISAPPEAR                   = 'EDOK_OVERVIEW_PAGE_DISAPPEAR';
export const EDOK_DETAIL_PAGE_DISAPPEAR                     = 'EDOK_DETAIL_PAGE_DISAPPEAR';
export const CHAT_OVERVIEW_PAGE_DISAPPEAR                   = 'CHAT_OVERVIEW_PAGE_DISAPPEAR';
export const CHAT_DETAIL_PAGE_DISAPPEAR                     = 'CHAT_DETAIL_PAGE_DISAPPEAR';
