const getInitialState = () => ({
    openRequests: [],
    failedRequests: [],
    updatedAt: 0,
    employees: {
        entries: {},
        maxCount: 0,
    },
    lastInteractiveId: null,
});

export default {
    getInitialState
};
