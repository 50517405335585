import { ArrayContains } from 'ClientService';
import {
    DISABLE_USER_FAILURE,
    FORGOT_PASSWORD_FAILURE,
    RESET_PASSWORD_FAILURE,
    UPDATE_USER_FAILURE,
} from 'Redux/Action';

export const isUserFailed = (state) =>
    ArrayContains(state.user.failedRequests, [
        DISABLE_USER_FAILURE,
        FORGOT_PASSWORD_FAILURE,
        RESET_PASSWORD_FAILURE,
        UPDATE_USER_FAILURE
    ]);



