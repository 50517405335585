import {
    FIND_CHAT_CONVERSATION_REQUEST,
    FIND_CHAT_CONVERSATIONS_REQUEST,
    CREATE_NEW_CHAT_CONVERSATION_REQUEST,
    ADD_NEW_CHAT_MESSAGE_REQUEST,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isChatConversationLoading = (state) =>
    ArrayContains(state.chat.openRequests, [
        FIND_CHAT_CONVERSATION_REQUEST,
        FIND_CHAT_CONVERSATIONS_REQUEST,
        CREATE_NEW_CHAT_CONVERSATION_REQUEST,
        ADD_NEW_CHAT_MESSAGE_REQUEST,
    ]);
