import {
    CREATE_CARE_PROVIDER_REQUEST,
    CREATE_CARE_PROVIDER_SUCCESS,
    CREATE_CARE_PROVIDER_FAILURE,

    UPDATE_CARE_PROVIDER_REQUEST,
    UPDATE_CARE_PROVIDER_SUCCESS,
    UPDATE_CARE_PROVIDER_FAILURE,

    FIND_CARE_PROVIDER_REQUEST,
    FIND_CARE_PROVIDER_SUCCESS,
    FIND_CARE_PROVIDER_FAILURE,

    FIND_CARE_PROVIDERS_REQUEST,
    FIND_CARE_PROVIDERS_SUCCESS,
    FIND_CARE_PROVIDERS_FAILURE,

    DE_PSEUDO_CARE_PROVIDER_REQUEST,
    DE_PSEUDO_CARE_PROVIDER_SUCCESS,
    DE_PSEUDO_CARE_PROVIDER_FAILURE,

    MASS_DE_PSEUDO_REQUEST,
    MASS_DE_PSEUDO_SUCCESS,
    MASS_DE_PSEUDO_FAILURE,

    GET_ENCRYPTED_PSEUDONYMS_REQUEST,
    GET_ENCRYPTED_PSEUDONYMS_SUCCESS,
    GET_ENCRYPTED_PSEUDONYMS_FAILURE,

    LOGOUT_USER,
    DISABLE_USER_SUCCESS,
    AUTHENTICATE_FAILURE,

} from 'Redux/Action';
import CareProviderState from 'Redux/Store/CareProvider/CareProviderState';
import { removeOpenRequest, removeFailedRequest, addSingleObjectToReducer, getSingleObject } from 'ClientService/ReducerUtil/ReducerUtil';

const initialState = CareProviderState.getInitialState();

export const careProviderReducer = (state = initialState, action) => {

    switch (action.type) {

    case AUTHENTICATE_FAILURE :
    case LOGOUT_USER :
    case DISABLE_USER_SUCCESS :
        return {
            ...initialState,
        };

    case MASS_DE_PSEUDO_REQUEST:
    case GET_ENCRYPTED_PSEUDONYMS_REQUEST:
    case CREATE_CARE_PROVIDER_REQUEST:
    case UPDATE_CARE_PROVIDER_REQUEST:
    case FIND_CARE_PROVIDER_REQUEST:
    case DE_PSEUDO_CARE_PROVIDER_REQUEST:
    case FIND_CARE_PROVIDERS_REQUEST:
        return {
            ...state,
            lastInteractiveId: null,
            openRequests: [...state.openRequests, action.type],
            failedRequests: removeFailedRequest(action, state),
            updatedAt: Date.now(),
        };

    case CREATE_CARE_PROVIDER_SUCCESS:
    case UPDATE_CARE_PROVIDER_SUCCESS:
    case FIND_CARE_PROVIDER_SUCCESS:
    case DE_PSEUDO_CARE_PROVIDER_SUCCESS:
        const careProvider = getSingleObject(action.payload.careProvider);

        return {
            ...state,
            lastInteractiveId: careProvider.id,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
            careProviders: {
                ...state.careProviders,
                entries: addSingleObjectToReducer(state.careProviders.entries, action.payload.careProvider)
            },
        };

    case FIND_CARE_PROVIDERS_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            careProviders: {
                entries: action.payload.careProviders,
                maxCount: (action.meta) ? action.meta.response.count : state.careProviders.maxCount,
            },
            updatedAt: Date.now(),
        };

    case GET_ENCRYPTED_PSEUDONYMS_SUCCESS:
    case MASS_DE_PSEUDO_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
        };

    case CREATE_CARE_PROVIDER_FAILURE:
    case MASS_DE_PSEUDO_FAILURE:
    case GET_ENCRYPTED_PSEUDONYMS_FAILURE:
    case UPDATE_CARE_PROVIDER_FAILURE:
    case FIND_CARE_PROVIDER_FAILURE:
    case DE_PSEUDO_CARE_PROVIDER_FAILURE:
    case FIND_CARE_PROVIDERS_FAILURE:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            failedRequests: [...state.failedRequests, action.type],
            updatedAt: Date.now(),
        };



    default:
        return state;
    }
};
