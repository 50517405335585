import { FIND_MODULES_BY_CARE_PROVIDER_REQUEST, findModulesByCareProviderSuccess, findModulesByCareProviderFailure} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function findModulesByCareProviderEpic(action$, store$, { ModuleRepository }) {
    return action$.pipe(
        ofType( FIND_MODULES_BY_CARE_PROVIDER_REQUEST ),
        mergeMap(action => {
            const { careProviderId, ...filterSettings } = action.payload;
            return from(ModuleRepository.findAllByCareProvider(careProviderId, filterSettings)
                .then(({ modules, meta }) => findModulesByCareProviderSuccess({ modules, response: meta, request: action}))
                .catch(error => findModulesByCareProviderFailure({ error, request: action }))
            )
        }),
    );
}
