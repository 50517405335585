import { UPDATE_CARE_PROVIDER_REQUEST, updateCareProviderSuccess, updateCareProviderFailure } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function updateCareProviderRequestEpic(action$, store$, { CareProviderRepository }) {
    return action$.pipe(
        ofType(UPDATE_CARE_PROVIDER_REQUEST),
        mergeMap(action => from(
            CareProviderRepository.update( action.payload.id, action.payload.updateData )
                .then(( careProvider ) => updateCareProviderSuccess({ careProvider }))
                .catch(error => updateCareProviderFailure(error)),
        )),
    );
}
