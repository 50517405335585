import { message }                                                                from 'antd';
import { CREATE_EDOK_FORM_REQUEST, createEdokFormFailure, createEdokFormSuccess } from 'Redux/Action';
import { ofType }                                                                 from 'redux-observable';
import { from }                                                                   from 'rxjs';
import { mergeMap }                                                               from 'rxjs/operators';

export function createEdokFormEpic(action$, store$, { EdokRepository }) {
    return action$.pipe(
        ofType(CREATE_EDOK_FORM_REQUEST),
        mergeMap(action => from(
            EdokRepository.create( action.payload )
                .then(( form ) => {
                    message.success('Der Bogen wurde erfolgreich gespeichert. Bitte beachten Sie, dass Sie ihn noch versenden müssen.');
                    return createEdokFormSuccess({ form })
                })
                .catch(error => createEdokFormFailure(error)),
        )),
    );
}
