import { GET_AVAILABLE_EDOK_MODULES_REQUEST, getAvailableEdokModulesSuccess, getAvailableEdokModulesFailure} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function getAvailableEdokModulesEpic(action$, store$, { EdokRepository }) {
    return action$.pipe(
        ofType(GET_AVAILABLE_EDOK_MODULES_REQUEST),
        mergeMap( () =>{
            return from(
                EdokRepository.getAvailableModules()
                    .then(({ modules, moduleForms }) => getAvailableEdokModulesSuccess({ modules, moduleForms }))
                    .catch(error => getAvailableEdokModulesFailure(error)),
            );
        }),
    );
}
