import { FormHandler } from 'ClientService';
import cloneDeep from 'lodash/cloneDeep';
import forEach from 'lodash/forEach';

export default function convertObjectToFormData(objectData, override) {

    const form = new FormHandler();
    const data = cloneDeep({ ...objectData });

    forEach(data, (value, key) => {
        if(typeof override === 'function') {
            const result = override(key, value, form);
            if(result) {
                return true;
            }
        }

        if(key === 'images') {
            form.addImages( value );
            return true;
        }
        if(key === 'image') {
            form.addImage( value );
            return true;
        }
        if(key === 'files') {
            form.addFiles( value );
            return true;
        }
        if(key === 'file') {
            form.addFile( value );
            return true;
        }

        // global keys will be ignored
        if(['id', 'createdAt', 'updatedAt'].includes(key)) {
            return false;
        }

        form.set(key, value);
    });

    return form.parseData();

}