import { message }              from 'antd';
import {
    SEND_EDOK_FORM_REQUEST,
    sendEdokFormFailure,
    sendEdokFormSuccess
}                               from 'Redux/Action';
import { ofType }               from 'redux-observable';
import { from }                 from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

export function sendEdokFormEpic(action$, store$, { EdokRepository }) {
    return action$.pipe(
        ofType(SEND_EDOK_FORM_REQUEST),
        mergeMap(action => from(EdokRepository.send(action.payload.id))
            .pipe(
                mergeMap(( form ) => {
                    message.success('Der Bogen wurde erfolgreich versendet');
                    return from([
                        sendEdokFormSuccess({ form }),
                    ]);
                }),
                catchError(error => {
                    return from([
                        sendEdokFormFailure(error),
                    ]);
                })
            )
        ),
    );
}
