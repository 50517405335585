import { lazy } from "react";

export const publicRoutes = {
    signin: {
        path: '/login',
        Component: lazy(() => (import('Page/Public/SignIn/SignIn'))),
    },
    forgotPassword: {
        path: '/passwort-vergessen',
        Component: lazy(() => (import('Page/Public/ForgotPassword/ForgotPassword'))),
    },
    register: {
        path: '/registrieren',
        Component: lazy(() => (import('Page/Public/Register/Register'))),
    },
    successRegister: {
        path: '/registrierung-abgesendet',
        Component: lazy(() => (import('Page/Public/SuccessRegister/SuccessRegister'))),
    },
    confirmEmail: {
        path: '/validate-mail',
        arguments: ['hash'],
        Component: lazy(() => (import('Page/Public/ValidateEmail/ValidateEmail'))),
    },
    activateAccount: {
        path: '/aktiviere-account',
        arguments: ['hash'],
        Component: lazy(() => (import('Page/Public/ActivateAccount/ActivateAccount'))),
    },
    networkError: {
        path: '/netzwerk-fehler',
        Component: lazy(() => (import('Page/Public/Error/NetworkError'))),
    },
    privacyPolicy: {
        path: '/datenschutz',
        Component: lazy(() => (import('Page/Public/PrivacyPolicy/PrivacyPolicy'))),
    },
    termsOfAgreements: {
        path: '/nutzung',
        Component: lazy(() => (import('Page/Public/TermsOfAgreement/TermsOfAgreement'))),
    },


    validateUser: {
        path: '/freischalten',
        arguments: ['hash'],
        Component: lazy(() => (import('Page/Public/ValidateUser/ValidateUser'))),
    },
    rejectUser: {
        path: '/ablehnen',
        arguments: ['hash'],
        Component: lazy(() => (import('Page/Public/RejectUser/RejectUser'))),
    },
    resetPassword: {
        path: '/reset-password',
        arguments: ['hash'],
        Component: lazy(() => (import('Page/Public/ResetPassword/ResetPassword'))),
    },

}
