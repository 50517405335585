import React                    from 'react';
import { useSelector }          from 'react-redux';
import { Tooltip }              from 'antd';
import Icon                     from 'Common/Icon/Icon';
import Wrapper                  from './AdditionalHeaderInformation.style';
import { getUser, isGhostUser } from 'Redux/Query';
import { formatDate }           from 'ClientService';

export const AdditionalHeaderInformation = () => {

    const user = useSelector(getUser);
    const ghostUser = useSelector(isGhostUser);
    const disableGhostAccount = new Date(user.validTo);

    if( !ghostUser || user.validTo === null ) {
        return null;
    }

    return (
        <Wrapper>
            <Icon type="lock" />
            <span>
                <Tooltip title="Ihr Zugang wird deaktiviert, da alle Stellungnahmen beantwortet wurden.">
                    Ihr Zugang wird am <b>{ formatDate(disableGhostAccount) }</b> deaktiviert.
                </Tooltip>
            </span>

        </Wrapper>
    );

};
