import { create } from 'apisauce';
import { ServerUrl } from 'AppConfig/Config';

const requestHeader = {
    'Content-Type': 'application/json; charset=utf-8',
};

const requestApi = create({
    baseURL: ServerUrl,
    headers: requestHeader,
});

requestApi.getHeader = () => {
    return requestApi.headers;
};

requestApi.resetHeader = (additionalHeaderData = {}) => {
    requestApi.setHeaders(Object.assign({}, requestHeader, additionalHeaderData));

    return requestApi;
};

requestApi.lastAccessToken  = null;
requestApi.lastRefreshToken = null;

export default requestApi;
