import { FIND_MODULE_REQUEST, findModuleSuccess, findModuleFailure} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from, EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function findModuleEpic(action$, store$, { ModuleRepository }) {
    return action$.pipe(
        ofType( FIND_MODULE_REQUEST ),
        mergeMap( action =>{
            const { id } = action.payload;

            if(!id.trim()) {
                return EMPTY;
            }

            return from(
                ModuleRepository.findById( id )
                    .then(module => findModuleSuccess(module))
                    .catch(error => findModuleFailure(error)),
            );
        }),
    );
}
