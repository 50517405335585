export const pageAppear = ( page, settings = null) => {
    settings = Object.assign(
        {},
        {
            payload: {},
            filter: [],
            pagination: { page: 1, take: 50 },
            options: { enhanceState: false, forceOverride: false }

        },
        (settings) ? { ...settings } : null

    );

    return {
        type: `${page}_APPEAR`,
        payload: settings.payload,
        meta: {
            pagination: settings.pagination,
            enhanceState: settings.options.enhanceState,
            forceOverride: settings.options.forceOverride,
        }
    }
};


