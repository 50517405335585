import {
    SEND_OPEN_POOL_REQUESTS_REQUEST,
    sendOpenPoolRequestsSuccess,
    sendOpenPoolRequestsFailure,
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import { message } from "antd";

export function sendOpenPoolRequestRequestsEpic(action$, store$, { PoolRequestRepository }) {
    return action$.pipe(
        ofType(SEND_OPEN_POOL_REQUESTS_REQUEST),
        mergeMap(action =>
            from( PoolRequestRepository.sendOpenPoolRequest( action.payload.ids )).pipe(
                mergeMap(() => {
                    message.success('Das Stellungnahmeverfahren wurde erfolgreich eingeleitet.');
                    return from([
                        sendOpenPoolRequestsSuccess(),
                    ])
                }),
                catchError( error => {

                    return from([
                        sendOpenPoolRequestsFailure(error),
                    ])
                })
            )
        )
    );
}
