import styled from "styled-components";
import { Drawer } from "antd";

export default styled(Drawer) `
    
    .headerWrapper {
        display: flex;
        justify-content: space-between;
        vertical-align: middle;
        align-items: center;
    }
    
    .ant-list-item {
        position: relative;
        padding-right: 120px;
        .ant-list-item-action {
            position: absolute;
            right: 2px;
            top: 3px;
            
            .transparentButton {
                a {
                    margin: 0;
                }
            }
        }
    }
`;
