export const FIND_EDOK_FORMS_SUCCESS = 'FIND_EDOK_FORMS_SUCCESS';

export const findEdokFormsSuccess = ({ forms, request, response }) => ({
    type: FIND_EDOK_FORMS_SUCCESS,
    payload: { forms },
    meta: {
        request,
        response,
    }
});
