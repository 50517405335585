import React from "react";
import { internalRoutes, publicRoutes } from 'AppConfig/Routes';
import _isEmpty from 'lodash/isEmpty';

class LinkHandler {

    convertRouteByKey(key) {
        const route  = internalRoutes[key];

        return Object.assign({},
            { ...route },
            { key },
            { ...route.sideBoard },
            { sorting: (route.sideBoard && route.sideBoard.sorting) ? route.sideBoard.sorting : 1000 },
            (route.access) ? { access: route.access} : null,
        );
    }

    getPublic(routeKey, args = {}) {
        return this.get(routeKey, args, false);
    }

    get(routeKey, args = {}, internal = true) {
        let route = internalRoutes[routeKey];
        if(!internal || !route){
            route = publicRoutes[routeKey];
        }
        if(!route || !route.path){
            return routeKey;
        }

        return this.getUrlWithArguments(route.path, args, route);
    }

    getUrlWithArguments(path, args = {}, route = null) {
        const _args = { ...args };
        if(route !== null) {
            // noinspection JSAnnotator
            if(!_isEmpty(_args) && route.arguments){
                // noinspection JSAnnotator
                route.arguments.forEach(argument => {
                    if(!_args[argument]) {
                        console.warn(`the route expected the following argument: ${argument}`);
                        return null;
                    }
                    path += `/${_args[argument]}`;
                    delete _args[argument];
                });
            }
        }

        for(const key in _args) {
            const value = _args[key];
            if(path.match(`:${key}`)){
                path = path.replace(`:${key}`, value);
                delete _args[key];
            }
        }

        if(!_isEmpty(_args)) {
            const additionalParams = new URLSearchParams();
            for(const key in _args) {
                additionalParams.append(key, _args[key]);
            }
            path += `?${additionalParams.toString()}`;
        }

        return path;
    }

    getSideBoard() {
        const sideBoard = [];

        for(const key in internalRoutes){
            const route = internalRoutes[key];

            if(route.hasOwnProperty('sideBoard')) {
                sideBoard.push(this.convertRouteByKey(key));
            }
        }

        const orderSideBar = ( a, b ) =>
            (a.sorting > b.sorting) ? 1 : ((b.sorting > a.sorting) ? -1 : 0);

        return sideBoard.sort(orderSideBar);
    }

    redirect(key, args = {}) {
        window.location.href = this.get( key, args );
    }

}

const linkHandler = new LinkHandler();

export default linkHandler;