import { DOWNLOAD_REPORT_REQUEST, downloadReportSuccess, downloadReportFailure} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from, EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function downloadReportEpic(action$, store$, { ReportRepository }) {
    return action$.pipe(
        ofType(DOWNLOAD_REPORT_REQUEST),
        mergeMap( action =>{
            const { id } = action.payload;

            if(!id.trim()) {
                return EMPTY;
            }

            return from(
                ReportRepository.downloadById( id )
                    .then(report => downloadReportSuccess(report))
                    .catch(error => downloadReportFailure(error)),
            );
        }),
    );
}
