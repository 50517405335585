import { CREATE_EMPLOYEE_REQUEST, createEmployeeFailure, createEmployeeSuccess } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function createEmployeeRequestEpic(action$, store$, { EmployeeRepository }) {
    return action$.pipe(
        ofType(CREATE_EMPLOYEE_REQUEST),
        mergeMap(action => from(
            EmployeeRepository.create( action.payload )
                .then(( employee ) => createEmployeeSuccess({ employee }))
                .catch(error => createEmployeeFailure(error)),
        )),
    );
}
