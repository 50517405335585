export const FIND_MODULES_BY_CARE_PROVIDER_SUCCESS = 'FIND_MODULES_BY_CARE_PROVIDER_SUCCESS';

export const findModulesByCareProviderSuccess = ({ modules, request, response }) => ({
    type: FIND_MODULES_BY_CARE_PROVIDER_SUCCESS,
    payload: { modules },
    meta: {
        request,
        response,
    }
});
