export default function isDifferent(valueOne, valueTwo){

    let typeValOne = typeof valueOne;
    let typeValTwo = typeof valueTwo;

    if(Array.isArray(valueOne)) { typeValOne = 'Array'; }
    if(Array.isArray(valueTwo)) { typeValTwo = 'Array'; }


    if(typeValOne !== typeValTwo) {
        return true;
    }

    switch(typeValOne.toLowerCase()) {
        case 'number'  :
        case 'string'  :
        case 'boolean' :
            return (valueOne !== valueTwo);
        case 'object' :
        case 'array'  :
            return (JSON.stringify(valueOne) !== JSON.stringify(valueTwo))

        default : return false;

    }
}