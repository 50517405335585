import {
    UPDATE_STATEMENT_TEMPLATE_FAILURE,
    UPDATE_RESULTS_QSEB_FAILURE,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isResultUpdatingFailed = (state) =>
    ArrayContains(state.result.failedRequests, [
        UPDATE_STATEMENT_TEMPLATE_FAILURE,
        UPDATE_RESULTS_QSEB_FAILURE
    ]);