import { LAG_GS } from 'AppConfig/Config';

export default class ChatConversation {

    constructor(data) {
        this.id   = data.id;
        this.__createdAt  = new Date(data.createdAt * 1000);
        this.createdAt    = data.createdAt;
        this.updatedAt    = data.updatedAt;
        this.lastResponse = data.lastResponse;
        this.messages     = data.messages ? data.messages : [];
        this.lastReadCareProvider = data.lastReadCareProvider ? new Date(data.lastReadCareProvider) : null;;
        this.lastReadLagGs        = data.lastReadLagGs ? new Date(data.lastReadLagGs) : null;
        this.pseudonym            = data.pseudonym;
        this.messageCount         = data.messageCount;
        this.isRead               = data.isRead;

        this.setChatMessageStates();
    }

    setChatMessageStates() {
        this.messages.sort((a, b) => a.createdAt - b.createdAt).forEach((message, index) => {
            let state = 'unread';
            const createdAt = new Date(message.createdAt * 1000);

            if(
                (message.userRole === LAG_GS && createdAt <= this.lastReadCareProvider) ||
                (message.userRole !== LAG_GS && createdAt <= this.lastReadLagGs)
            ) {
                state = 'read';
            }
            this.messages[index] = {
                ...message,
                state,
                createdAt
            }
        });
    }

}
