export const getStatementTemplateByIndicatorId = (state) => (indicatorId) => {
    const keys = Object.keys(state.statementTemplate.statementTemplate.entries);
    for(let i = 0, l = keys.length; i < l; i++) {
        const statementTemplate = state.statementTemplate.statementTemplate.entries[keys[i]];
        if(`${statementTemplate.indicatorId}` === `${indicatorId}`) {
            return statementTemplate;
        }
    }
    return {};
}
