import {
    FIND_EMPLOYEE_ROLE_REQUEST,
    FIND_EMPLOYEE_ROLE_FAILURE,
    FIND_EMPLOYEE_ROLE_SUCCESS,

    FIND_EMPLOYEE_ROLES_REQUEST,
    FIND_EMPLOYEE_ROLES_FAILURE,
    FIND_EMPLOYEE_ROLES_SUCCESS,

    LOGOUT_USER,
    DISABLE_USER_SUCCESS,
    AUTHENTICATE_FAILURE,

} from 'Redux/Action';
import EmployeeRoleState from 'Redux/Store/EmployeeRole/EmployeeRoleState';
import { removeOpenRequest, removeFailedRequest, addSingleObjectToReducer, getSingleObject } from 'ClientService/ReducerUtil/ReducerUtil';

const initialState = EmployeeRoleState.getInitialState();

export const employeeRoleReducer = (state = initialState, action) => {

    switch (action.type) {

    case AUTHENTICATE_FAILURE :
    case LOGOUT_USER :
    case DISABLE_USER_SUCCESS :
        return {
            ...initialState,
        };

    case FIND_EMPLOYEE_ROLE_REQUEST:
    case FIND_EMPLOYEE_ROLES_REQUEST:
        return {
            ...state,
            lastInteractiveId: null,
            openRequests: [...state.openRequests, action.type],
            failedRequests: removeFailedRequest(action, state),
            updatedAt: Date.now(),
        };

    case FIND_EMPLOYEE_ROLE_SUCCESS:
        const employeeRole = getSingleObject(action.payload.employeeRole);

        return {
            ...state,
            lastInteractiveId: employeeRole.id,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
            employeeRoles: {
                ...state.employeeRoles,
                entries: addSingleObjectToReducer(state.employeeRoles.entries, action.payload.employeeRole)
            },
        };

    case FIND_EMPLOYEE_ROLES_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            employeeRoles: {
                entries: action.payload.employeeRoles,
                maxCount: (action.meta) ? action.meta.response?.count : state.employees.maxCount,
            },
            updatedAt: Date.now(),
        };

    case FIND_EMPLOYEE_ROLE_FAILURE:
    case FIND_EMPLOYEE_ROLES_FAILURE:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            failedRequests: [...state.failedRequests, action.type],
            updatedAt: Date.now(),
        };



    default:
        return state;
    }
};
