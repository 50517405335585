export const FIND_EDOK_DOCUMENTATIONS_FAILURE = 'FIND_EDOK_DOCUMENTATIONS_FAILURE';

export const findEdokDocumentationsFailure = ({ error, request }) => ({
    type: FIND_EDOK_DOCUMENTATIONS_FAILURE,
    payload: error,
    error: true,
    meta: {
        request
    }
});
