import { FIND_MAIL_TEMPLATES_REQUEST, findMailTemplatesSuccess, findMailTemplatesFailure } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function findMailTemplatesEpic(action$, store$, { MailTemplateRepository }) {
    return action$.pipe(
        ofType( FIND_MAIL_TEMPLATES_REQUEST ),
        mergeMap(action => from(
            MailTemplateRepository.findAll(action.payload)
                .then(({ mailTemplates, meta }) => findMailTemplatesSuccess({ mailTemplates, response: meta, request: action}))
                .catch(error => findMailTemplatesFailure({ error, request: action })),
        )),
    );
}
