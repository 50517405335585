import {
    FIND_STATEMENT_TEMPLATE_BY_INDICATOR_ID_REQUEST,
    FIND_STATEMENT_TEMPLATE_BY_INDICATOR_ID_SUCCESS,
    FIND_STATEMENT_TEMPLATE_BY_INDICATOR_ID_FAILURE,

    FIND_STATEMENT_TEMPLATE_PLACEHOLDERS_REQUEST,
    FIND_STATEMENT_TEMPLATE_PLACEHOLDERS_SUCCESS,
    FIND_STATEMENT_TEMPLATE_PLACEHOLDERS_FAILURE,

    CREATE_STATEMENT_TEMPLATE_REQUEST,
    CREATE_STATEMENT_TEMPLATE_SUCCESS,
    CREATE_STATEMENT_TEMPLATE_FAILURE,

    UPDATE_STATEMENT_TEMPLATE_REQUEST,
    UPDATE_STATEMENT_TEMPLATE_SUCCESS,
    UPDATE_STATEMENT_TEMPLATE_FAILURE,

    FIND_INDICATOR_IDS_REQUEST,
    FIND_INDICATOR_IDS_SUCCESS,
    FIND_INDICATOR_IDS_FAILURE,

    LOGOUT_USER,
    DISABLE_USER_SUCCESS,
    AUTHENTICATE_FAILURE,

} from 'Redux/Action';
import StatementTemplateState from 'Redux/Store/StatementTemplate/StatementTemplateState';
import { removeOpenRequest, removeFailedRequest, addSingleObjectToReducer, getSingleObject } from 'ClientService/ReducerUtil/ReducerUtil';

const initialState = StatementTemplateState.getInitialState();

export const statementTemplateReducer = (state = initialState, action) => {

    switch (action.type) {

    case AUTHENTICATE_FAILURE :
    case LOGOUT_USER :
    case DISABLE_USER_SUCCESS :
        return {
            ...initialState,
        };

    case FIND_STATEMENT_TEMPLATE_BY_INDICATOR_ID_REQUEST:
    case CREATE_STATEMENT_TEMPLATE_REQUEST:
    case UPDATE_STATEMENT_TEMPLATE_REQUEST:
    case FIND_STATEMENT_TEMPLATE_PLACEHOLDERS_REQUEST:
    case FIND_INDICATOR_IDS_REQUEST:
        return {
            ...state,
            openRequests: [...state.openRequests, action.type],
            failedRequests: removeFailedRequest(action, state),
            updatedAt: Date.now(),
        };

    case UPDATE_STATEMENT_TEMPLATE_SUCCESS:
    case CREATE_STATEMENT_TEMPLATE_SUCCESS:
    case FIND_STATEMENT_TEMPLATE_BY_INDICATOR_ID_SUCCESS:
        const statementTemplate = getSingleObject(action.payload.statementTemplate);
        const entries = {};
        Object.keys(state.statementTemplate.entries).map(key => {
            entries[key] = { ...state.statementTemplate.entries[key] };
        });

        if(!!`${state.lastInteractiveId}`.match(/new/i)) {
            delete entries[state.lastInteractiveId];
        }

        return {
            ...state,
            lastInteractiveId: statementTemplate.id,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
            statementTemplate: {
                ...state.statementTemplate,
                entries: addSingleObjectToReducer(entries, action.payload.statementTemplate)
            },
        };

    case FIND_STATEMENT_TEMPLATE_PLACEHOLDERS_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
            statementTemplate: {
                ...state.statementTemplate,
                entries: action.payload.statementTemplates
            },
        };

    case FIND_INDICATOR_IDS_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
            indicatorIds: [ ...new Set([ ...state.indicatorIds, ...action.payload.indicatorIds ]) ],
        };

    case FIND_STATEMENT_TEMPLATE_BY_INDICATOR_ID_FAILURE:
    case CREATE_STATEMENT_TEMPLATE_FAILURE:
    case UPDATE_STATEMENT_TEMPLATE_FAILURE:
    case FIND_STATEMENT_TEMPLATE_PLACEHOLDERS_FAILURE:
    case FIND_INDICATOR_IDS_FAILURE:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            failedRequests: [...state.failedRequests, action.type],
            updatedAt: Date.now(),
        };



    default:
        return state;
    }
};
