import styled from 'styled-components';
import { Layout } from 'antd';

export default styled(Layout.Footer) `
  
    padding: 8px 10px;
    color: rgba(0, 0, 0, 0.4);
    font-size: 12px;
    background: #f0f2f5;
    font-style: italic;
    text-align: right;
    
    .scrollToTop {
        position: fixed;
        bottom: 28px;
        right: 28px;
        border-radius: 100%;
        width: 40px;
        height: 40px;
        padding: 0;
        z-index: 100000;
    }

`;
