import {
    FIND_RESULTS_REQUEST,
    FIND_RESULTS_FAILURE,
    FIND_RESULTS_SUCCESS,

    FIND_RESULT_REQUEST,
    FIND_RESULT_FAILURE,
    FIND_RESULT_SUCCESS,

    UPLOAD_RESULTS_REQUEST,
    UPLOAD_RESULTS_SUCCESS,
    UPLOAD_RESULTS_FAILURE,

    UPLOAD_NOTICE_RESULTS_REQUEST,
    UPLOAD_NOTICE_RESULTS_SUCCESS,
    UPLOAD_NOTICE_RESULTS_FAILURE,

    UPDATE_RESULTS_QSEB_REQUEST,
    UPDATE_RESULTS_QSEB_SUCCESS,
    UPDATE_RESULTS_QSEB_FAILURE,

    UPDATE_RATING_REQUEST,
    UPDATE_RATING_SUCCESS,
    UPDATE_RATING_FAILURE,

    SEND_RESULT_RATING_MAIL_REQUEST,
    SEND_RESULT_RATING_MAIL_SUCCESS,
    SEND_RESULT_RATING_MAIL_FAILURE,

    LOGOUT_USER,
    DISABLE_USER_SUCCESS,
    AUTHENTICATE_FAILURE,

    FIND_EVAL_YEARS_REQUEST,
    FIND_EVAL_YEARS_SUCCESS,
    FIND_EVAL_YEARS_FAILURE,

    FIND_SPEC_YEARS_REQUEST,
    FIND_SPEC_YEARS_SUCCESS,
    FIND_SPEC_YEARS_FAILURE,

    CHECK_RESULT_RATING_MAIL_SUCCESS,

} from 'Redux/Action';
import ResultState from 'Redux/Store/Result/ResultState';
import { removeOpenRequest, removeFailedRequest, addSingleObjectToReducer, getSingleObject } from 'ClientService/ReducerUtil/ReducerUtil';

const initialState = ResultState.getInitialState();

export const resultReducer = (state = initialState, action) => {

    switch (action.type) {

    case AUTHENTICATE_FAILURE :
    case LOGOUT_USER :
    case DISABLE_USER_SUCCESS :
        return {
            ...initialState,
        };

    case FIND_RESULTS_REQUEST:
    case SEND_RESULT_RATING_MAIL_REQUEST:
    case UPDATE_RESULTS_QSEB_REQUEST:
    case UPDATE_RATING_REQUEST:
    case FIND_RESULT_REQUEST:
    case FIND_EVAL_YEARS_REQUEST:
    case FIND_SPEC_YEARS_REQUEST:
    case UPLOAD_RESULTS_REQUEST:
    case UPLOAD_NOTICE_RESULTS_REQUEST:
        return {
            ...state,
            openRequests: [...state.openRequests, action.type],
            failedRequests: removeFailedRequest(action, state),
            updatedAt: Date.now(),
        };

    case FIND_EVAL_YEARS_SUCCESS:
    case FIND_SPEC_YEARS_SUCCESS:
        return {
            ...state,
            ...action.payload,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
        };

    case CHECK_RESULT_RATING_MAIL_SUCCESS:
    case SEND_RESULT_RATING_MAIL_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            allowSendResultRatingMail: action.payload.allowToSendMail,
            updatedAt: Date.now(),
        };

    case UPLOAD_RESULTS_SUCCESS:
    case UPLOAD_NOTICE_RESULTS_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
        };

    case FIND_RESULT_SUCCESS:
        const result = getSingleObject(action.payload.result);

        return {
            ...state,
            lastInteractiveGuid: result.id,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
            results: {
                ...state.results,
                entries: addSingleObjectToReducer(state.results.entries, action.payload.result)
            },
        };

    case FIND_RESULTS_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            results: {
                entries: action.payload.results,
                maxCount: (action.meta) ? action.meta.response.count : state.results.maxCount,
            },
            updatedAt: Date.now(),
        };
    case UPDATE_RESULTS_QSEB_SUCCESS:
    case UPDATE_RATING_SUCCESS:
        const entries = { ...state.results.entries };
        Object.keys(action.payload.results).forEach(id => {
            const result = action.payload.results[id];
            entries[id] = result;
        });

        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            results: {
                ...state.results,
                entries,
            },
            updatedAt: Date.now(),
        };

    case FIND_RESULTS_FAILURE:
    case FIND_RESULT_FAILURE:
    case UPLOAD_RESULTS_FAILURE:
    case UPDATE_RESULTS_QSEB_FAILURE:
    case UPDATE_RATING_FAILURE:
    case SEND_RESULT_RATING_MAIL_FAILURE:
    case FIND_EVAL_YEARS_FAILURE:
    case FIND_SPEC_YEARS_FAILURE:
    case UPLOAD_NOTICE_RESULTS_FAILURE:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            failedRequests: [...state.failedRequests, action.type],
            updatedAt: Date.now(),
        };



    default:
        return state;
    }
};
