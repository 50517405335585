import BaseRepository from '../Base/BaseRepository';
import Employee from 'Domain/Model/Employee/Employee';
import isEmpty from "lodash/isEmpty";

class EmployeeRepository extends BaseRepository {

    resendActivateLink = (id) => {
        return this.makeRequest({
            path: `/internal/employee/resend-activate-link/${id}`,
            type: 'GET',
            callback: () => {}
        });
    };

    initializeModels = (responseObject) => {
        if(responseObject.hasOwnProperty('employees')){
            return { employees: this.iterate(responseObject.employees), meta: responseObject.meta };
        }

        return null;
    };

    initializeSingleModel = (response) => {
        if(isEmpty(response)) {
            return {};
        }
        if(!response.hasOwnProperty('employee')){
            return null;
        }

        const newModel  = this.initializeModel(response.employee);
        return { [newModel.id]: newModel };
    };

}

export default new EmployeeRepository({
    model: Employee,
    baseRoute: 'employee',
    singular: 'employee',
    plural: 'employees',
});
