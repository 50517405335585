import React from 'react';
import { Link as InternalLink } from 'react-router-dom';
import Wrapper from './Link.style';
import { linkHandler }  from 'ClientService';

const Link = ({ to, href, args, externalLink, children, ...props }) => {
    let link = null;
    if( to ) {
        link = <InternalLink to={ to }>{ children }</InternalLink>;
    }
    if( href ) {
        link = <InternalLink to={linkHandler.get(href, args)}>{ children }</InternalLink>;
    }
    if( externalLink ) {
        link = <a href={ `${externalLink} `} rel="noopener noreferrer" target="_blank">{ children }</a>
    }

    return(
        <Wrapper { ...props }>
            { link }
        </Wrapper>
    );
}
export default Link;
