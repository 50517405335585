import {
    GET_POOL_REQUEST_INBOX_REQUEST,
    FIND_RESULT_SUCCESS,
    SEND_POOL_REQUEST_SUCCESS,
    FIND_OPEN_PROCESS_STATEMENTS_SUCCESS,
    getPoolRequestInboxSuccess,
    getPoolRequestInboxFailure,
}                                               from 'Redux/Action';
import { ofType }                               from 'redux-observable';
import { from, of, EMPTY }                      from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { RESULT_OVERVIEW_GROUPS }               from 'AppConfig/Config';
import { getAccessGrant, userContainsRole }     from 'Redux/Query';

export function getPoolRequestInboxEpic(action$, store$, { ProcessStatementRepository }) {
    return action$.pipe(
        ofType(
            GET_POOL_REQUEST_INBOX_REQUEST,
            FIND_RESULT_SUCCESS,
            SEND_POOL_REQUEST_SUCCESS,
            FIND_OPEN_PROCESS_STATEMENTS_SUCCESS
        ),
        withLatestFrom(store$),
        mergeMap(([ action, store ]) => {
            if(!userContainsRole(store)([ ...RESULT_OVERVIEW_GROUPS ]) || !['READ', 'WRITE', 'SEND'].includes(getAccessGrant(store, 'CREATE_PROCESS_STATEMENT'))) {
                return EMPTY;
            }

            return from(ProcessStatementRepository.getOpenRequestCount()).pipe(
                mergeMap(( { poolRequestInboxCount } ) => {
                    return from([
                        getPoolRequestInboxSuccess({ poolRequestInboxCount, request: action})
                    ]);
                }),
                catchError(error => of(getPoolRequestInboxFailure({ error, request: action })))
            )
        })

    );
}
