import {
    CREATE_EMPLOYEE_FAILURE,
    UPDATE_EMPLOYEE_FAILURE,
    FIND_EMPLOYEE_FAILURE,
    FIND_EMPLOYEES_FAILURE,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isEmployeeFailed = (state) => ArrayContains(state.employee.failedRequests, [
    CREATE_EMPLOYEE_FAILURE,
    UPDATE_EMPLOYEE_FAILURE,
    FIND_EMPLOYEE_FAILURE,
    FIND_EMPLOYEES_FAILURE,
]);