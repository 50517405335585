import { combineEpics } from 'redux-observable';

import { findResultRatingsEpic } from './FindResultRatings/FindResultRatingsEpic';
import { findResultRatingEpic }  from './FindResultRating/FindResultRatingEpic';


export default combineEpics(
    findResultRatingsEpic,
    findResultRatingEpic,
);
