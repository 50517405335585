export const queryFilter = props => {
    let filter = null;

    if(props.hasOwnProperty('limit')) {
        if(filter === null) { filter = {}; }
        filter.limit = props.limit;
    }
    if(props.hasOwnProperty('sorting')) {
        if(filter === null) { filter = {}; }
        filter.sorting = props.sorting;
    }

    return filter;
};

export const sortObjects = (objectStore, orderSettings) => {
    const orderBy        = orderSettings.by || 'id';
    const orderDirection = ((orderSettings.order || 'ASC') === 'ASC') ? 1 : -1;


    return Object.keys(objectStore).map(key => objectStore[key]).sort(( item1, item2 ) => {
        if (item1[orderBy] < item2[orderBy]) {
            return -orderDirection;
        }
        if (item1[orderBy] > item2[orderBy]) {
            return orderDirection;
        }
        return 0;
    });

};


export const filterObjectStore = (objectStore, filter = null) => {
    if(!objectStore) {
        return [];
    }

    if(filter === null) {
        return Object.keys(objectStore).map(key => objectStore[key]);
    }

    if(filter.hasOwnProperty('limit') && filter.hasOwnProperty('sorting')) {
        return sortObjects(objectStore, filter.sorting).slice(0, filter.limit);
    }
    if(filter.hasOwnProperty('limit')) {
        return Object.keys(objectStore).map(key => objectStore[key]).slice(0, filter.limit);
    }
    if(filter.hasOwnProperty('sorting')) {
        return sortObjects(objectStore, filter.sorting);
    }

    return Object.keys(objectStore).map(key => objectStore[key]);
};