import BaseRepository from '../Base/BaseRepository';
import MailTemplate   from 'Domain/Model/Mail/MailTemplate';

class MailTemplateRepository extends BaseRepository {

}

export default new MailTemplateRepository({
    model: MailTemplate,
    baseRoute: 'mail-template',
    singular: 'mailTemplate',
    plural: 'mailTemplates',
});
