import styled from "styled-components";

export default styled.div `
      
   
    height: 100%;
    margin: 0;
    padding: 0;
    top: 0;

    
`;