import { combineEpics } from 'redux-observable';
import { checkSessionRequestEpic } from './CheckSession/CheckSessionRequestEpic';
import { initializeLoadEpic } from './InitializeLoad/InitializeLoadEpic';
import { initializeBulkLoad } from './InitializeBulkLoad/InitializeBulkLoadEpic';
import { getSettingsEpic } from './GetSettings/GetSettingsEpic';
import { reloadServerSettingsEpic } from './ReloadServerSettings/ReloadServerSettingsEpic';
import { updateServerSettingsRequestEpic } from './UpdateServerSettings/UpdateServerSettingsRequestEpic';
import { updateServerSettingsSuccessEpic } from './UpdateServerSettings/UpdateServerSettingsSuccessEpic';
import { getPoolRequestInboxEpic } from './GetPoolRequestInbox/GetPoolRequestInboxEpic';

export default combineEpics(
    checkSessionRequestEpic,
    initializeLoadEpic,
    initializeBulkLoad,
    getSettingsEpic,
    reloadServerSettingsEpic,
    updateServerSettingsRequestEpic,
    updateServerSettingsSuccessEpic,
    getPoolRequestInboxEpic,
);
