import BaseRepository from '../Base/BaseRepository';
import Document from 'Domain/Model/Document/Document';
import isEmpty from 'lodash/isEmpty';
import each from "lodash/each";

class DocumentRepository extends BaseRepository {

    filterSettings = {};

    setFilterSettings(settings) {
        super.setFilterSettings(settings);

        if( this.filterSettings.hasOwnProperty('filter_username')) {
            this.filterSettings['filter_`Employee`.`title`']      = this.filterSettings['filter_username'];
            this.filterSettings['filter_`Employee`.`first_name`'] = this.filterSettings['filter_username'];
            this.filterSettings['filter_`Employee`.`last_name`']  = this.filterSettings['filter_username'];

            delete this.filterSettings['filter_username'];
        }

        return this;
    };

    findById(id) {
        return this.makeRequest({
            path: `/${this.baseRoute}/${id}`,
            type: 'GET',
            callback: this.initializeSingleModel
        });

    }

    downloadById(id) {
        return this.makeRequest({
            path: `/${this.baseRoute}/download/${id}`,
            type: 'GET',
            callback: this.fetchDownload
        });

    }

    uploadDocument(data) {
        return this.makeRequest({
            path: `/${this.baseRoute}/upload`,
            data: data,
            type: 'POST',
            callback: this.initializeSingleModel
        });
    };

    initializeModels = (responseObject) => {
        if(responseObject.hasOwnProperty('documents')){
            return { documents: this.iterate(responseObject.documents), meta: responseObject.meta };
        }

        return null;
    };



    iterate = (dataEntries, initializeFunction = this.initializeModel) => {
        const objectStorage = {};
        each(dataEntries, entry => {
            const newModel = initializeFunction(entry);
            objectStorage[newModel.id] = newModel;
        });
        return objectStorage;

    };

    fetchDownload = (responseData, response) => {
        response.blob()
            .then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = 'document.json';
                a.click();
            })
            .catch( console.error );
    };

    initializeSingleModel = (response) => {
        if(isEmpty(response)) {
            return {};
        }

        const entry     = (response.hasOwnProperty('document')) ? response.document : response;
        const newModel  = this.initializeModel(entry);

        return { [newModel.id]: newModel };
    };
}

export default new DocumentRepository({
    model: Document,
    baseRoute: 'document',
    singular: 'document',
    plural: 'documents',
});
