import BaseRepository from '../Base/BaseRepository';
import Report from 'Domain/Model/Report/Report';


class ReportRepository extends BaseRepository {


    downloadById(id) {
        return this.makeRequest({
            path: `/${this.baseRoute}/download/${id}`,
            type: 'GET',
            callback: this.fetchDownload
        });

    }

    fetchDownload = (responseData, response) => {
        response.blob()
            .then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = 'report.file';
                a.click();
            })
            .catch( console.error );
    };

}

export default new ReportRepository({
    model: Report,
    baseRoute: 'report',
    singular: 'report',
    plural: 'reports',
});
