
export const FIND_CHAT_CONVERSATIONS_SUCCESS = 'FIND_CHAT_CONVERSATIONS_SUCCESS';

export const findChatConversationsSuccess = ({ conversations, request, response }) => ({
    type: FIND_CHAT_CONVERSATIONS_SUCCESS,
    payload: { conversations },
    meta: {
        request,
        response,
    }
});
