export const FIND_MAIL_TEMPLATES_FAILURE = 'FIND_MAIL_TEMPLATES_FAILURE';

export const findMailTemplatesFailure = ({ error, request }) => ({
    type: FIND_MAIL_TEMPLATES_FAILURE,
    payload: error,
    error: true,
    meta: {
        request
    }
});
