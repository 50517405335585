import { FIND_OPEN_ANSWER_PROCESS_STATEMENTS_REQUEST, findOpenAnswerProcessStatementsSuccess, findOpenAnswerProcessStatementsFailure } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function findOpenAnswerProcessStatementsEpic(action$, store$, { ProcessStatementRepository }) {
    return action$.pipe(
        ofType( FIND_OPEN_ANSWER_PROCESS_STATEMENTS_REQUEST ),
        mergeMap(action => from(
            ProcessStatementRepository.findAllOpenAnsweredByUser(action.payload)
                .then(({ statements, meta }) => findOpenAnswerProcessStatementsSuccess({ processStatements: statements, response: meta, request: action}))
                .catch(error => findOpenAnswerProcessStatementsFailure({ error, request: action })),
        )),
    );
}
