export const FIND_EMPLOYEE_ROLES_REQUEST = 'FIND_EMPLOYEE_ROLES_REQUEST';

export const findEmployeeRolesRequest = ({
     filter = [],
     sorting = { field: 'name', order: 'ASC' },
     pagination = { page: 1, take: 50 },
 }) => ({
    type: FIND_EMPLOYEE_ROLES_REQUEST,
    payload: { filter, sorting, pagination },
    meta: {
        sorting,
        pagination,
    }
});
