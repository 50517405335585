import BaseRepository from '../Base/BaseRepository';
import PoolRequest from 'Domain/Model/PoolRequest/PoolRequest';

class PoolRequestRepository extends BaseRepository {

    sendPoolRequest( data ) {
        return this.makeRequest({
            path: `/pool-request/send`,
            data,
            type: 'POST',
            callback: () => {}
        });
    }

    sendOpenPoolRequest( ids ) {
        return this.makeRequest({
            path: `/pool-request/request-inbox/send`,
            type: 'POST',
            data: {
                ids,
            },
            // poolRequestInboxCount
            callback: () => {}
        });
    }

    delete(ids) {
        return this.makeRequest({
            path: `/${this.baseRoute}`,
            data: { ids },
            type: 'DELETE'
        });
    }
}

export default new PoolRequestRepository({
    model: PoolRequest,
    baseRoute: 'pool-request',
    singular: 'poolRequest',
    plural: 'poolRequests',
});
