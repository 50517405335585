import { HAS_ANSWER_ACCESS_GRANTS, HAS_SEND_ACCESS_GRANTS } from 'AppConfig/Config';
import { getAccessGrant }                                   from 'Redux/Query/Employee/GetAccessGrant/GetAccessGrant';
import { getUserRole }                                      from 'Redux/Query/User';

export const hasCommentWriteAccessGrants = (state) => {
    const userRole = getUserRole(state);
    if(HAS_SEND_ACCESS_GRANTS.includes(userRole)) {
        return ['WRITE', 'SEND'].includes(getAccessGrant(state, 'CREATE_PROCESS_STATEMENT'));
    }
    if(HAS_ANSWER_ACCESS_GRANTS.includes(userRole)) {
        return ['WRITE', 'SEND'].includes(getAccessGrant(state, 'ANSWER_PROCESS_STATEMENT'));
    }

}