import { ADD_NEW_CHAT_MESSAGE_REQUEST, addNewChatMessageSuccess, addNewChatMessageFailure } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function addNewChatMessageEpic(action$, store$, { ChatRepository }) {
    return action$.pipe(
        ofType(ADD_NEW_CHAT_MESSAGE_REQUEST),
        mergeMap(action => from(
            ChatRepository.createNewChatMessage( action.payload.conversationId, action.payload.message )
                .then(( conversation ) => addNewChatMessageSuccess({ conversation }))
                .catch(error => addNewChatMessageFailure(error)),
        )),
    );
}
