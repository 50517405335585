import { DOWNLOAD_DOCUMENT_REQUEST, downloadDocumentSuccess, downloadDocumentFailure} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from, EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function downloadDocumentEpic(action$, store$, { DocumentRepository }) {
    return action$.pipe(
        ofType(DOWNLOAD_DOCUMENT_REQUEST),
        mergeMap( action =>{
            const { id } = action.payload;

            if(!id.trim()) {
                return EMPTY;
            }

            return from(
                DocumentRepository.downloadById( id )
                    .then(document => downloadDocumentSuccess(document))
                    .catch(error => downloadDocumentFailure(error)),
            );
        }),
    );
}
