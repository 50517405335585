import BaseRepository from '../Base/BaseRepository';
import { User } from 'Domain/Model';
import requestApi from 'ClientService/RequestApi';

class UserRepository extends BaseRepository {

    checkAuthorization() {
        return this.makeRequest({
            path: `/user/check-authentification`,
            type: 'GET',
            callback: this.parseUser
        });
    }

    login({ mail, password }) {
        return this.makeRequest({
            path: `/user/authenticate`,
            type: 'POST',
            data: {
                username: mail,
                password,
                client_id: 'uni_trend_user',
                grant_type: 'password',
            },
            callback: this.parseUser
        });
    }

    logout() {
        return this.makeRequest({
            path: `/user/logout`,
            type: 'GET',
            callback: this.resetHeader
        });
    }

    disable( password ) {
        return this.makeRequest({
            path: `/user/disable`,
            type: 'POST',
            data: { password },
            callback: this.resetHeader
        });
    }

    forgotPassword( mail ) {
        return this.makeRequest({
            path: `/user/forgot-password/ `,
            data: { mail },
            type: 'POST',
            callback: this.parseUser
        });
    }

    resetPassword({ password, hash, confirmationHash } ) {
        return this.makeRequest({
            path: `/user/reset-password/ `,
            data: { password, hash, confirmationHash },
            type: 'POST',
            callback: this.parseUser
        });
    }

    find() {
        return this.makeRequest({
            path: `/user`,
            type: 'GET',
            callback: this.parseUser
        });
    }

    update(updateData) {
        return this.makeRequest({
            path: `/user/update`,
            type: 'PATCH',
            data: updateData,
            callback: this.parseUser
        });
    }

    signup( data ) {
        return this.makeRequest({
            path: `/user/register`,
            type: 'POST',
            data,
            callback: this.parseUser
        });
    }



    refreshAccessToken(refreshToken) {
        return this.makeRequest({
            path: `/user/refresh-access-token`,
            type: 'POST',
            data: {
                refresh_token: refreshToken,
                client_id: 'uni_trend_user',
                grant_type: 'refresh_token',
            },
            callback: this.parseUser
        });
    }


    setAuthorisationHeader(accessToken) {
        requestApi.setHeader('Authorization', `Bearer ${accessToken}`);
    }

    resetHeader = () => {
        requestApi.resetHeader();

        return null;
    };

    validateToken = response => {
        return !!response.success;
    };

    parseUser = ( response ) => {
        let user = new User(response.user || {});
        let authentication = null;
        let role           = null;

        if(response.hasOwnProperty('accessToken')){
            this.setAuthorisationHeader(response.accessToken);
            authentication = {
                accessToken: response.accessToken,
                refreshToken: response.refreshToken,
                accessTokenExpiresAt: response.accessTokenExpiresAt,
                refreshTokenExpiresAt: response.refreshTokenExpiresAt,
            };

            requestApi.lastAccessToken  = response.accessToken;
            requestApi.lastRefreshToken = response.refreshToken;
        }
        if(response.hasOwnProperty('user') && response.user.hasOwnProperty('employeeRole')){
            role = {
                id: response.user.employeeRole.id,
                name: response.user.employeeRole.name,
                key: response.user.employeeRole.key,
            }
            user = new User({ ...response.user, role: role.id } || {});

        }

        return { user, authentication, role };
    };

}

export default new UserRepository({
    model: User,
    baseRoute: 'user',
    singular: 'user',
    plural: 'users',
});