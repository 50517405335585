export const strPad = ( str ) => {
    return `${str}`.padStart(2, '0');
};

export const formatDate = ( date ) => {
    if(!date) {
        return '---';
    }

    if(!(date instanceof Date)) {
        try {
            date = new Date(`${date}`)
        }
        catch(error) {
            console.error(error);
            return '- Invalid Date Object -'
        }
    }

    return `${strPad(date.getDate())}.${ strPad(date.getMonth() + 1) }.${ strPad(date.getFullYear()) }`;
};
