export default class StatementTemplate {

    constructor( data  ) {

        this.id          = data.id;
        this.createdAt   = data.createdAt;
        this.indicatorId = data.indicatorId;
        this.shortKey    = data.shortKey;
        this.template    = data.template;

    }

}
