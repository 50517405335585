import { combineEpics } from 'redux-observable';

import { findCareProvidersEpic }         from './FindCareProviders/FindCareProvidersEpic';
import { findCareProviderEpic }          from './FindCareProvider/FindCareProviderEpic';
import { dePseudoCareProviderEpic }      from './DePseudoCareProvider/DePseudoCareProviderEpic';
import { createCareProviderRequestEpic } from './CreateCareProvider/CreateCareProviderRequestEpic';
import { updateCareProviderRequestEpic } from './UpdateCareProvider/UpdateCareProviderRequestEpic';
import { updateCareProviderSuccessEpic } from './UpdateCareProvider/UpdateCareProviderSuccessEpic';
import { massDePseudoEpic }              from './MassDePseudo/MassDePseudoEpic';
import { findEncryptedPseudonymsEpic }   from './FindEncryptedPseudonyms/FindEncryptedPseudonymsEpic';


export default combineEpics(
    dePseudoCareProviderEpic,
    findCareProvidersEpic,
    findCareProviderEpic,
    createCareProviderRequestEpic,
    updateCareProviderRequestEpic,
    updateCareProviderSuccessEpic,
    massDePseudoEpic,
    findEncryptedPseudonymsEpic,
);
