export default class MailTemplate {

    constructor(data) {
        this.id             = data.id;
        this.name           = data.name;
        this.createdAt      = data.createdAt;
        this.subject        = data.subject;
        this.identifierName = data.identifierName;
        this.template       = data.template;
        this.arguments      = data.arguments.split(',');
        this.propertySchema = data.propertySchema;

    }
}
