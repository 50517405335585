import { UPDATE_PROCESS_STATEMENT_RTF_REQUEST, updateProcessStatementRtfSuccess, updateProcessStatementRtfFailure } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from, EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function updateProcessStatementRtfEpic(action$, store$, { SettingsRepository }) {
    return action$.pipe(
        ofType(UPDATE_PROCESS_STATEMENT_RTF_REQUEST),
        mergeMap( action => from(
            SettingsRepository.updateProcessStatementRtf(action.payload.rtfTemplate)
                .then(rtfTemplate => updateProcessStatementRtfSuccess({ rtfTemplate }))
                .catch(error => updateProcessStatementRtfFailure(error)),
        )),
    );
}
