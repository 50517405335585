import { combineEpics } from 'redux-observable';

import { findMailTemplateEpic }   from './FindMailTemplate/FindMailTemplateEpic';
import { findMailTemplatesEpic }  from './FindMailTemplates/FindMailTemplatesEpic';
import { updateMailTemplateEpic } from './UpdateMailTemplate/UpdateMailTemplateEpic';


export default combineEpics(
    findMailTemplateEpic,
    findMailTemplatesEpic,
    updateMailTemplateEpic,
);
