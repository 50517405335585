export default class File {

    constructor( data  ) {

        this.id           = data.id           || 'new';
        this.guid         = data.guid         || null;
        this.originalName = data.originalName || '';
        this.username     = data.username     || '';
        this.size         = data.size         || 0;
        this.uploadDate   = new Date(data.uploadDate)   || new Date();
        this.responseDate = data.responseDate ? new Date(data.responseDate) : null;
        this.downloadDate = data.downloadDate ? new Date(data.downloadDate) : null;


        this.createdAt    = data.createdAt;

    }

}
