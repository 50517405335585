import {
    LOGOUT_USER,
    DISABLE_USER_SUCCESS,
    AUTHENTICATE_FAILURE,

    CHANGE_TABLE_SETTINGS,

} from 'Redux/Action';
import TableStore from 'Redux/Store/Table/TableStore';

const initialState = TableStore.getInitialState();

export const tableReducer = (state = initialState, action) => {

    switch (action.type) {

        case AUTHENTICATE_FAILURE :
        case LOGOUT_USER :
        case DISABLE_USER_SUCCESS :
            return {
                ...initialState,
            };

        case CHANGE_TABLE_SETTINGS: {
            const { table, ...payload } = action.payload;
            return {
                ...state,
                tables: {
                    ...state.tables,
                    [action.payload.table]: {
                        ...state.tables[action.payload.table],
                        ...payload
                    },
                }
            }
        }


        default:
            return state;
    }
};
